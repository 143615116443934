import React, { useState } from 'react'
import facebook from '../../images/facebook.svg'
import instagram from '../../images/instagram.svg'
import whatsapp from '../../images/whatsapp.svg'
import headerclosemenu from '../../images/headerclosemenu.svg'
import headerhamburger from '../../images/headerhamburger.svg'
import headerlogomobile from '../../images/headerlogomobile.svg'
import { useLocation } from 'react-router-dom'
import headeremail from '../../images/headeremail.svg'
import headerphone from '../../images/headerphone.svg'
import mobileheaderlogo from '../../images/mobile-header-logo.svg'

import './header.scss'
import { useNavigate } from 'react-router-dom'

const Header = () => {
  const navigate = useNavigate();

  const [showMenu, setShowMenu] = useState(false);

  const handleOpenMenu = () => {
    setShowMenu(true);
  }

  const handleCloaseMenu = () => {
    setShowMenu(false);
  }

  const location = useLocation();

  const handleBlog = () => {
    navigate('/blog')
  }

  const handleHome = () => {
    navigate('/')
  }

  const handleServices = () => {
    navigate('/services')
  }

  return (
    <>
      {showMenu && 
      <div className='burger-menu-open'>
        <div className='modal-header-pages'>
          <p onClick={handleHome}>მთავარი</p>
          <p onClick={handleServices} >განყოფილებები</p>
          <p onClick={handleBlog}>ბლოგი</p>
        </div>
        <div>
        <div className='burger-menu-bottom-box'>
          <img src={headerphone} alt='headerphone' />
          <p>საკონტაკტო: +995 568 32 01 64</p>
        </div>
        <div className='burger-menu-bottom-box'>

          <img src={headeremail} alt='headeremail' />
          <p>მეილი: Medicaltourısm@mail.ru</p>
        </div>
        </div>

        
      </div>}
      <div className='header'>
        <div className='header-mobile'>
          {!showMenu && <img src={headerhamburger} alt='headerhamburger' className='header-hamburger' onClick={handleOpenMenu} />}
          {showMenu && <img src={headerclosemenu} alt='headerclosemenu' className='header-close-menu' onClick={handleCloaseMenu} />}
          <img src={mobileheaderlogo} alt='headerlogomobile' className='header-logo-mobile' />
          <div className='header-mobile-right'>
            <img src={facebook} alt='facebook' />
            <img src={instagram} alt='instagram' />
          </div>
        </div>
        <div className='heder-left'>
          <img src={mobileheaderlogo} alt='HeadLogo' className='header-logo-img' />
          <div className='header-pages'>
            <p className={`${location.pathname === '/' ? 'active-route' : ''}`} onClick={handleHome}>მთავარი</p>
            <p className={`${location.pathname === '/services' ? 'active-route' : ''}`} onClick={handleServices}>განყოფილებები</p>
            <p className={`${location.pathname === '/blog' ? 'active-route' : ''}`} onClick={handleBlog}>ბლოგი</p>
          </div>
        </div>
        <div className='header-right'>
          <p className='contact-header blue-header'>საკონტაკტო: +995 568 32 01 64</p>
          <div className='header-right-images'>
            <a href='https://www.facebook.com/profile.php?id=100068552813359' target="_blank"
              rel="noopener noreferrer"><img src={facebook} alt='facebook' /></a>
            <a href='https://www.instagram.com/medical_tourism_iin_turkey?igsh=MW01MDZteDUzNnVzOA%3D%3D&utm_source=qr&fbclid=IwY2xjawHCvFFleHRuA2FlbQIxMAABHR-J2Xe6W-4BXUZ57UADB89QiL6hQkSJuzby4KVR3r0OQcDmY0t2X5ashA_aem_st__iNczmmGR_lFfYX-muw' target="_blank"
              rel="noopener noreferrer"><img src={instagram} alt='instagram' /> </a>
            <a href="https://wa.me/995568320164?text=გამარჯობა" target="_blank" rel="noopener noreferrer">            <img src={whatsapp} alt='whatsapp' />
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header