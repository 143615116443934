import React from 'react'
import Layout from '../../components/layout/layout'
import blogarrow from '../../images/blog/blogarrow.svg'
import './pets.scss'
import petsblog from '../../images/blog/petsblog.svg'

const Pets = () => {
  return (
    <Layout>
      <div className='bacterial'>



        <div className='blog-txt-head'>
          <h2 className='blog-main-txt-head'>ბლოგი</h2>
          <img src={blogarrow} alt='blogarrow' className='blogarrow' />
          <p className='blog-main-txt-head-sub'>პოზიტრონ-ემისიური ტომოგრაფია (პეტ) და მისი უპირატესობები.</p>
        </div>

        <div className='blog-content'>
          <div className='blog-post-start'>
            <img src={petsblog} alt='petsblog' />
            <div className='blog-post-start-right'>
              <p className='blog-post-start-head'>პოზიტრონ-ემისიური ტომოგრაფია  და მისი უპირატესობები.</p>
              <p>პოზიტრონ–ემისიური ტომოგრაფია მაღალტექნოლოგიური, კომბინირებული პროდუქტია, რადგან სისტემა ერთდროულად ორი კვლევის – პოზიტრონ–ემისიური ტომოგრაფიისა და კომპიუტერული ტომოგრაფიის ჩატარების შესაძლებლობას იძლევა, შესაბამისად, საბოლოოდ ორი უმნიშვნელოვანესი კვლევის მონაცემთა შეჯამება ხდება. </p>
            </div>
          </div>

          <p>აღსანიშნავია, რომ სიმსივნის დიაგნოსტირების დროს, აღნიშნული მეთოდით სიმსივნის გავრცელების ყველაზე ზუსტი ანატომიური და ფუნქციური გამოსახულება, სურათი მიიღება. პოზიტრონ–ემისიური ტომოგრაფიით სრულიად შესაძლებელია უმცირესი ზომის სიმსივნის აღმოჩენა
            პოზიტრონ-ემისიური ტომოგრაფია (PET/CT) ონკოლოგიური დაავადებების დიაგნოსტირების შეუცვლელი მეთოდია. მისი მეშვეობით შესაძლებელია ისეთი ცვლილებების გამოვლენა, რომელიც ვერ ფიქსირდება სხვა რადიოლოგიური კვლევების მიერ.</p>
          <h3>პოზიტრონ–ემისიური ტომოგრაფიით შესაძლებელია:</h3>
          <ul>
            <li>მეტასტაზური პროცესის პირველადი კერის დადგენა.</li>
            <li>კეთილთვისებიანი და ავთვისებიანი სიმსივნეების დიფერენცირება.</li>
            <li>გვეხმარება სხივური თერაპიის დაგეგმარებაში და სხვა.</li>
            <li>აუცილებელია მაშინაც, როცა ბიოფსიისთვის საჭირო ლოკალიზების შერჩევაა საჭირო.</li>
            <li>ონკოლოგიური დაავადების გავრცელების ხარისხის დადგენა.</li>
            <li>გვეხმარება სხივური თერაპიის დაგეგმარებაში და სხვა.</li>
          </ul>
          <h3>რა არის კვლევის მთავარი უპირატესობა?</h3>
          <p>სადღეისოდ, ეს კვლევა (პეტ კატე) ოქროს სტანდარტია ონკოლოგიაში.
            აღსანიშნავია, რომ კომპიუტერული ტომოგრაფია უზრუნველყოფს ანატომიურ გამოსახულებას, რაც უმნიშვნელოვანესია წარმონაქმნის მორფოლოგიური მახასიათებლების დასადგენად. პოზიტრონ-ემისიური ტომოგრაფია კი საშუალებას გვაძლევს, გამოვავლინოთ ორგანიზმში მიმდინარე მეტაბოლური ცვლილებები. ამ ორი კვლევის შედეგების შეჯამებით კი შესაძლებელია სიმსივნური პროცესის  დეტალური შეფასება და ზუსტი დიაგნოსტირება.</p>
        </div>
      </div>

    </Layout>
  )
}

export default Pets