import React, { useState } from 'react';
import Layout from '../../components/layout/layout'
import playservices from '../../images/playservices.svg'
import downsewrvices from '../../images/downsewrvices.svg'

import './services.scss'

const sectionsData = [
  {
    id: 1,
    title: 'აბდომინალური ქირურგია',
    content: [
      'მუცლის ღრუს ორგანოთა დაავადებების ქირურგიული მკურნალობა.',
      'საძილე არტერიების რეკონსტრუქცია.',
      'ფარისებრი ჯირკვლის დაავადებების ქირურგიული მკურნალობა.',
      'რეკონსტრუქციული ოპერაციები  საყლაპავის ახალაზიის და საყლაპავის დიაფრაგმალური ხვრელის თიაქრის დროს.',
      'ღვიძლის ატი პიური რეზექცია, სპლენექტომია, ადრენალექტომია, ქოლეცისტექტომია, აპენდექტომია, თიაქარპლასტიკა.',
      'რადიკალური, ორგანოშემანარჩუნებელი და რეკონსტრუქციულ-აღდგენითი. ონკოქირურგიული ოპერაციები:',
      'ქვემო კიდურების ვარიკოზული დაავადების მკურნალობა ენდოსკოპური მეთოდითკოლექტომია, გასტრექტომია  და ჰემიკოლექტომია.',
      'საყლაპავის, კუჭ-ნაწლავის ტრაქტის - კუჭის, წვრილი და მსხვილი  ნაწლავის, სწორი ნაწლავის, ღვიძლის, პანკრეასის, რეტროპერიტონული სივრცის სიმსივნეების, ',
      'მცირე მენჯის კეთილთვისებიანი  და ავთვისებიანი პათოლოგიების დროს.',
      'პანკრეატოდუოდენალური რეზექცია.',
      'ბარიატრიული ქირურგიული ჩარევები ლაპარასკოპიული მეთოდით.',
    ],
  },
  {
    id: 2,
    title: 'ალერგოლოგია ',
    content: [
      'ალერგიული რინიტი',
      'ბრონქული ასთმა',

      'ურტიკარია (ე.წ. ჭინჭრის ციება)',
      'ანგიონევროზული შეშუპება (კვინკეს შეშუპება)',
      'ატოპიური დერმატიტი',
      'წამლისმიერი ალერგია',
      'კვებითი ალერგია',
      'მედიკამენტური ალერგია',
      'ბრონქული ასთმა',
      'ალერგია მწერის ნაკბენზე და მზეზე',
    ],
  },
  {
    id: 3,
    title: 'ანგიოლოგია და სისხლძარღვთა ქირურგია',
    content: [],
  },
  {
    id: 4,
    title: 'ანესთეზიოლოგიისა და რეანიმატოლოგიის დეპარტამენტი',
    content: [
      'ოპერაციის წინა ვიზიტი, პაციენტების გასინჯვა და ოპერაციისთვის მომზადება',
      'ანესთეზიოლოგიური ტექნიკის შერჩევა სხვადასხვა ტიპის ოპერაციების და სპეციალური საჭიროებების დროს',
      'პოსტოპერაციული ინტენსიური თერაპია',
      'ანესთეზიის უზრუნველყოფა მოხუც პაციენტებში',
      'ანესთეზიის უზრუნველყოფა წონაგადაჭარბებულ პაციენტებში',
      'ანესთეზიის უზრუნველყოფა კარდიოლოგიური პრობლემების და შაქრიანი დიაბეტის მქონე პაციენტებში',
      'ანესთეზიის უზრუნველყოფა პედიატრიულ პაციენტებში დიაგნოსტიკური გამოკვლევების MRI, CT, PET სკანირების ჩასატარებლად',
      'ანესთეზიის უზრუნველყოფა ენდოსკოპიური გამოკვლევების დროს',
      'ანესთეზიის უზრუნველყოფა კლაუსტროფობიის (დახურული სივრცის შიშის) მქონე პაციენტებში დიაგნოსტიკური პროცედურების დროს',
      'ანესთეზიის უზრუნვეყოფა ონკოლოგიური პრობლემების მქონე პაციენტებში',
    ],
  },
  {
    id: 5,
    title: 'ბირთვული მედიცინის დეპარტამენტი',
    content: [

      'ონკოლოგიური დაავადების გავრცელების ხარისხის დადგენა (სტადირება/რესტადირება)',
      'რეციდივისა და/ან განგრძობითი ზრდის დადგენა, როცა კლინიკური და ანატომიური მონაცემები უარყოფითია (მაგ.მომატებული სიმსივნური მარკერების ფონზე)',
      'მეტასტაზური პროცესის და/ან პარანეოპლაზიური სინდრომის დროს პირველადი კერის დადგენა',
      'კეთილთვისებიანი/ავთვისებიანი სიმსივნეების დიფერენცირება',
      'მკურნალობის (ქიმიოთერაპიის, სხივური თერაპიის, კომბინირებული) ეფექტურობის შეფასება',
      'ბიოფსიისთვის საუკეთესო ლოკალიზაციის შერჩევა',
      'ქირურგიულ მკურნალობის დაგეგმარება',
      'სხივური თერაპიის დაგეგმარება',
      'ძვლის სცინტიგრაფიული კვლევა ონკოლოგიურ პაციენტებში (მეტასტაზური დაზიანების გამოსავლენად)  და ტრავმატოლოგიურ პაციენტებში (ძვლის ოსტეომიელიტის და პროტეზის მორყევის სადიაგნოსტიკოდ)',
      'ფარისებრი ჯირკვლის სცინტიგრაფია (თირეოტოქსიკოზის მიზეზის დასადგენად. ასევე ფარისებრი ჯირკვლის კვანძების ფუნქციური მდგომარეობის შესასწავლად -- ჰიპერ და/ან ჰიპოფიქსაციის უბნების გამოსავლენად)',
      'ფარისებრახლო ჯირკვლის სცინტიგრაფია (ფარისებრახლო ჯირკვლის ადენომის გამოვლენის მიზნით)',
      'თირკმლის დინამიური და კორტიკალური ნეფროსცინტიგრაფია (თირკმელების ფარდობითი ფუნქციური მდგომარეობის და GFR შეფასების მიზნით; თირკმლის თანდაყოლილი ანომალიების გამოსავლენად,თირკმლის არტერიის სტენოზისა და  ვაზორენალური ჰიპერტენზიის სადიაგნოსტიკოდ;  თირკმლის ტრავმის, ნაწიბურების,საშარდე გზების ინფექციური დაავადებების და  შარდის ბუშტ- შარდსაწვეთის რეფლუქსის გამოსავლენად, პრაქტიკულად შეუცვლელია თირკმლის ობსტრუქციული დაავადებების დიაგნოსტიკაში)',
      'კუჭის სცინტიგრაფია (კუჭის მოტორულ-ევაკუაციური ფუნქციის შეფასებისთვის. ე.წ. კუჭის დაცლის სკანირება)',
      'მეკელის დივერტიკულის სცინტიგრაფია (ყველაზე სარწმუნო მეთოდია გასტროინტესტინური ტრაქტის უხშირესი თანდაყოლილი დარღვევის მეკელის დივერტიკულის გამოსავლენად, მცირეწლოვან ბავშვებში მელენის მიზეზის დასადგენად )',
      'ძვლის სცინტიგრაფიული კვლევა ტრავმატოლოგიურ პაციენტებში',
      'მიოკარდიუმის სცინტიგრაფია (მიოკარდიუმის სისხმომარაგების სადიაგნოსტიკოდ)',
      'ლიმფური სისტემის სცინტიგრაფია (ლიმფური სადინრების გამავლობის შეფასების მიზნით)',
    ],
  },

  {
    id: 6,
    title: 'ბრონქოლოგიისა და ინტერვენციული პულმონოლოგიის დეპარტამენტი',
    content: [
      'ზედა სასუნთქი გზების ვიდეოენდოსკოპია',
      'ლარინგოსკოპია',
      'ტრაქეოსკოპია',
      'ბრონქოსკოპია',
      'ენდობრონქული ულტრასონოგრაფია ტრანსბრონქული ბიოფსიით (EBUS-TBNA)',
      'ენდობრონქული ულტრასონოგრაფია ფილტვის პერიფერიული წარმონაქმნების ბიოფსიით და ბრაშ-ბიოფსიით.',
    ],
  },

  {
    id: 7,
    title: 'გასტროენტეროლოგია',
    content: [
      'პირის ღრუსა და საყლაპავის ანთებითი, პეპტიური და ინფექციური პათოლოგიები. გასტროეზოფაგალური და ლარინგოფარინგეალური რეფლუქსების ასვე ეოზინოფილური ეზოფაგიტების მართვა. საყლაპავის წინასიმსივნური პათოლოგიების დიაგნოსტიკა და მენჯმენტი. საყლაპავის კაუსტიკური დაზიანებები.',
      'კუჭისა და თორმეტგოჯა ნაწლავის პეპტიური დაავადებები - მათ შორის ატროფიული გასტრიტები და სხვა წინასიმსივნური პათოლოგიების მართვა. H.pylori-სთან ასოცირებული გასტრიტებისა და სხვადასხვა წყლულოვანი თუ არაწყლულოვანი პროცესების მართვა.',
      'ნაღვლის ბუშტისა და სანაღვლე გზების პათოლოგიები, მათ შორის ნაღვლკენჭოვანი დაავადებები, არაკალკულოზური ბილიარული ტკივილები, აუტოიმუნური გენეზის სანაღვლე გზების პათოლოგიები. სანაღვლე გზების სხვადასხვა წინასიმსივნური პათოლოგიების მართვა და მენეჯმენტი.',
      'კუჭუკანა ჯირკვლის ფუნქციურ და სტრუქტურულ პათოლოგიებთან დაკავშირერებული დაავადებების მართვა. მათ შორის პანკრეასის ცისტური დაავადებების მენეჯმენტი. აუტოიმუნური თუ სხვა გენეზის პანკრეატიტების მართვა.',
      'ღვიძლის ქრონიკული პათოლოგიების მართვა, მათ შორის აუტოიმუნური, ალკოჰოლური, მეტაბოლური და ტოქსიური ჰეპატიტები, ღვიძლის გაცხიმოვნება და სტეატოჰეპატიტები. ციროზული და არაციროზული პორტული ჰიპერტენზიები, ასევე ციროზი და მისი მართვა.',
      'წვრილი და მსხვილი  ნაწლავის მალდიგესტიითა და მალაბსორბციით მიმდინარე დაავადებები. ინფექციური თუ არაინფექციური ენტერიტები და ენტეროკოლიტები. სხვადასხვა გენეზის მწვავე და ქრონიკული დიარეების მართვა. ნაწლავის არასპეციფიური ანთებები, მათ შორის არასპეციფიური წყლულოვანი კოლიტი, კრონის დაავადება და მიკროსკოპული კოლიტები, ყაბზობა',
      'კუჭ-ნაწლავის ტრაქტთან ასოცირებული სხადასხვა მეტაბოლური დარღვევების მართვა.',
    ],
  },

  {
    id: 8,
    title: 'გინეკოლოგია',
    content: [
      'მცირე მენჯის ღრუს ორგანოების  ანთებითი და არაანთებითი დაავადებები',
      'სქესობრივი გზით გადამდები დაავადებები',
      'საშვილოსნოს და საკვერცხეების ანატომიური პათოლოგიები:',
      'საკვერცხის ცისტები, ენდომეტრიუმის  ჰიპერპლაზია, საშვილოსნოს მიომა',
      'გენიტალური ენდომეტრიოზი',
      'მენსტრუალური ციკლის დარღვევები პუბერტატულ, რეპროდუქციულ, პერი და პოსტმენოპაუზალურ პერიოდში (ჭარბი, აციკლური სისხლდენები, შემცირებული მენსტრუალური ციკლი,მენსტრუალური ციკლის გადაცდენა) პოლიცისტური საკვერცხეების სინდრომი, აკნე, ჭარბთმიანობა, ენდოკრინული ჯირკვლების  დისბალანსი და მასთან დაკავშირებული რეპროდუქციული დარღვევები (ჰიპოთირეოზი, ჰიპერპროლაქტინემია, თირკმელზედა ჯირკვლის თანდაყოლილი პათოლოგიები, ნივთიერებათა ციკლის დარღვევები)',
      'პრემენსტრუალური სინდრომი ( პრემენსტრუალური შაკიკი, პრემენსტრუალური ალერგია, პრემენსტრუალური დისფორიული სინდრომი....)',
      'ჩვეული აბორტი - 2 და მეტი თვითნებითი აბორტის მიზეზების განსაზღვრა და მკურნალობა',
      'საშვილოსნოს ყელის, სარძევე ჯირკვლების კიბოსწინარე და არაკიბოსწინარე დაავადებების დიაგნოსტიკა და მკურნალობა (სკრინინგული გამოკვლევები - პაპ, ტეტსი, კოლპოსკოპია, მაღალი ონკოტიპის პაპილომავირუსის გამოვლენა - HPV DNA test, სარძევე ჯირკვლების ექოსკოპია, მამოგრაფია)',
      'კლიმაქტერული პერიოდი და მასთან დაკავშირებული დარღვევების დროული დიაგნოსტიკა და კორექცია - ფსიქო - ემოციური, ვეგეტო-სისხლძარღვოვანი, ურო-გენიტალური დარღვევები (საშოს სიმშრალე, ლიბიდოს დაქვეითება, ტკივილი სქესობრივი ურთიერთობის დროს) და ოსტეპოროზი.',
      'ოჯახის დაგეგმვა და კონტრაცეპცია',
      'შვილობის მიზეზების დიაგნოსტიკა და მკურნალობა',
      'ერთი ციკლის განმავლობაში ბაზალური ჰორმონალური სპექტრის განსაზღვრა',
      'ფოლიკულოემტრია',
      '3D ექოსკოპია',
      'ფალოპის მილების ანატომიური მდგომარეობის შეფასება',
      'სპერმოგრამა',
      'საკვერცხეების სტიმულაცია ნატურალურ ციკლში',
      'საშვილოსნოს შიდა ინსემინაცია',
      'პაციენტების მომზადება ხელოვნური განაყოფიერებისათვის',
    ],
  },
  {
    id: 9,
    title: 'დერმატოლოგია',
    content: [
      'კანის ბაქტერიული დაავადებები',
      'კანის სოკოვანი დაავადებები',
      'კანის ვირუსილი დაავადებები',
      'კანზე პიგმენტაციის დაღღვევები',
      'თმის და ფრჩხილის დაავადებები',
      'კანის გარქოვანებით მიმდინარე დაავადებები',
      'დერმატიტები',
      'ბულოზური დაავადებები',
      'კანზე მომატებული მგრძნობელობით და ანთებით მიმდინარე დაავადებები',
      'ფსორიაზი და აქერცვლით მიმდინარე დაავადებები',
      'აკნე და მასთან დაკავშირებული დაავადებები',

    ],
  },
  {
    id: 10,
    title: 'ელექტრო კარდიოგრაფია',
    content: [
    
    ],
  },
  {
    id: 11,
    title: 'ენდოსკოპიისა და ენდოსკოპიური ქირურგიის დეპარტამენტი',
    content: [
      'საყლაპავი მილის, კუჭის, თორმეტგოჯა  ნაწლავის და მსხვილი ნაწლავის ენდოსკოპური კვლევა, პილორული ჰელიკობაქტერიების გამოვლენა ბიოფსიური მეთოდით',
      'საყლაპავის ვარიკოზულად გაგანიერებული ვენების ენდოსკოპური ლიგირება',
      'საყლაპავი მილის აქალაზიით, დამწვრობით და ანასტომოზიტებით გამოწვეული შევიწროვების ბალონური დილატაცია',
      'კუჭის კიბოს ადრეული ფორმების  გამოვლენა.',
      'საყლაპავი მილის, კუჭისა და ნაწლავების სიმსივნეებით განპირობებული შევიწროვების მკურნალობა სტენტებით.',
      'პოლიპების ენდოსკოპური დიაგნოსტიკა და ენდოსკოპური პოლიპექტომია, ლორწოვანის რეზექცია',
      'კოლიტის სხვადასხვა ფორმის ენდოსკოპური და მორფოლოგიური სურათის დადგენა',
      'სანაღვლე გზების დაავადებათა ენდოსკოპური დიაგნოსტიკა და მკურნალობა. (პაპილოტომია, სტენტირება და ბალონური დილატაცია)',
      'თერმული ჰემოსტაზი და დევიტალიზაცია',
      'ჰეპატოპანკრეატოდუოდენალური ზონის დაავადებათა დიაგნოსტიკა და მკურნალობა',
      'საჭმლის  მომნელებელი   სისტემის სიმსივნეების ადრეული დიაგნოსტიკა და მკურნალობა',
      'ენდოსკოპური ულტრასონოგრაფია საჭმლის მომნელებელი სისტემის დაავადებათა დიაგნოსტიკაში',
    ],
  },
  {
    id: 12,
    title: 'ენდოკრინოლოგია',
    content: [
      'შაქრიანი დიაბეტი (ტიპი 1, ტიპი 2, გესტაციური დიაბეტი, დიაბეტის სხვა ფორმები)',
      'სიმსუქნე და მეტაბოლური სინდრომი',
      'ფარისებრი ჯირკვლის დაავადებები (ჰიპოთირეოზი, ჰიპერთირეოზი, ფარისებრი ჯირკვლის კვანძოვანი დაავადებები, ფარისებრი ჯირკვლის ონკოლოგიური დაავადებები და ა.შ)  ',
      'ფარისებრახლო ჯირკვლების დაავადებები (ჰიპერპარათირეოზი, ჰიპოპარათირეოზი)',
      'ჰიპოფიზის დაავადებები (აკრომეგალია, ჰიპერპროლაქტინემია, უშაქრო დიაბეტი, კუშინგის დაავადება და ა.შ)',
      'თირკმელზედა ჯირკვლების დაავადებები (ჰიპერალდოსტერონიზმი, ადისონის დაავადება და ა.შ)',
      'ვიტამინებისა და მიკროელემენტების დეფიციტით, მინერალური ნივთიერებების  ცვლის დარღვევით  განპირობებული პათოლოგიები (ოსტეოპოროზი და ა.შ.)',
      'სრულად ავტომატიზირებული ლაბორატორია',
      'ულტრაბგერითი გამოკვლევა',
      'ფარისებრი ჯირკვლის, ფარისებრახლო ჯირკვლების სცინტიგრაფიული კვლევა',
      'კომპიუტერული ტომოგრაფია',
      'მაგნიტურ-რეზონანსული ტომოგრაფია',
      'დენსიტომეტრია',
    ],
  },
  {
    id: 13,
    title: 'ექოსკოპიის დეპარტამენტი',
    content: [
    ],
  },
  {
    id: 14,
    title: 'თავ-კისრის ქირურგიის დეპარტამენტი',
    content: [
      'თავ-კისრის სიმსივნეებს მიეკუთვნება:',
      'ხორხის',
      'ფარისებრი ჯირკვლის',
      'ცხვირ-ხახის',
      'პირ-ხახის',
      'თვალბუდის',
      'ქვედა ყბის',
      'სასმენი აპარატის',
      'სანერწყვე ჯირკვლების',
      'კანის სიმსივნეები და სხვა',
    ],
  },
  {
    id: 15,
    title: 'თორაკალური ქირურგია',
    content: [
    ],
  },
  {
    id: 16,
    title: 'ინტერვენციული რადიოლოგიის დეპარტამენტი',
    content: [
      'წვრილნემსიანი ბიოფსია ციტოლოგიური კვლევისათვის',
      'მსხვილნემსიანი ბიოფსია ქსოვილოვანი მორფოლოგიური კვლევისათვის (რბილი ქსოვილები,ლიმფური კვანძები, მუცლის ღრუს პარენქიმული       ორგანოები, რეტროპერიტონეული სივრცე, გულმკერდი , ფილტვი, შუასაყარის ჩათვლით)',
      'მუცლის ღრუს და  პლევრის ღუს სითხის დიაგნოსტიკური ასპირაცია ციტოლოგიის მიზნით',
      'პერკუტანული ქოლანგიოგრაფია',
      'ღვიძლშიდა სანაღვლე გზების გარეთა დრენირება (ქოლანგიოსტომია)',
      'ღვიძლშიდა სანაღვლე გზების გარეგან-შინაგანი დრენირება',
      'ნაღვლის ბუშტის გარეთა დრენირება (ქოლეცისტოსტომია)',
      'სუბდიაფრაგმული აბსცესის დრენირება',
      'ღვიძლის აბსცესების ასპირაცია',
      'ღვიძლის აბსცესების დრენირება',
      'ღვიძლის ჰემატომის ასპირაცია-დრენირება',
      'ღვიძლის ცისტის (მათ შორის პარაზიტულის) ასპირაცია-დრენირება',
      'პერკუტანული ვირზუნგოსტომია',
      'ღვიძლის სიმსივნეთა ტრანსარტერიული ემბოლიზაცია და ქემოემბოლიზაცია',
      'პანკრეასის ფსევდოცისტის პერკუტანული ასპირაცია',
      'ნეფროსტომია',
      'თირკმლის ცისტის ასპირაცია-სკლეროთერაპია',
      'თირკმლის ცისტის (აბსცესის) დრენირება',
      'ტუბოოვარიული აბსცესის პერკუტანული დრენირება',
      'ტუბოოვარიული აბსცესის პერვაგინური და პერრექტული ასპირაცია-გამორეცხვა,დრენირება',
      'საკვერცხის ცისტის ასპირაცია პერკუტანული და პერვაგინური მიდგომით',
      'წინამდებარე ჯირკვლის აბსცესის პერრექტული ასპირაცია-გამორეცხვა',
      'პარაპროქტიტული აბსცესის ასპირაცია-გამორეცხვა პერრექტული მიდგომით',
      'მუცლის კედლის პოსტოპერაციული სერომების ასპირაცია',
      'ასციტური სითხის დრენირება',
      'სარძევე ჯირკვლის ცისტების ასპირაცია',
      'პერიკარდიუმის ღრუს დრენირება',
      'ქსოვილოვან წარმონაქმნთა  ლოკალური აბლაცია',
      'სიმსივნეთა პერკუტანული რადიოსიხშირული(რსა) და მიკროტალღური აბლაცია (მტა)',
      'სისხლძარღვთა და სადინრების გამავლობის აღდგენა  (ენდოლუმინალური რსა–ის ჩათვლით)',
      'სანაღვლე გზების დილატაცია/ სტენტირება',
      'ვირზუნგის სადინრის სტენტირება ',
      'სანაღვლე გზების ბლოკის რეკანალიზაცია სტენტირებით ',
      'ვირზუნგის სადინრის ბლოკის რეკანალიზაცია სტენტირებით',
      'ანგიოგრაფიული მიდგომით წარმოებული ჩარევები',
      'ემბოლიზაციები შინაგანი სისხლდენების დროს',
      'ღვიძლის სიმსივნეთა ტრანსარტერიული ემბოლიზაცია და ქემოემბოლიზაცია.',
      'პერკუტანული ლითოლაპაქსიის პროცედურები',
      'პერკუტანული ბილიოლითოლაპაქსია',
      'პერკუტანული ვისრზუნგოლითოლაპაქსია',
    ],
  },
  {
    id: 17,
    title: 'ინფექციურ სნეულებათა დეპარტამენტი',
    content: [

    ],
  },
  {
    id: 18,
    title: 'კანისა და რბილი ქსოვილების ქირურგიის დეპარტამენტი',
    content: [
      'ანის კიბოს დროს სხვადასხვა მასშტაბისა და სირთულის ქირურგიული ოპერაციები, როგორც სიმსივნის ფართო ამოკვეთა ერთმომენტიანი       პლასტიკით ადგილობრივი ქსოვილების გამოყენებით, ასევე კანის თავისუფალი ნაფლეთის გადანერგვით',
      'მელანომის ქირურგიული მკურნალობა - მელანომის ფართო ამოკვეთა კანის გადანერგვით',
      'იღლიისქვეშა და საზარდულის ლიმფადენექტომიები კანის კიბოს და მელანომების მეტასტაზების დროს',
      'რბილქსოვილოვანი კეთილთვისებიანი სიმსივნეების ქირურგიული მკურნალობა (ფიბროლი პომა, ათერომა, ჰემანგიომა და სხვ.)',
      'ყველა ტიპის რბილქსოვილოვანი სარკომების ქირურგიული მკურნალობა',
      'რბილქსოვილოვანი სარკომების დროს კიდურის შემანარჩუნებელი ოპერაციები: სიმსივნის ამოკვეთა, კუნთისა და სისხლძარღვების აღდგენა',
      'ყველა ტიპის ამპუტაციები და ეგზარტიკულაციები შორსწასული რბილქსოვილოვანი და ძვლოვანი ავთვისებიანი პათოლოგიების დროს   კიდურებსა და თითებზე',
      'ძვლების კეთილთვისებიანი და ავთვისებიანი სიმსივნეების ქირურგიული მკურნალობა',
    ],
  },
  {
    id: 19,
    title: 'კარდიოლოგიის დეპარტამენტი',
    content: [
      'კარდიოლოგის კონსულტაცია',
      'ელექტროკარდიოგრაფია 12 სტანდარტული განხრით',
      'ტრანსთორაკალური ექოკარდიოგრაფია',
      '24 სთ-იანი ეკგ მონიტორირება ჰოლტერის მეთოდით',
      '24 სთ-იანი წნევის მონიტორირება ',
      'დატვირთვის სტრესს-ტესტი (ტრედმილით)',
      'ლაბორატორული გამოკვლევები',
    ],
  },
  {
    id: 20,
    title: 'კლინიკური ლაბორატორია',
    content: [
      'ბიოქიმიური გამოკვლევები - ფერმენტები, ნახშირწყლები, ცილები და ლიპიდების მეტაბოლიზმი, ჰომოცისტეინი, ამიაკი, ანტიოქსიდანტები განისაზღვრება  ავტომატურ ანალიზატორებზე COBAS c 311 და COBAS INTEGRA 400 PLUS(ROCHE DIAGNOSTICS , ელექტროლიტები -  EASYLYTE(MEDICA) მეშვეობით.',
      'ჰემატოლოგიური გამოკვლევები - სრულდება რეგიონში ანალოგის არმქონე უახლესი თაობის ჰემატოლოგიური ანალიზატორზე  (CBC+DIFF)         SYSMEX  XN1500 და SYSMEX  XT4000i. ერთ სისტემაში რამოდენიმე შერწყმული მოდული - მთვლელი, სისხლის ნაცხების შეღებვისა და უჯრედების ვიზუალიზაცია. ანთებითი პროცესის დიაგნოსტიკის ახალი მაჩვენებლები. ანომალური უჯრედების კლასიფიკაცია. თრომბოციტების გამოთვლის მაქსიმალური სიზუსტე (იმპედანსი, ფლუორესცენცია). ედს განსაზღვრა ვესტერგრინის მეთოდით VACUETTE (GREINER BIO-ONE)',
      'სისხლის შედედების სისტემის შესწავლა - წარმოებს კოაგულოლოგიური ანალიზატორების STA COMPACT MAX 3  და  STA COMPACT (DIAGNOSTICA STAGO) მეშვეობით - სისხლის შედედების პარამეტრების გამოკვლევის  ქრომოგენური, კლოტინგური და იმუნოლოგიური მეთოდების მარტივი და უჭკვიანესად მოფიქრებული  შერწყმა საშუალებას გვაძლევს განვახორციელოთ  თრომბოფილიის, ანტიკოაგულანტების მოქმედების კონტროლის, ანტიფოსფოლიპიდური სინდრომის, ჰემოფილიის, ვილებრანდის დაავადების, დიკ სინდრომისა,  ქირურგიული  და ონკოლოგიური პაციენტების მონიტორინგი.',
      'შარდის მრავალმოდულიანი ანალიზი -  SYSMEX  UF3500, UF4000 ,UD10 - შარდის ფიზიკურ-ქიმიური თვისებები, ფორმიანი ელემენტების, მარილოვანი კრისტალების, ბაქტერიებისა(გრამ დადებითი, უარყოფითი) და საფუარის მსგავსი უჯრედების ერთდროული აღმოჩენა.',
      'ბიოლოგიური სითხეების შემადგენლობის განსაზღვრა ბიოქიმიური და პოლარიზაციული მეთოდებით (ლიქვორი, პლევრისა და  სინოვიალური სითხე და სხვ).',
    ],
  },
  {
    id: 21,
    title: 'კლინიკური კვლევების დეპარტამენტი',
    content: [
      'ლაბორატორიაში შესაძლებელია სისხლის საერთო, ბიოქიმიური, იმუნოლოგიური, ბაქტერიოლოგიური თუ მორფოლოგიური კვლევების, ასევე მოლეკულური გენეტიკის სხვადასხვა ტესტების წარმატებით განხორციელება',
    ],
  },
  {
    id: 22,
    title: 'კლინიკური იმუნოლოგიის ლაბორატორია',
    content: [
      'ჰორმონალური ფერტილობა',
      'თირეოიდული და პარათირეოიდული ჯირკვლის ჰორმონები',
      'ჰიპოფიზის ჰორმონები',
      'მეტაბოლური დარღვევები',
      'კარდიალური მარკერები',
      'ანთების მარკერები',
      'სიმსივნის მარკერები',
      'ორსულობის მონიტორინგი',
      'ვიტამინები',
      'ინფექციური დაავადებები',
      'ჰეპატიტები',
      'ადამინის იმუნოდეფიციტის სინდრომი',
      'უროგენიტალური ინფექციები',
      'რევმატოლოგიური დაავადებები - ანთებითი და დეგენერაციული',
      'სისხლისა და სისხლძარღვების დაავადებები',
      'თრომბოზებისა და ანტიფოსფოლიპიდური სინდრომის მარკერები',
      'გასტროენტეროლოგიური დაავადებები',
      'მეტაბოლური დაავადებები რევმატოიდული დისფუნქციით',
    ],
  },
  {
    id: 23,
    title: 'კომპიუტერული ტომოგრაფიის დეპარტამენტი',
    content: [
      'თავის ტვინის, ქალას ძვლების, ხერხემლის სვეტის, გულმკერდის, მუცლისა და მცირე მენჯის ღურს კომპიუტერულ-ტომოგრაფიული დიაგნოსტიკა.',
      'კტ-ანგიოგრაფია',
      'თავის ტვინის პერფუზიის შესწავლა',
      'არაინვაზიური კტ კორონაროგრაფია - კვლევა ხასიათდება მაღალი სიზუსტით. არ საჭიროებს ჰოსპიტალიზაციას და არ ახასიათებს გართულებები. კვლევა გრძელდება 5-7 წუთი',
      'კტ-დენსიტომეტრია - ძვლის მინერალური სიმკვრივის რაოდენობრივი შეფასება და ოსტეოპოროზის ხარისხის დადგენა',
      'სხვადასხვა ინტერვენციული პროცედურები',
    ],
  },
  {
    id: 24,
    title: 'მაგნიტურ-რეზონანსული ტომოგრაფიის დეპარტამენტი - მრტ',
    content: [
      'თავის ტვინის სტრუქტურული კვლევა, მათ შორის სუბლიმეტრული ჭრილებით, რაც მკვეთრად ზრდის ნებისმიერი წვრილკეროვანი დაზიანების ალბათობას',
      'თავის ტვინისა და კისრის მაგისტრალური სისხლძარღვების მაგნიტურ-რეზონანსული ანგიოგრაფია – აღნიშნული მეთოდი ჩვეული რენტგენოკონტრასტული გამოკვლევის არაინვაზიური ალტერნატივაა.',
      'თავის ტვინის ფუნქციური გამოკვლევები უმაღლესი ნერვული ფუნქციების შესწავლა, ქერქული ცენტრების ლოკალიზაცია, მეტყველების, მოძრაობის მხედველობის, მეხსიერების შესწავლა. აღნიშნული მეთოდები გვეხმარება როგორც ავადმყოფების პრექირურგიულ გამოკვლევაში, რათა თავიდან იქნას აცილებული ოპერაციული ჩარევის შედეგად ამა თუ იმ მნიშვნელოვანი კერული უბნის დაზიანება, ასევე აღნიშნული კვლევით შესაძლებელი კოგნიტური ფუნქციების დარღვევების ადრეული დიაგნოსტირება.',
      'თავის ტვინის თეთრი ნივთიერების ტრაქტოგრაფია – თეთრი ნივთიერების ბოჭკოების მთლიანობის, მიმართულებისა და კავშირების შესწავლა. პათოლოგიური კერების ურთიერთობის დადგენა ფუნქციურად მნიშვნელოვან ტრაქტებთან.',
      'დაავადების გამოსავლის პროგნოზირება თეთრი ნივთიერების დაზიანების ხარისხის შესწავლის საფუძველზე, თავის ტვინის პერფუზიული და დიფუზიური კვლევა, იშემიური დაზიანების გამოვლენა უმწვავეს სტადიაში, სიმსივნური წარმონაქმნების ხასიათის დიფერენცირება.',
      'ფუნდამენტური კვლევები ნეირომეცნიერების დარგში–მეხსიერების ფუნქციური კვლევა, თავის ტვინის ტრაქტოგრაფიული კვლევები.',
      'მუცლის ღრუს ორგანოების: ღვიძლის, ელენთის, პანკრეასის, თირკმელზედა ჯირკვლის და თირკმელების კვლევა,სანაღვლე გზების უკონტრასტო მაგნიტურ-რეზონანსული ქოლანგიოპანკრეატოგრაფია,თირკმლის დინამიური კვლევა და მაგნიტურ-რეზონანსული ანგიოგრაფია.',
      'მცირე მენჯის ორგანოთა დაავადებების მაგნიტურ-რეზონანსული დიაგნოსტიკა.',
      'საყრდენ - მამოძრავებელი სისტემის სტრუქტურული და ფუნქციური დარღვევების მაგნიტურ-რეზონანსული კვლევა.',
      'პედიატრიული პაციენტების მაგნიტურ-რეზონანსული კვლევა',
    ],
  },
  {
    id: 25,
    title: 'მიკრობიოლოგიის ლაბორატორია',
    content: [
      'ზემო სასუნთქი გზების დაავადებების, ჩირქოვან - ანთებითი პროცესების გამომწვევი მიკროფლორის, ვირუსული, სოკოვანი, ინფექციური პათოლოგიების დიაგნოსტიკა.',
      'სხვადასხვა ბიოლოგიური ნიმუშების: შარდის, ნახველის, ურეთრის და უროგენიტალური ნაცხის, პროსტატის სეკრეტის, ჩირქის, ჭრილობიდან აღებული მასალის ექსუდატებისა და გამონაჟონი სითხეების, თავზურგტვინის სითხის, ბაქტერიოლოგიური გამოკვლევა იდენტიფიკაცია, სისხლის სტერილობის ბაქტერიოლოგიური გამოკვლევა (მიკრობის იდენტიფიკაცია და ანტიბიოტიკების მიმართ მგრძნობელობა)',
      'A + B ჯგუფის გრიპის ვირუსის აღმოჩენა ნაზოფარინგეალურ ნაცხში, ადამიანის პაპილომა ვირუსის მაღალი რისკის ტიპების, მიკოპლაზმა ჰომინისის და ქლამიდია ტრაქომატისის , C და B ჰეპატიტის ვირუსის აღმოჩენა და გენოტიპირება პჯრ მეთოდით რეალურ დროში.',
      'კუჭ - ნაწლავის ტრაქტის მიკროფლორის, ჰელიკობაქტერიების ანტიგენის, როტა-ადენოვირუსების, ენტეროვირუსების, ჰემორაგიული ეშერიხია კოლის VT - 1+VT-2, კლოსტრიდიუმ დეფიცილე A+B ტოქსინის შესწავლა, იერსინია ენტეროკოლიკა-ს აღმოჩენა ფეკალურ მასაში.',
      'ტესტი კალპროტექტინზე და ფარულ სისხლდენაზე, ნაწლავის პათოლოგიური ფლორის და დისბაქტერიოზის შესწავლა,',
      'სპერმოგრამა - სპერმის რაოდენობრივი და  ხარისხობრივი შესწავლა აგრეთვე სათესლე სითხის ბაქტერიოლოგიური კვლევა',
      'კლინიკური მიკრობიოლოგიის და მოლეკულური დიაგნოსტიკის  ლაბორატორია არის თსსუ სარეზიდენტო პროგრამა ‘’ ლაბორატორიული მედიცინის“ სასწავლო ბაზა',
    ],
  },
  {
    id: 26,
    title: 'მოლეკულური დიაგნოსტიკისა და ონკოგენეტიკის ლაბორატორია',
    content: [
    ],
  },
  {
    id: 27,
    title: 'ნეიროქირურგიის დეპარტამენტი',
    content: [
      'თავის ტვინის კეთილთვისებიანი და ავთვისებიანი სიმსივნეები',
      'ზურგის ტვინის სიმსივნეები (ინტრა-და ექსტრა-მედულარული სიმსივნეები)',
      'ჰიპოფიზის ადენომა (ენდოსკოპიით)',
      'მენინგიომა და ნევრინომა',
      'თავის ტვინის განვითარების ანომალიები',
      'III პარკუჭის კოლოიდური კისტა',
      'თავის ტვინის მეორადი დაზიანება (მეტასტაზები)',
      'თავის ტვინის პარაზიტული დაზიანება',
      'გლიური სიმსივნეების შემთხვევაში გამოიყენება ფლუროსცეიული მეთოდი',
      'თავის ტვინის  სისხლძარღვოვანი დაავადებები',
      'თავის ტვინის სისხლძარღვების ანევრიზმა(ანევრიზმის კლიპირება)',
      'არტერიო-ვენური მალფორმაცია.',
      'ხერხემლის პათოლოგიების მკურნალობა',
      'მალთაშუა დისკის თიაქარი (მიკროდისკექტომია)',
      'არასტაბილური ხერხემალი',
      'მალების წანაცვლება (სპონდილოლისთეზი)',
      'ქალატვინის და წელის ტრავმა (ქირურგიული და კონსერვატული მკურნალობა)',
      'სამწვერა ნერვის ნევრალგიის ქირურგიული მკურნალობა მიკროვასკულურიდეკომპრესია)',
      'ჰემიფაციალური სპაზმი (სახის ნერვის მიკროვასკულური დეკომპრესია).',
      'ჰიდროცეფალიის მკურნალობა',
      'მესამე პარკუჭის ფსკერის ენდოსკოპიური პერფორაცია',
      'ვენტრიკულოპერიტონეალური შუნტირება',
      'ვენტრიკულოატრიალური შუნტირება',
      'ლუმბოპერიტონეალურიშუნტირება',
      'რეკონსტრუქციული ნეიროქირურგია',
      'ქალას/ქალასარქველის პოსტოპერაციული დეფექტების მთლიანობის აღდგენა 3D მოდელირებული იმპლანტების მეშვეობით',
      'ეპილეფსიის ქირურგიული მკურნალობა',
      'პერიფერიული ნერვები',
      'კარპალური არხის სინდრომი',
      'კუბიტალური არხის სინდრომი',
    ],
  },
  {
    id: 28,
    title: 'ნევროლოგია',
    content: [
      'თავის ტვინის სისხლძარღვოვანი დაავადებები',
      'თავის ტკივილი',
      'ნევროზი',
      'ეპილეფსია',
      'სხვადასხვა სახის დემენცია და კოგნიტური დარღვევები',
      'ძილის დარღვევები',
      'ხერხემლის სვეტის და ზურგის ტვინის სხვადასხვა დაზიანებები',
      'ცენტრალური და ნერვული სისტემის ონკოლოგიური პათოლოგიები',
    ],
  },
  {
    id: 29,
    title: 'ონკოლოგიის და ჰემატოლოგიის დეპარტამენტი',
    content: [
      'სიმსივნის ტიპი',
      'ლოკალიზაცია',
      'გავრცელება',
      'დაავადების სტადია',
      'ჩატარებული მკურნალობა',
      'პაციენტის ზოგადი მდგომარეობა და მისი ნება-სურვილი.',
      'ჰემოფილია',
      'თრომბოზი',
      'ლეიკემია',
      'ლიმფომა',
      'მიელომა',
      'ანემია',
    ],
  },
  {
    id: 30,
    title: 'ორთოპედია-ტრავმატოლოგიის დეპარტამენტი',
    content: [
      'მხრის სახსრის ენდოპროტეზირება',
      'მენჯ–ბარძაყის სახსრის ენდოპროტეზირება',
      'მუხლის სახსრის ენდოპროტეზირება',
      'ძვალ–სახსროვანი სისტემის თანდაყოლილი დეფორმაციების კორექცია',
      'ტერფმრუდობა',
      'ტერფის ვალგუსური დეფორმაცია',
      'მტევნის, ტერფის ფალანგის დეფორმაცია',
      'შეუხორცებელი და არასწორად შეხორცებული მოტეხილობები',
      'კიდურის ძვლების ოსტეოსინთეზი',
      'ინტრამედულური ოსტეოსინთეზი',
      'კეროვანი ოსტეოსინთეზი',
      'გარეგანი ფიქსატორებით ოსტეოსინთეზი',
      'სამკურნალო და სადიაგნოსტიკო ართროსკოპია',
      'დაუზუსტებელი პათოლოგიური კერის ბიოფსია',
      'კეთილთვისებიანი სიმსივნური წარმონაქმნის  ამოღება',
      'ონკოპროტეზირება (მხარის, მენჯ-ბარძაყის, მუხლის სახსრები)',
    ],
  },
  {
    id: 31,
    title: 'ოფთალმოლოგია',
    content: [
      'ვიზომეტრია, რეფრაქციის ანომალიათა კორექცია რეფრაქტომეტრის მონაცემების საფუძველზე. მიოპიის კორექცია Bausch Lomb-ის, Cooper Vision-ის, Ocular Sciences-ის კონტაქტური ლინზებით.',
      'თვალშიდა წნევის დადგენა კომპიუტერული ტონომეტრით. ბიომიკროსკოპია, ოფთალმოსკოპია, ეგზოფთალმომეტრია, ჯამური ცრემლწარმოქმნის უნარის  განსაზღვრა შირმერის ცდით.',
      'მხედველობის ველის გამოკვლევა კომპიუტერული პერიმეტრით  სხვადასხვა პათოლოგიის  დროს.',
      'ოფთალმოლოგიურ პათოლოგიათა გამოვლენა და მკურნალობა ნევროლოგიურ, ენდოკრინულ, კარდიოლოგიურ  და ონკოლოგიურ დაავადებათა დროს.',
    ],
  },
  {
    id: 32,
    title: 'პათომორფოლოგიის ლაბორატორია',
    content: [
      'ოპერაციული, ბიოფსიური, (მათ შორის ექსპრესბიოფსიური) და ციტოლოგიური მასალის გამოკვლევა, ზოგად მორფოლოგიური, იმუნოჰისტოქიმიური, in situ ჰიბრიდიზაცია.  ',
      'სიმსივნის ჰისტოგენეზის დაზუსტება',
      'პირველადი კერის დადგენა',
      'სიმსივნის ანაპლაზიის ხარისხის განსაზღვრა',
      'იმუნოფენოტიპის განსაზღვრა ლიმფოპროლიფერაციული დაავადების დროს',
      'ესტროგენ - პროგესტერონის რეცეპტორების და ონკოგენ CERBB/Her2/neu განსაზღვრა სარძევე ჯირკვლის კიბოს შემთხვევებში',
      'სიმსივნური ქსოვილის მგრძნობელობის დადგენა ჰორმონული და ქიმიოთერაპიული პრეპარატების მიმართ.',
      'სხვადასხვა ორგანოებისა და სისტემების პათოლოგიური პროცესების სიცოცხლის დროინდელი მორფოლოგიური დიაგნოსტიკა',
      'კლინიკო-ანატომიური ანალიზი',
      'პროცესების პროგნოზული შეფასება',
    ],
  },
  {
    id: 33,
    title: 'პედიატრია',
    content: [
      'ჯანმრთელი ბავშვის ზედამხედველობა მოიცავს ასაკის მიხედვით ვიზიტების კონკრეტულ რაოდენობას. პედიატრთან ვიზიტების განმავლობაში ხდება ბაშვის ჯანმრთელობის და ფიზიკური განვითარების შეფასება, ნუტრიციული სტატუსის შეფასება და ჯანსაღი კვების ხელშეწყობა, ზრდა განვითარებაზე მოქმედი რისკ-ფაქტორების იდენტიფიცირება, განვითარების ჩამორჩენის განსაზღვრა და  კორექცია, დაავადებების გამოვლენა და პრევენცია',
      'მწვავედ ან ქრონიკულად მიმდინარე ჩივილების და დაავადებების არსებობის შემთხვევაში ხდება  შესაბამისი კლინიკურ-ლაბორატორიული და ინსტრუმენტული კვლევებით უზრუნველყოფა და მკურნალობა',
      'პრევენციული ტიპის Check-Up ვიზიტები, გარდა ზოგადი სამედიცინო გასინჯვისა მოიცავს სხვადასხვა ლაბორატორიულ და ინტრუმენტულ კვლევებს, ანემიის სკრინინგს, ფარისებრი ჯირკვლის სკრინინგს, დიაბეტის სკრინინგს, სასიცოცხლო მნიშვნელობის მინერალების და ვიტამინების  განსაზღვრას, სისხლის , შარდის და განავლის  საერთო ანალიზებს,  მუცლის ღრუს და შარდ-სასქესო სისტემის ექოსკოპიას',
      'Check-Up - პროგრამის მიზანია დაავადებების და პათოლოგიური პროცესების პრევენცია, სიმპტომების გაჩენამდე, საწყის ეტაპზე  პრობლემის იდენტიფიცირება და მართვა. ',
    ],
  },
  {
    id: 34,
    title: 'პლასტიკური და რეკოსნტრუქციული ქირურგიის დეპარტამენტი',
    content: [
      'ბლეფაროპლასტიკა - ზედა და ქვედა ქუთუთოების კანის ნაკეცის და ცხიმოვნი ქსოვილების  მოშორება',
      'ცხვირის პლასტიკა - ფორმის შეცვლა, ფორმის გაუმჯობესება, სუნთქვასთან დაკავშირებული პრობლემების მკურნალობა.(ოპერაციის და მკურნალობის მსვლელობაში ჩართულია რინოლოგი, რომელიც უზრუნველყოფს პაციენტის სუნთქვასთან დაკავშირებული ყველა სახის პრობლემის მოგვარებას)',
      'ლიპოსაქცია - მუცელი, ბარძაყები, მუხლები, წვივები, მკერდი, დუნდულები, ღაბაბი, მხრები, ზურგი',
      'მამოპლასტიკა - მკერდის გადიდება, აწევა, დაპატარავება, ფორმის გაუმჯობესება, დვრილის პლასტიკა, ცხიმის გადანერგვა, გინეკომასტიის მკურნალობა მამაკაცებში. ქალბატონების ძალიან  დიდი ნაწილის პრობლემაა მცირე ზომის მკერდი, ლაქტაციის შემდგომი ფტოზი (სარძევე ჯირკვლის დაშვება), ფორმის გაუარესება, ცხიმოვანი განლევა, დვრილ-არეოლის დეფორმაცია ან/და ასიმეტრია. ამ ყველაფრის კორექცია შესაძლებელია ქირურგიული ჩარევების საშუალებით.',
      'აბდომინოპლასტიკა - მუცლის წინა კედლის ფორმის გაუმჯობესება, მოშვებული ან/და სტრიებით დაზიანებული კანის ნაკეცის და ცხიმოვანი ქსოვილის მოშორება',
      'ბარძაყების ლიფტინგი - ბარძაყების შიგნითა და გარეთა მიდამოდან კანის ნაკეცის და ცხიმოვანი ქსოვილის მოშორება',
      'ოტოპლასტიკა - ყურების ფორმის შეცვლა, დაპატარავება, ასიმეტრიის კორექცია, ფორმის გაუმჯობესება, ბიბილოს პლასტიკა',
      'გლუტეოპლასტიკა - დუნდულოს გადიდება (საკუთარი ქსოვილით, ცხიმით ან იმპლანტით) ნაკეცის მოშორება, ფტოზის კორექცია, ფორმის გაუმჯობესება.',
      'წვივების პლასტიკა - გადიდება იმპლანტით, ცხიმის გადანერგვა',
      'მხრების პლასტიკა - ლიპოსაქცია, ჭარბი კანის და ცხიმოვანი ქსოვილის მოშორება',
      'ნიკაპის კორექცია - გადიდება იმპლანტით, ცხიმის გადანერგვა',
      'ცხიმის გადანერგვა - ტუჩების, ყვრიმალების, შუბლის, ცხვირ-ტუჩის ნაოჭის მკერდის, დუნდულოს, ნიკაპის, წვივების მიდამოში',
      'ბიშექტომია - ლოყის ცხიმის ამოღება',
      'ბარიატრიული - (კუჭის ოპერაციის) ოპერაციის შემდგომი მოშვებული კანის, გაუარესებული სხეულის კონტურების კორექცია.( body lifting)',
      'ნაწიბურის ქირურგიული კორექცია',
      'ლიპომების მოშორება - ქირურგიულად ან ლიპოსაქციით (ცხიმოვანი კუზის მოშორება)',
      'ონიხოკრიპტოზის მკურნალობა - ჩაზრდილი ფრჩხილების ფრაგმენტების რეზექცია შემდგომი ფრჩხილის მატრიქსის ნაწილობრივი ლაზერული დესტრუქციით',
      'ქსანტელაზმების მკურნალობა - თვალბუდის ირგვლივ არსებული ცხიმოვანი უბნების ლაზერული და ქირურგიული მოშორება',
      'ბიბილოს პლასტიკა - ჩამოხეული ან დაზიანებული ბიბილოს ქირურგიული კორექცია',
      'პირსინგი სხეულის სხვადასხვა უბანზე',
      'ბოტოქსის ინექცია - ნაოჭის გასწორება, ჭარბი ოფლიანობის და შაკიკის მკურნალობა.',
      'ფილერის (შემავსებლების) ინექცია - ტუჩის გადიდება, ფორმის შეცვლა, ცხვირ-ტუჩის და შუბლის ნაოჭის, ყვრიმალების დეფორმირებული/ასიმეტრიული უბნების შევსება',
      'კელოიდური ან ჰიპერტროფიული  ნაწიბურის ინექციური მკურნალობა',
      '',
      '',
      '',
    ],
  },
  {
    id: 35,
    title: 'რადიაციული ონკოლოგიის დეპარტამენტი',
    content: [
    ],
  },
  {
    id: 36,
    title: 'რევმატოლოგიის დეპარტამენტი',
    content: [
      'შემაერთებელი ქსოვილის სისტემური დაავადებები (სისტემური წითელი მგლურა, დერმატომიოზიტი, სისტემური სკლეროდერმია) და სისტემური ვასკულიტები',
      'ანთებითი რევმატული დაავადებები, რევმატოიდული ართრიტი, სერონეგატიური სპონდილოართროპათიები, ბეხტერევის დაავადება, ფსორიაზული ართრიტი, რეაქტიული ართრიტები, ართრიტები ნაწლავთა ქრონიკული ანთებითი დაავადებების დროს, რევმატიზმი, კვანძოვანი ერითემა',
      'მიკროკრისტალური ართროპათიები (პოდაგრული ართრიტი, ქონდროკალცინოზი)',
      'სახსრების დეგენერაციული დაავადებები (სხვადასხვა ლოკალიზაციის ოსტეოართრიტი (ოსტეოართროზი)',
      'სახსარგარე რბილი ქსოვილების დაავადებები',
      'სარკოიდოზი',
      'ოსტეოპოროზი',
    ],
  },
  {
    id: 37,
    title: 'რენტგენოლოგიის დეპარტამენტი',
    content: [
      'გულმკერდის ღრუს ორგანოების დაავადებათა რენტგენოლოგიური დიაგნოსტიკა.',
      'საჭმლის მომნელებელი ტრაქტის ორგანოთა კვლევა: საყლაპავი მილის, კუჭის, წვრილი და მსხვილი ნაწლავების დინამიური კვლევა.',
      'ირიგოსკოპია ორმაგი კონტრასტირებით.',
      'შარდ-სასქესო სისტემის დაავადებათა რენტგენოლოგიური დიაგნოსტიკა – მიმოხილვითი და ინტრავენური უროგრაფია, მიქციური, ანტეგრადული და რეტროგრადული ცისტოგრაფია, ურეთეროგრაფია, ჰისტეროსალპინგოგრაფია.',
      'საყრდენ- მამოძრავებელი სისტემის პოლიპოზიციური, სტაციონარული და პოსტოპერაციული რენტგენოგრაფია.',
      'სარძევე ჯირკვლის დაავადებათა რენტგენოლოგიური დიაგნოსტიკა (მამოგრაფია), დუქტოგრაფია.',
      'ბილიარული სისტემის რენტგენოლოგიური გამოკვლევა: ანტეგრადული და რეტროგრადული ქოლანგიოპანკრეატოგრაფია, ინტრა და პოსტოპერაციული ანტეგრადული ქოლანგიოგრაფია.',
      'ფისტულოგრაფია სხვადასხვა ლოკალიზაციის დროს.',
      'DEXA- დენსიტომეტრია (ორმაგენერგეტიკული რენტგენოლოგიური აბსორბციომეტრია) - ოქროს სტანდარტი ოსტეოპოროზის დიაგნოსტიკაში. აღჭურვილია შემდეგი პროგრამებით: TBS-ტრაბეკულური ძვლის ინდექსი, FRAX-ალგორითმი, პროტეზირებული ბარძაყისა და მუხლის სახსრის მინერალიზაციის განსაზღვრა, ბარძაყის ძვლის გაფართოებული კვლევა, ხერხემლის მორფომეტრია, სრული სხულის კომპოზიცია.',
    ],
  },
  {
    id: 38,
    title: 'საოპერაციო და ქირურგიული სტაციონარი',
    content: [
      'თორაკო-აბდომინური ჯგუფი',
      'ქირურგიული ორთოპედია-ტრავმატოლოგიის განყოფილება',
      'ქირურგიული უროლოგიის განყოფილება',
      'ქირურგიული გინეკოლოგიის განყოფილება',
      'კანისა და რბილი ქსოვილების განყოფილება',
      'თავ-კისრის ქირურგიის განყოფილება',
      'ქირურგიული პროქტოლოგია',
      'ნეიროქირურგიის განყოფილება',
      'ქირურგიული მამოლოგიის განყოფილება',
      'ანგიოლოგიისა და სისხლძარღვთა ქირურგიის განყოფილება',
    ],
  },
  {
    id: 39,
    title: 'ტრანსფუზიოლოგიის დეპარტამენტი',
    content: [
     
    ],
  },
  {
    id: 40,
    title: 'ულტრაბგერითი დიაგნოსტიკის დეპარტამენტი',
    content: [
      'ეპატოპანკრეატობილიარული სისტემის',
      'თირკმელების, თირკმელზედა ჯირკვლების, შარდის ბუშტის, წინამდებარე ჯირკვლის',
      'სარძევე, ფარისებრი ჯირკვლებისა და პერიფერიული ლიმფური კვანძების',
      'მცირე მენჯის ორგანოების',
      'საყრდენ- მამოძრავებელი სისტემის ულტრაბგერითი კვლევა',
      'ტრანსთირაკალური ექოკარდიოგრაფია',
      'ექსტრა და ინტრაკრანიული სისხლძარღვების ',
      'მუცლის ღრუს',
      'ზედა და ქვედა კიდურების სისხლძარღვების დუპლექს- სკანირება',
      'ნაყოფის განვითარების ანომალიების ადრეული დიაგნოსტიკა',
      'ფარისებრი, სარძევე ჯირკვლის, მუცლის ღრუს ორგანოების, რბილქსოვილოვანი მოცულობითი წარმონაქმნების, ლიმფური კვანძების   ელასტოგრაფიული შეფასება',
      'ღვიძლის დიფუზური (ფიბროზული) ცვლილებების რაოდენობრივი კვლევა (SWE) shear wave ელასტოგრაფიის მეთოდით',
      'სადიაგნოსტიკო პუნქციური ბიოფსია ულტრაბგერითი კონტროლით სხვადასხვა პათოლოგიების დროს',
      'მადრენირებელი-სამკურნალო მანიპულაციების  წარმოება',
    ],
  },
  {
    id: 41,
    title: 'უროლოგია',
    content: [
      'შარდკენჭოვანი დაავადება (თირკმლის ჭვალი,მწვავე ტკივილი წელის მიდამოში)',
      'საშარდე გზების ინფექციები (მწვავე და ქრონიკული თირკმლის ანთება,მწვავე და ქრონიკული შარდის ბუშტის ანთება)',
      'სქესობრივი გზით გადამდები დაავადებები (ქლამიდიოზი,ტრიქომონიაზი,კანდიდოზი)',
      'მოშარდვის პრობლემები და შარდის ბუშტის შეხუთვა (პროსტატის კეთილთვისებიანი ფიპერპლაზია)',
      'შარდში სისხლიანი მინარევი (შარდის ბუშტის სიმსივნე)',
      'მოშარდვის გაძნელება და ტკივილი წელის მიდამოში (პროსტატის სიმსივნე)',
      'ტკივილი სათესლე ჯირკვლებში (სათესლე ჯირკვლის სიმსივნე,ანთება,კისტა,წყალმანკი)',
      'საშარდე სისტემის ორგანოების ანთებითი პროცესები',
      'შარდის ბუშტის ანთება-ცისტიტი',
      'წინამდება ჯირკვლის ანთება-პროსტატიტი',
      'საშარდე გზების ინფექციები',
      'სქესობრივი გზით გადამდები დაავადებები (ქლამიდია, ტრიქომონიაზი, კანდიდოზი)',
      'პროსტატის ადენომა ',
      'შარდკენჭოვანი დაავადება',
      'შარდის ბუშტის სიმსივნე',
      'შარდის შეუკავებლობა',
      'პროსტატის ანთებითი დაავადება - პროსტატი',
      'ურეტრიტები, ურეთრალური სინდრომი',
      'პროსტატის კეთილთვისებიანი ჰიპერპლაზია',
      'პროსტატის ავთვისებიანი სიმსივნე',
      'სისხლიანი შარდის - ჰემატურიის მიზეზის ზუსტი ვერიფიკაცია',
      'სათესლე ჯირკვლების და სათესლე პარკის ანთებითი დაავადებები',
      'ჰიდროცელე, ვარიკოცელე, სპერმატოცელე',
      'მამაკაცის უნაყოფობის დიაგნოსტიკა',
      'სასქესო ასოს ინფექციური და არაინფექციური პათოლოგიები (ფიმოზი, მოკლელაგამი, გრძელი ჩუჩა, პეირონის დაავადება და ა.შ.)',
      'მამაკაცური (სქესობრივი) ფუნქციის გაუარესება',
    ],
  },
  {
    id: 42,
    title: 'ფიზიოთერაპია',
    content: [
      'ელექტროთერაპია - დაბალი ძაბვისა და დენის ძალის მუდმივი უწყვეტი ელ.დენის გამოყენებით, კერძოდ: გალვანიზაცია და წამლისმიერი ელექტროფორეზი.',
      'ამპლიპულს თერაპია',
      'დიადინამოთერაპია',
      'ინტერფერენცული თერაპია',
      'ელექტროსტიმულაცია',
      'დარსონვალიზაცია',
      'ულტრამაღალი სიხშირის ელ.ველით ზემოქმედება მაგნიტური ველის გამოყენებით',
      'სინათლის გამოსხივებით: ინფრაწითელი, წითელი, ულტრაიისფერი და ლაზერული (კოჰერენტული და მონოქრომული)',
      'მექანიკური ენერგიის გამოყენებით: ვიბრაცია და ულტრაბგერითი სიხშირის რხევები',
      'სითბური ენერგია',
      'აეროზოლ თერაპია და ა.შ.',
    ],
  },
  {
    id: 43,
    title: 'ფსიქიატრია',
    content: [
      'შფოთვითი და შიშთან დაკავშირებული დარღვევები: გენერალიზირებული შფოთვითი აშლილობა, პანიკური შეტევა ან აშლილობა, ნევროზი სოციალური ან/და სპეციფიკური ფობიები, ობსესიურ-კომპულსიური აშლილობა, ტრავმითა და სტრესით გამოწვეული დარღვევები, მაგ: მწვავე სტრესული აშლილობა, პოსტტრავმული სტრესტული აშლილობა',
      'დეპრესია: გუნებ-განწყობის მკვეთრი გაუარესება, უიმედობისა და უმწეობის განცდა, ნეგატიური აზრები და სხვ',
      'ძილის დარღვევები: უძილობა, ჭარბი ძილიანობა და სხვ',
      'სომატოფორმული აშლილობები: იპოქონდრია, სხეულის დიმორფული აშლილობა, კონვერსიული აშლილობა და სხვ',
      'კვებითი დარღვევები',
      'სექსუალური დარღვევები და დისფუნქცია',
      'იმპულსთა კონტროლის დარღვევები',
      'ანტისოციალური და სხვა და სხვა პიროვნული დარღვევები',
      'ნეიროკოგნიტური დარღვევები (მახსოვრების და ორიენტაციის მოშლა, დემენცია)',
      'გუნებ-განწყობის დარღვევები: ბიპოლარული აშლილობა',
      'ფსიქოზური რეგისტრის დარღვევები: შიზოფრენია, შიზოაფექტური დარღვევა, ბოდვითი აშლილობა, ხანმოკლე ფსიქოზური ეპიზოდი.',
    ],
  },
  {
    id: 44,
    title: 'ქირურგიული გინეკოლოგიის დეპარტამენტი',
    content: [
      'უშვილობის ქირურგიული დიაგნოსტიკა და მკურნალობა',
      'სადიაგნოსტიკო ლაპაროსკოპია',
      'ფალოპის მილების გამავლობის შემოწმება და აღდგენა-ფიმბრიოპლასტიკა',
      'მცირე მენჯის შეხორცებების გათიშვა',
      'ენდომეტრიოზის ქირურგიული მკურნალობა',
      'საკვერცხის კისტების და სხვა კეთილთვისებიანი წარმონაქმნების ლაპაროსკოპიული ქირურგია',
      'საშვილოსნოს ღრუს პოლიპები',
      'ენდომეტრიუმის ჰიპერპლაზია-საშვილოსნოს სუბმუკოზური კვანძი',
      'საშვილოსნოს ღრუს ჰისტეროსკოპიული პლასტიკა, საშვილოსნოს განვითარების მანკების დროს (საშვილოსნოს ღრუს არასრული და სრული ტიხარი.',
      'ონკო-გინეკოლოგიური ქირურგია',
      'საკვერცხის კიბო',
      'საშვილოსნოს ყელის ავთვისებიანი სიმსივნე',
      'საშვილოსნოს ტანის კიბო',
      'ვულვის კიბო ',
      'ტოტალური ჰისტერექტომია',
      'ლაპაროსკოპიულად',
      'მუცელკვეთით',
      'ვაგინალური მიდგომით',
      'საშვილოსნოს მიომა',
      'ადენომიოზი',
      'ოპერაციები გენიტალური პროლაფსის დროს',
    ],
  },
  {
    id: 45,
    title: 'ქირურგიული მამოლოგიის დეპარტამენტი',
    content: [
      'ინტრაოპერაციული მორფოლოგიური და ციტოლოგიური ექსპრეს დიაგნოსტიკა;',
      '„სენტინელის’ სასიგნალო ლიმფური კვანძების ბიოფსია;',
      'თირაკოსკოპული მეთოდის გამოყენებით, პარასტერნალური მიდამოს ლიმფური კვანძების ბიოფსია;',
      'ძუძუს კიბოთი გამოწვეული რადიკალურად ნამკურნალები პაციენტების რეაბილიტაცია;',
    ],
  },
  {
    id: 46,
    title: 'ქირურგიული პროქტოლოგიის დეპარტამენტი',
    content: [
      'ჰემოროიდული დაავადება',
      'ანალური ნაპრალი',
      'პარაპროქტიტი',
      'პერიანალური მიდამოს, ანალური არხისა და სწორი ნაწლავის სიმსივნური წარმონაქმნები',
      'მახვილწვეტიანი კონდილომები',
      'ანალური არხის და სწორი ნაწლავის პროლაფსი',
      'ანალური სფინქტერის უკმარისობა',
      'რექტო-ვაგინური ფისტულა',
      'რექტოცელე',
      'პილონიდური კისტა(გავა-კუდუსუნის ეპითელიალური ხვრელმილი)',
      'ანალური ქავილი',
      'მსხვილი ნაწლავის ანთებითი დაავადებები და ფუნქციური აშლილობები',
      'ანოსკოპია',
      'სფინქტერომეტრია',
      'მენჯის ფსკერის კუნთოვანი აპარატის ფუნქციონირების სრულფასოვანი შესწავლა და პაციენტებისთვის შესაბამისი რჩევების მიცემა',
      'შიდა ჰემოროიდული კვანძების ლიგირება',
      'ინფრაწითელი ფოტოკოაგულაცია',
      'ულტრამაღალი სიხშირის რადიოტალღებზე მომუშავე ელექტროაპარატით მინიინვაზიური ჩარევები: ჰემოროიდული დაავადების, ანალური ნაპრალის, კონდილომების, პერიანალური ფოჩების, თრომბოზების მკურნალობის დროს',
      'ლაზერული ჰემოროიდოპლასტიკა',
      'სამკვანდრანტული ჰემოროიდექტომია თანამედროვე აპარატურით, ნაკერების ფორმირების გარეშე',
      'ცირკულური ჰემოროიდექტომია',
      'სხვადასხვა სირთულის ოპერაციები ფისტულებზე, ფისტულის არხის ლაზერული ობლიტერაცია',
      'ეპითელიალური ხვრელმილების ამოკვეთა',
      'გაურთულებელი ეპითელიალური ხვრელმილის ლაზერული ობლიტერაცია',
      'ოპერაციები სფინქტერების უკმარისობაზე და სწორი ნაწლავის პროლაფსზე',
      'სხვადასხვა სირთულის ოპერაციები სწორი და მსხვილი ნაწლავის სიმსივნურ წარმონაქმნებზე',
    ],
  },
  {
    id: 47,
    title: 'ქირურგიული უროლოგიის დეპარტამენტი',
    content: [
      'გახშირებული შარდვა',
      'სისხლიანი მინარევი შარდში',
      'შარდის შეკავება',
      'ზოგადი მდგომარეობის გაუარესება და სხვა',
      'ცისტოსკოპია ბიოფსიით - უახლესი დრეკადი ვიდეოცისტოსკოპით ამბულატორიულად',
      'პროსტატის ბიოფსია ულტრაბგერითი ტრანსრექტალური დამიზნებით',
      'სხვადასხვა ლოკალიზაციის სიმსივნეების ბიოფსია',
      'შარდსაწვეთის კათეტერიზაცია, სტენტირება',
      'ეპიცისტოსტომია',
      'ქირურგიულ განყოფილებაში ტარდება:',
      'თანამედროვე ონკოლოგიური ოპერაციები',
      'უროლოგიური ოპერაციები',
      'ლაპაროსკოპიული ოპერაციები',
      'ღია ქირურგიული ოპერაციები',
      'თირკმლის სიმსივნე',
      'ღია და ლაპაროსკოპიული ნეფრექტომია',
      'ორგანოს შემანარჩუნებელი - თირკმლის რეზექცია',
      'თირკმელზედა ჯირკვლის ამოკვეთა',
      'შარდის ბუშტის სიმსივნე',
      'ტრანსურეთერული რეზექცია',
      'რადიკალური და მოდიფიცირებული',
      'ცისტექტომია ლიმფადენექტომიით ',
      'ენტეროცისტოპლასტიკა',
      'სათესლე ჯირკვლის და გარეთა სასქესო ორგანოების სიმსივნეები',
      'სათესლე ჯირკვლის ამოკვეთა',
      'რეპროპერიტონული (პარააორტული) ლიმფადენექტომია',
      'გარეთა სასქესო ორგანოების ამპუტაცია',
      'საზარდულის ლიმფადენექტომია',
      'მენჯის ლიმფადენექტომია',
      'პროსტატის სიმსივნე',
      'ლაპაროსკოპიული პროსტატექტომია',
      'პროსტატის კეთილთვისებიანი სიმსივნეები (ადენომა) - ტრანსუთერული რეზექცია ბიპოლარული რეზექტოსკოპით.',
      'მენჯის ორგანოების სიმსივნეების რადიკალური ქირურგიული მკურნალობა',
      'ადგილობრივად გავრცელებული სიმსივნეებისას',
      'კომბინირებული ოპერაციები',
      'აღდგენით-რეკონსტრუქციული',
      'რეპროპერიტონული, მუცლის ღრუს და მცირე მენჯის სიმსივნეების რადიკალური ქირურგიული მკურნალობა.',
    ],
  },
  {
    id: 48,
    title: 'ყბა სახის ქირურგია',
    content: [
      'კბილის ამოჭრის პათოლოგია (გაძნელებული ამოჭრა, დისტოპია, რეტენცია)',
      'ყბების ოდონტოგენური და არაოდონტოგენური კისტები',
      'პირის ღრუს კეთილთვისებიანი სიმსივნეები და სიმსივნისმაგვარი   წარმონაქმნები',
      'სანერწყვე ჯირკვლების დაავადებები',
      'საფეთქელ-ქვედა ყბის სახსრის დაავადებები',
      'ყბა-სახის მიდამოს ჩირქოვან-ანთებითი პროცესები',
      'სახის და კისრის თანდაყოლილი კისტები და ხვრელარხები',
      'ოდონტოგენური ჰაიმორიტი',
      'სახის და სამწვერა ნერვის დაავადებები',
      'სახის ძვლების და რბილი ქსოვილების კეთილთვისებიანი სიმსივნეები',
      'სახის ძვლების მოტეხილობების',
      'სახის ძვლების თანდაყოლილი და ტრავმვის შემდგომი დეფორმაციების და დეფექტების ქირურგიული მკურნალობა',
    ],
  },
  {
    id: 49,
    title: 'შინაგანი მედიცინა',
    content: [
      'შესწავლა, დიაგნოსტიკა, მკურნალობა და პრევენცია: როგორც უკვე აღვნიშნეთ, თერაპია მოიცავს მრავალ შინაგან დაავადებას და ფოკუსირებულია ყოვლისმომცველ გამოკვლევაზე, ზუსტ დიაგნოზზე, ეფექტურ მკურნალობასა და პრევენციულ ღონისძიებებზე. ამ დაავადებებიდან ბევრმა შეიძლება ძალიან მნიშვნელოვანი უარყოფითი მოახდინონ ადამიანის ჯანმრთელობაზე, ამიტომ ექიმი-თერაპევტის დროული ჩართულობა გადამწყვეტია. ',
      'პაციენტის ისტორიის შესწავლა: ექიმი-თერაპევტი პაციენტთან ურთიერთობას სამედიცინო ისტორიის საფუძვლიანი მიმოხილვით (ანამნეზი) იწყებს და შემდეგ დეტალურ ფიზიკურ გამოკვლევას ატარებს. ეს პროცესი საშუალებას აძლევს მას შეაგროვოს აუცილებელი ინფორმაცია პაციენტის ჯანმრთელობის შესახებ.',
      'დიაგნოსტიკური ინსტრუმენტები: თერაპევტები იყენებენ დიაგნოსტიკური ხელსაწყოების მთელ რიგს ზუსტი დიაგნოზის დასადგენად. ეს ხელსაწყოები მოიცავს რენტგენოგრაფიას (რენტგენი), ულტრაბგერას, კომპიუტერულ და მაგნიტურ-რეზონანსულ ტომოგრაფიას (MRI), ენდოსკოპიას და ლაბორატორიულ ანალიზს. ყველა ზემოჩამოთვლილი საშუალება საჭიროა რომ პაციენტის სიმპტომების გამომწვევი მიზეზები დროულად გამოვლინდეს.',
      'ურთიერთდაკავშირებული დისციპლინები: თერაპია მჭიდროდ არის დაკავშირებული რამდენიმე სპეციალიზებულ სამედიცინო სფეროსთან, მათ შორის პულმონოლოგია (რესპირატორული დაავადებები), კარდიოლოგია (გულის დაავადებები), გასტროენტეროლოგია (საჭმლის მომნელებელი სისტემის დარღვევები), ნევროლოგია (ნერვული სისტემის დარღვევები), ჰემატოლოგია (სისხლის დარღვევები) და რევმატოლოგია ( კუნთოვანი და აუტოიმუნური დაავადებები). ექიმი-თერაპევტი ხშირად მოქმედებს ამ სფეროების სპეციალისტებთან ერთად, რათა  მკურნალობის პროცესმა მაქსიმალურად ეფექტურად ჩაიაროს.',
      'საერთო სიმპტომები: შინაგანი დაავადებების მქონე პაციენტებს ხშირად აღენიშნებათ სხვადასხვა სიმპტომები, მათ შორის ცხელება (სხეულის ტემპერატურის მომატება), ხველა, გულის პალპიტაცია, კანის ფერის ცვლილება, ზოგადი სისუსტე და სხვადასხვა სახის ტკივილი, მათ შორის სახსრებისა და ძვლების ტკივილი. თერაპევტს აქვს შესაბამისი ცოდნა და გამოცდილება რომ ეს სიმპტომები დროულად შენიშნოს და მათი გამომწვევი მიზეზები დაადგინოს.',
      'მკურნალობის მიდგომები: ექიმი-თერაპევტები, როგორც წესი, იყენებენ მკურნალობის კონსერვატიულ მეთოდებს, რომლებიც ხშირად ფარმაცევტული მედიკამენტების დანიშვნას გულისხმობს. ისინი შეიმუშავებენ პერსონალიზებულ მკურნალობის გეგმებს, რათა მ ხელი შეუწყონ პაციენტის სწრაფ გამოჯანმრთელებას.',
      'მიმართვები: დიაგნოზის დადგენის შემდეგ, თერაპევტმა შეიძლება გადაამისამართოს პაციენტი შესაბამის სპეციალისტთან შემდგომი შეფასებისა და საჭიროების შემთხვევაში მკურნალობისთვის. ',
    ],
  },
  {
    id: 50,
    title: 'ჰეპატოლოგია',
    content: [
    ],
  },
];


const Services = () => {
  const [expandedSections, setExpandedSections] = useState([]);

  const toggleSection = (id) => {
    setExpandedSections((prev) =>
      prev.includes(id) ? prev.filter((sectionId) => sectionId !== id) : [...prev, id]
    );
  };

  return (
    <Layout>
      <div className='services-page'>

      <div className='services-main-title'>
      <p> განყოფილებები</p>
      </div>
      <div className='services-list-container'>
      {sectionsData.map((section) => (
        <div key={section.id}>
          <div className='services-text-box'>
          <img
  src={expandedSections.includes(section.id) ? downsewrvices : playservices}
  alt={expandedSections.includes(section.id) ? 'Collapse' : 'Expand'}
  onClick={() => section.content.length > 0 && toggleSection(section.id)} // Проверка перед вызовом toggleSection
  style={{
    cursor: section.content.length > 0 ? 'pointer' : 'default', // Изменение стиля курсора
  }}
/>

          <p className='services-txt'>
            {section.title}
          </p>
          </div>

          {expandedSections.includes(section.id) && (
            <ul className='services-text-ul'>
              {section.content.map((item, index) => (
                <li  key={index}>{item}</li>
              ))}
            </ul>
          )}
        </div>
      ))}
      </div>
     
      </div>
    </Layout>
  )
}

export default Services