import React from 'react'
import Layout from '../../components/layout/layout'
import blogarrow from '../../images/blog/blogarrow.svg'
import './nevroz.scss'
import nevrozblog from '../../images/blog/nevrozblog.svg'

const Nevroz = () => {
  return (
    <Layout>
      <div className='nevroz'>



        <div className='blog-txt-head'>
          <h2 className='blog-main-txt-head'>ბლოგი</h2>
          <img src={blogarrow} alt='blogarrow' className='blogarrow' />
          <p className='blog-main-txt-head-sub'>ნევროზი – გამომწვევი მიზეზები, სიმპტომები და მკურნალობა.</p>
        </div>

        <div className='blog-content'>

          <div className='blog-post-start'>
            <img src={nevrozblog} alt='nevrozblog' />
            <div className='blog-post-start-right'>

              <p className='blog-post-start-head'>ნევროზი – გამომწვევი მიზეზები, სიმპტომები და მკურნალობა.</p>
              <p>ნევროზი ნერვული სისტემის ფუნქციური დაავადებაა, რომელიც ვითარდება მწვავე ფსიქოლოგიური ტრამვის ან ხანგრძლივი ნერვული დაძაბულობის ფონზე. </p>
            </div>

          </div>

          <p>ნევროზი ფსიქიკური და ემოციური დარღვევაა, რომელიც გამოიხატება შფოთვებით, დეპრესიული განწყობით, ადვილად გაღიზიანებით, აკვიატებული აზრებითა და სხვა მრავალფეროვანი ჩივილებით. ხშირად პაციენტი საკუთარ თავში იკეტება, ზღუდავს სოციუმთან ინტერაქციასა და კომუნიკაციას. შესაბამისად, ნევროზის დროს მნიშვნელოვნად არის დაქვეითებული ადამიანთა ცხოვრების ხარისხი.</p>
          <p>ნევროზი კლინიკურად ვლინდება მრავალფეროვანი სიმპტომებით და შესაბამისად არსებობს მისი სხვადასხვა კლასიფიკაცია,  ძირითადად  გამოყოფენ შემდეგ  ტიპებს:
            შფოთვით - ფობიური  დარღვევები
            სადაც წამყვანი ჩივილია შიში, არამოტივირებული შფოთვა, ან  მეტად ფოკუსირებული, თუმცა  ირაციონალური ფობია. ხშირად ვლინდება:</p>
          <ul>
            <li>განგაშის  მდგომარეობა</li>
            <li>დაძაბულობა,  გაღიზიანება, ნევროტულობა </li>
            <li>მოდუნების  გაძნელება</li>
            <li>კუნთური  დაჭიმულობა და ტკივილი</li>
            <li>ჰაერის უკმარისობა, თავბრუსხვევა, გულისცემის გახშირება, მერყევი არტერიული  წნევა</li>
            <li>ვლინდება  ფსიქოვეგეტატიური  პანიკური  შეტევები </li>
          </ul>

          <h3>იპოქონდრიული ნევროზი</h3>
          <p>რაიმე დაავადების არსებობის უსაფუძვლო შიში
            აკვიატებულ მდგომარეობათა ნევროზი ხასიათდება:</p>
          <ul>
            <li>განმეორებადი, არაკონტროლირებადი ფიქრებითა და შეგრძნებებით</li>
            <li>ხშირი, აკვიატებული რიტუალური ქცევებით, რომლებიც აკვიატებული-ობსესიური ფიქრებით გამოწვეული დაძაბულობის განტვირთვის ერთგვარი საშუალებაა.  გვხვდება სისუფთავის, საგნების დათვლის, ხელების დაბანის   აკვიატებული ქცევები</li>
          </ul>

          <h3>ასთენიურ  ნევროზს (ასთენია ნიშნავს გამოფიტვას) ახასიათებს:</h3>
          <ul>
            <li>აუხსნელი მუდმივი  დაღლილობა</li>
            <li>შრომისუნარიანობის, კონცენტრაციის, ათვისების უნარების დაქვეითება</li>
            <li>გუნება- განწყობის  ხშირი  ცვალებადობა</li>
            <li>ცრემლიანობა</li>
            <li>ძილის დარღვევა</li>
          </ul>
          <p>ასეთი ადამიანი თავს არიდებს სხვადასხვა აქტივობას და ამით ცდილობსშეამციროს შინაგანი კონფლიქტი.</p>
          <h3>ნევროზული დეპრესია ხასიათდება:</h3>
          <ul>
            <li>დაზარალებულობის, მიტოვებულობის შეგრძნებით   </li>
            <li>სიხარულის განცდის დაკარგვით     </li>
            <li>ძილის დარღვევით (ჭარბი ძილიანობა ან უძილობა)  </li>
          </ul>

          <h3>სომატოფორმული ნევროზები </h3>
          <p>სომატოფორმული ნევროზები  ხასიათდება იმ შინაგანი ორგანოების ფუნქციური დარღვევით, რომლებიც რეგულირდება ავტონომიური ვეგეტატიური სისტემით (გულსისხლძარღვთა, შარდსასქესო, საჭმლის მომნელებელი,  ბრონქიალური, ოფლის გამომყოფი სისტემები)
            ისტერიული ნევროზის დროს ვლინდება პიროვნების ხასიათობრივი  თავისებურებები:</p>

          <ul>
            <li>ყურადღების ცენტრში ყოფნის სურვილი</li>
            <li>სხვადასხვა ნევროლოგიური და  ორგანული  დაავადებების  იმიტაცია: სისუსტე  კიდურებში, კოორდინაციის, მეტყველების, მხედველობის დარღვევები</li>
            <li>ცნობიერების ინტეგრაციული ფუნქციის ცვლილებები - ფსიქოლოგიური ამნეზიები, პიროვნების გაორება და სხვა</li>
          </ul>

          <h3>ნევროზის დიაგნოზი და მკურნალობა</h3>
          <p>ნევროზი ხასიათდება განმეორებითი, გახანგრძლივებული, ქრონიკული  მიმდინარეობით.  იგი მნიშვნელოვნად აქვეითებს  პიროვნების  ცხოვრების  ხარისხს,  ადაპტაციას გარემოში, რეალიზებას ცხოვრებაში. სწორი დიაგნოსტირებისა და ადექვატური მკურნალობის პირობებში 80%-ზე მეტ შემთხვევაში მდგომარეობა მართვადი და განკურნებადია.
            მკურნალობა კომპლექსურია ფსიქოლოგიური დახმარება და მედიკამენტოზური თერაპია, რომლის შერჩევა ხდება სპეციალისტის მიერ დიაგნოზის შესაბამისად</p>
          <p>ყველა ჩამოთვლილი სიმპტომი სხვა მრავალი დაავადების დროსაც შეიძლება განვითარდეს, ამიტომ, ნევროზის დიაგნოზი აუცილებლად ექიმის მიერ უნდა იყოს დასმული. ნევროზის მკურნალობა მოითხოვს პაციენტისა და ექიმის მჭიდრო თანამშრომლობას. თუ პაციენტმა კარგად არ გააანალიზა თავისი მდგომარეობა და არ გაერკვა ავადმყოფობის არსში, პოზიტიური ეფექტის მიღწევა რთულია.</p>
        </div>
      </div>

    </Layout>
  )
}

export default Nevroz