import React from 'react'
import Layout from '../../components/layout/layout'
import blogarrow from '../../images/blog/blogarrow.svg'
import './invitro.scss'
import invitroblog from '../../images/blog/invitroblog.svg'
import invitroimg from '../../images/blogimages/invitroimg.svg'
import { Helmet } from 'react-helmet';
import { FacebookShareButton, FacebookIcon } from 'react-share';
import invitroimge from '../../images/facebook-share/invitroimg.jpg'
import { FacebookShare } from 'react-share-kit';
const Invitro = () => {

  const shareUrl = 'https://www.medical-tourism.ge/blog/invitro';
  const title = 'ინ ვიტრო განაყოფიერება IVF - სრული ინფორმაცია და ღირებულება თურქეთში.';
  const updatedTime = 1732181767; 

  const handleFacebookShare = () => {
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}&quote=${encodeURIComponent(title)}`;
    window.open(facebookShareUrl, '_blank', 'noopener,noreferrer');
  };
  return (
    <Layout>
      <Helmet>
        <title>ინ ვიტრო განაყოფიერება IVF</title>
        <meta property="og:site_name" content="https://www.medical-tourism.ge"></meta>
        <meta name="description" content="გაიგეთ მეტი ინ ვიტრო განაყოფიერების (IVF) შესახებ - სრული ინფორმაცია და ღირებულება თურქეთში." />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="ინ ვიტრო განაყოფიერება IVF" />
        <meta property="og:description" content="გაიგეთ მეტი ინ ვიტრო განაყოფიერების (IVF) შესახებ - სრული ინფორმაცია და ღირებულება თურქეთში." />
        <meta name="image" content="https://www.medical-tourism.ge/static/media/invitroimg.f218297f14b86df7bba8.jpg"></meta>
        <meta property="og:image" content="https://og-image-demo.haydn.co/static/palm-tree-de6c252c62de5c6c71337a6bb23bdb1c.jpg" />
        <meta name="og:image:type" content="image/jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="600" />
        <meta property="og:url" content="https://www.medical-tourism.ge/blog/invitro" />
        <meta property="og:locale" content="ka_GE" />
        <meta property="og:updated_time" content={updatedTime} />
      </Helmet>
      <div className='invitro'>
        <div className='blog-txt-head'>
          <h2 className='blog-main-txt-head'>ბლოგი</h2>
          <img src={blogarrow} alt='blogarrow' />
          <p className='blog-main-txt-head-sub'>ინ ვიტრო განაყოფიერება IVF.</p>
        </div>

        <div className='blog-content'>
          <div className='blog-post-start'>
            <img src={invitroblog} alt='invitroblog' className='invitroblog' />
            <div className='blog-post-start-right'>
              <p className='blog-post-start-head'>ინვიტრო განაყოფიერების (IVF) შესახებ</p>
              <p>ინ ვიტრო განაყოფიერება (ე.წ. ხელოვნური განაყოფიერება) უშვილობის მკურნალობის დამხმარე რეპროდუქციული ტექნოლოგიაა (Assisted Reproductive Technologies – ART), რომელიც ქალისა და მამაკაცის სასქესო უჯრედების ორგანიზმის გარეთ შერწყმას გულისხმობს. შედეგად, მიღებული ემბრიონის ქალის საშვილოსნოში გადატანა ხდება.
                ეს დარგი მუდმივად ვითარდება და  დღესდღეობით, ამ მეთოდით უკვე 5 მილიონზე მეტი ბავშვია დაბადებული მსოფლიო მასშტაბით. </p>
            </div>
          </div>

          <p>in-vitro განაყოფიერების განხორციელებამდე ტარდება წყვილის სრული კლინიკო-ლაბორატორიული გამოკვლევა. გამოკვლევა გულისხმობს ჰორმონალურ, იმუნოლოგიურ, ბაქტერიოლოგიურ და ულტრასონოგრაფიულ კვლევებს.
            ინ ვიტრო განაყოფიერება (IVF) შემდეგი ეტაპებისგან შედგება: საკვერცხეების სტიმულაცია; კვერცხუჯრედის ასპირაცია (ამოღება); განაყოფიერება და კულტივაცია. ბოლოს კი ყველაფერი ემბრიონის გადატანით სრულდება, დარჩენილი ემბრიონები კი იყინება, შემდეგ პაციენტმა საჭირო მკურნალობა უნდა გააგრძელოს და ემბრიონის გადატანიდან 10-12 დღის შემდეგ, მას ჩაუტარდეს ორსულობის ტესტი.</p>
          <h3>როდის მივმართოთ ინ ვიტრო განაყოფიერებას?</h3>
          <p>წყვილს, რომელსაც 12 თვის  განმავლობაში რეგულარული დაუცველი სქესობრივი ცხოვრების მიუხედავად, შვილი არ უჩნდება, უნაყოფობის დიაგნოზს უსვამენ. უშვილობის პრობლემა ოდითგანვე არსებობდა, თანამედროვე მედიცინა კი ამ პრობლემას სხვადასხვა მეთოდებით უმკლავდება.დამხმარე რეპროდუქციულ ტექნოლოგიებს შორის ყველაზე
            ეფექტურად მიიჩნევა ინ ვიტრო განაყოფიერება,რომელიც დღეს ფართოდ გამოიყენება ქალისა და მამაკაცის სხვადასხვა გენეზის უნაყოფობის დროს.</p>

          <img src={invitroimg} alt='invitroimg' className='invitroimg' />

          <h4>IVF მკურნალობის ღირებულება თურქეთში</h4>
          <p>IVF მკურნალობის ფასები მნიშვნელოვნად იკლებს ევროკავშირსა და აშშ -ში ჩატარებულ ოპერაციებთან შედარებით. IVF მკურნალობის ღირებულება თურქეთში  განსხვავდება ქვეყნის სხვადასხვა ქალაქში. ვიზისა და მგზავრობის ხარჯების დამატების გარეშე, რომელიც ასევე ჩვეულებრივ ძალიან დაბალია სხვა ქვეყნებთან შედარებით, თურქეთში IVF მკურნალობა საშუალოდ 2,500 დოლარს შეადგენს.</p>
          <p>ასევე არსებობს რამდენიმე ფაქტორი, რომელიც უნდა გავითვალისწინოთ თურქეთის დამხმარე რეპროდუქციის ტექნოლოგიების შესახებ კანონებთან დაკავშირებით. უპირველეს ყოვლისა, IVF მკურნალობის კანდიდატი წყვილი უნდა იყოს დაქორწინებული და არავითარ შემთხვევაში არ მიიღება ოპერაციისთვის წყვილი, რომელიც არ არის დაქორწინებული. ასევე, ერთსქესიან წყვილებს, ისევე როგორც მარტოხელა ქალებს არ შეუძლიათ გაიარონ IVF მკურნალობა ქვეყნის კანონების გამო, ვინაიდან სპერმისა და ემბრიონის დონაცია ასევე მკაცრად აკრძალულია.</p>

          {/* <FacebookShare
            url={shareUrl}
            text={title}
            hashtag="#IVF"
            render={props => (
              <button
                onClick={props.onClick}
                style={{
                  fontSize: '16px',
                  padding: '10px 20px',
                  backgroundColor: '#4267B2',
                  color: '#fff',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}
              >
                Share on Facebook
              </button>
            )}
          /> */}
        </div>
      </div>
    </Layout>
  )
}

export default Invitro