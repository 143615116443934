import React, { useEffect } from 'react';

const CrispChat = () => {
  useEffect(() => {
    // Initialize Crisp
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "3b9f1063-a7ff-4c9a-bfc4-ef90960c3bc4"; 

    // Load Crisp script
    (function() {
      const d = document;
      const s = d.createElement("script");
      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();

    window.$crisp.push(["set", "language", "ka"]);
  }, []);

  return null;
};

export default CrispChat;
