import React from 'react'
import Layout from '../../components/layout/layout'
import blogarrow from '../../images/blog/blogarrow.svg'
import './oncologic.scss'
import oncologicblog from '../../images/blog/oncologicblog.svg'
import oncologimg from '../../images/blogimages/oncologimg.svg'
import oncologimg2 from '../../images/blogimages/oncologimg2.svg'
import oncologimg3 from '../../images/blogimages/oncologimg3.svg'




const Oncologic = () => {
  return (
    <Layout>
      <div className='oncologic'>



        <div className='blog-txt-head'>
          <h2 className='blog-main-txt-head'>ბლოგი</h2>
          <img src={blogarrow} alt='blogarrow' className='blogarrow' />
          <p className='blog-main-txt-head-sub'>ონკოლოგიური დაავადებები.</p>
        </div>

        <div className='blog-content'>

          <div className='blog-post-start'>
            <img src={oncologicblog} alt='oncologicblog' />
            <div className='blog-post-start-right'>

              <p className='blog-post-start-head'>ონკოლოგიური დაავადებები.</p>
              <p>საზოგადოების დიდი ნაწილი ონკოლოგიური დაავადებების დიაგნოზს ჯერ კიდევ განაჩენად მიიჩნევს. სამწუხაროდ, სიმსივნური პათოლოგიები მართლაც დიდ საფრთხეს უქმნის ჩვენს ჯანმრთელობასა და სიცოცხლეს, თუმცა თანამედროვე მედიცინის მიღწევების წყალობით, კიბოსგან განკურნების შემთხვევები მნიშვნელოვნად იზრდება. აღსანიშნავია, რომ ონკოლოგია არის მედიცინის დარგი, რომელიც განსაკუთრებულად სწრაფი ტემპით ვითარდება მთელს მსოფლიოში და მათ შორის, რასაკვირველია, საქართველოშიც. </p>
            </div>

          </div>

          <p>კიბო სიკვდილის წამყვანი მიზეზი და სიცოცხლის მოსალოდნელი ხანგრძლივობის ზრდის მნიშვნელოვანი ბარიერია მსოფლიოს მეტწილ ქვეყანებში. 
            ჯანდაცვის მსოფლიო ორგანიზაციის მიერ 2020 წელს ჩატარებული შეფასებების თანახმად, კიბო არის გარდაცვალების პირველი ან მეორე ძირითადი მიზეზი 70 წლის ასაკამდე 183 ქვეყნიდან 112 ქვეყანაში და მესამე ან მეოთხე ადგილზეა შემდგომი 23 ქვეყნიდან. კიბოს მომატება, როგორც სიკვდილის ძირითადი მიზეზი, ნაწილობრივ ასახავს ინსულტისა და გულის იშემიური დაავადებების სიკვდილიანობის შემცირებას კიბოსთან შედარებით, ბევრ ქვეყანაში.</p>
          <h3>საქართველოში ონკოლოგიურ დაავადებათა გავრცელების თავისებურებები, 2020</h3>
          <p>კიბოს პოპულაციური რეგისტრის მონაცემებით 2020 წელს საქართველოში ყველა ლოკალიზაციის კიბოს 9435 ახალი შემთხვევაა რეგისტრირებული, მაჩვენებელი 100000 მოსახლეზე - 253.5.</p>
          <div className='oncolog-blog-img'>
            <p>ყველა ლოკალიზაციის კიბოს (in situ სტადიის ჩათვლით) ახალი შემთხვევები, საქართველო, 2008-2020</p>
            <p>კიბოს ახალი შემთხვევების სტადიები დიაგნოზის დასმისას, საქართველო, 2016-2020</p>
          </div>
          <img src={oncologimg} alt='oncologimg' className='oncologimg' />
          <div className='oncolog-blog-img'>
            <p>ყველა ლოკალიზაციის კიბოს განაწილება ასაკობრივი ჯგუფის მიხედვით, ორივე სქესი, საქართველო, 2020</p>
            <p>ყველა ლოკალიზაციის კიბოს ასაკ-სტანდარტიზებული ავადობა 100 000 მოსახლეზე რეგიონების მიხედვით, საქართველო, 2020</p>
          </div>
          <img src={oncologimg2} alt='oncologimg2' className='oncologimg' />
          <p>ათი ძირითადი ლოკალიზაციის კიბოს წილი კიბოს ავადობაში ქალებსა და მამაკაცებში, 2020, საქართველო</p>
          <img src={oncologimg3} alt='oncologimg3' className='oncologimg' />
          <h3>ონკოლოგია - მკურნალობის მეთოდები</h3>
          <p>ონკოლოგიური დაავადებები სრულფასოვან დიაგნოსტირებას, კომპლექსურ მკურნალობასა და მუდმივ მონიტორინგს მოითხოვს. ამიტომ, ონკოლოგიური პაციენტისთვის, დროისა და ძალისხმევის დაზოგვის მიზნით, განსაკუთრებით მნიშვნელოვანია სრული სამედიცინო მომსახურება ერთ სივრცეში მიიღოს, დაწყებული რუტინული ლაბორატორული ანალიზებით და სხვადასხვა დარგის სპეციალისტთა კონსულტაციებით და დამთავრებული ისეთი მაღალტექნოლოგიური სერვისებით, როგორიცაა: გენეტიკური კვლევები, პოზიტრონ-ემისიური ტომოგრაფია (პეტ/კტ), 
            ენდოციტოსკოპია, ინტერვენციული რადიოლოგია, ტრანსბრონქული ბიოფსია, ლაპაროსკოპული ონკოქირურგია, იმუნოთერაპია და რადიოქირურგია.  </p>
            <p>დიაგნოზიდან გამომდინარე, პაციენტებს უტარდებათ ქიმიოთერაპია, ჰორმონოთერაპია, ტარგეტული თერაპია, იმუნოთერაპია, ან მათი კომბინაცია. მნიშვნელოვანია, რომ მკურნალობის, ასევე მასთან დაკავშირებული გვერდითი მოვლენების მართვა ხდება როგორც ამბულატორულ (დღის სტაციონარის), ასევე, საჭიროებისამებრ, სტაციონარის პირობებში,
უახლესი საერთაშორისო პროტოკოლები, რეჟიმები და მედიკამენტები, რომლებიც ახლდება და იხვეწება ყოველწლიურად, საშუალებას გვაძლევს მნიშვნელოვნად შევამციროთ მკურნალობის გვერდითი ეფექტები, გავზარდოთ გამოჯანმრთელების მაჩვენებლები და სიცოცხლის ხანგრძლივობა ჩვენს პაციენტებში.</p>
        </div>

      </div>

    </Layout>
  )
}

export default Oncologic