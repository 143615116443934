import React from 'react'
import Layout from '../../components/layout/layout'
import blogarrow from '../../images/blog/blogarrow.svg'
import './bacterial.scss'
import bacterialblog from '../../images/blog/bacterialblog.svg'

import fat from '../../images/blogimages/fat.svg'



const Bacterial = () => {
  return (
    <Layout>
      <div className='bacterial'>
        <div className='blog-txt-head'>
          <h2 className='blog-main-txt-head'>ბლოგი</h2>
          <img src={blogarrow} alt='blogarrow' className='blogarrow' />
          <p className='blog-main-txt-head-sub'>ბარიატრიული ქირურგია (SLEEVE GASTRECTOMY)</p>
        </div>

        <div className='blog-content'>

          <div className='blog-post-start'>
            <img src={bacterialblog} alt='bacterialblog' />
            <div className='blog-post-start-right'>

              <p className='blog-post-start-head'>ბარიატრიული ქირურგია (SLEEVE GASTRECTOMY)</p>
              <p>სიმსუქნის პრობლემის გადაჭრა ქირურგიული გზით.</p>
              <p>სლივ–გასტრექტომია კუჭის ვერტიკალური რეზექციაა (ამოკვეთა). ოპერაციის დროს მცირდება კუჭის მოცულობა და ქირურგები პაციენტს ასევე აცილებენ ჰორმონ გრელინის (ჰორმონი, რომელიც შიმშილის გრძნობას იწვევს) გამომამუშავებელ უჯრედებს.</p>
            </div>

          </div>

          <p>Laparoscopic Sleeve Gastrectomy (LSG) - უსაფრთხო და ნაკლებად რთული, მინი ინვაზიური და შეუქცევადი ოპერაციაა, რომლის დროსაც:</p>
          <ul className='bacterial-list'>
            <li>მილის ფორმის კუჭი ფორმირდება;</li>
            <li>აღარ გამომუშავდება შიმშილის ჰორმონი - გრელინი;</li>
            <li>მცირდება კუჭის მოცულობის 80-85%, ასევე ერთ კვებაზე მისაღები საჭმლის რაოდენობა;</li>
            <li>ჭარბი წონის 60-70%-ს იკლებს პაციენტი 12-18 თვის განმავლობაში;</li>
          </ul>
          <p>სლივ–გასტრექტომიის დახმარებით, პაციენტი ემშვიდობება ზედმეტ წონას და ამავდროულად სიმსუქნის თანმხლები დაავადებების რეგრესი (დაქვეითება) ხდება.</p>
          <h4>რა შემთხვევაში კეთდება სლივგასტრექტომია?</h4>
          <p>სლივ-გასტრექტომია მხოლოდ წონის კორექციისთვის არ არის მოწოდებული, არამედ სიმსუქნის თანმხლები დაავადებების რეგრესისთვისაც.
            მოგეხსენებათ, ჭარბი წონის გამო მნიშვნელოვნად იზრდება სხვადასხვა დაავადების განვითარების რისკი, რაც საფრთხეს უქმნის პაციენტის ჯანმრთელობას და აქვეითებს სიცოცხლის ხარისხს.
            იმ შემთხვევაში, როდესაც სამედიცინო ჩვენება არსებობს, ოპერაცია შესაძლებელია ჩაუტარდეს ნებისმიერი ასაკის პაციენტს, როგორც ხანდაზმულს, ასევე მოზარდს. ბაქტერიული მკურნალობა თურქეთის  წამყვან კლინიკებში საკმაოდ ეფექტურია, რასაც გამოკვლევები გვიჩვენებს. </p>
          <p>ოპერაცია კეთდება, მაშინ, როცა სხეულის მასის ინდექსი (BMI) არის მაღალი.</p>
          <h4>რამდენ ხანში იკლებს ადამიანი წონაში და როგორია პოსტოპერაციული პერიოდი?</h4>
          <p>შედეგი ყველასთან ინდივიდუალურია, თუმცა სტატისტიკურად ჭარბ წონას პაციენტი ოპერაციიდან 12-16 თვეში იკლებს. გარდა ამისა, 
          ოპერაციის შემდეგ აუცილებელია ცხოვრების სტილის შეცვლა, რადგან კუჭის ელასტიურობის გამო მისი მოცულობა ისევ იზრდება და 3-5 წელიწადში პაციენტები დაკარგული წონის 15%-ს უკან იბრუნებენ. ოპერაციის შემდეგ მათ ეძლევათ რეკომენდაციები, ფიზიკური აქტივობისა და კვების შესახებ. მაგალითად, იკრძალება საღეჭი რეზინის ღეჭვა, შავი პურისა და ზოგიერთი ხილის მირთმევა, ჭამის დროს სითხის მიღება და ა.შ. ისინი, ვინც ამ წესებს იცავენ, მუდმივად ინარჩუნებენ მიღწეულ შედეგს.</p>
        <img src={fat} alt='fat' className='fat-img' />
        <p className='bacterial-last-txt'>იმის გამო, რომ ეს ოპერაციები ლაპარასკოპიულად ტარდება, ორგანიზმი ნაკლებ ტრავმას იღებს. შესაბამისად, ოპერაციის შემდეგ პაციენტს ნაკლებად აწუხებს ტკივილი და ასევე ნაკლებად უჩივის სისუსტეს. უკვე მეორე დღეს დგება ფეხზე და დამოუკიდებლად გადაადგილდება, მესამე დღიდან ვიწყებთ თხიერ კვებას, 4-5 დღეში კი ეწერება სახლში. სამი კვირის შემდეგ პაციენტი იწყებს შედარებით მყარი საკვების მიღებას.</p>

        </div>

      </div>

    </Layout>
  )
}

export default Bacterial