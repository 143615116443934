import React from 'react'
import Layout from '../../components/layout/layout'
import blogarrow from '../../images/blog/blogarrow.svg'
import './stomach.scss'
import stomachblog from '../../images/blog/stomachblog.svg'

const Stomach = () => {
  return (
    <Layout>
      <div className='stomach'>
        <div className='blog-txt-head'>
          <h2 className='blog-main-txt-head'>ბლოგი</h2>
          <img src={blogarrow} alt='blogarrow' className='blogarrow' />
          <p className='blog-main-txt-head-sub'>კუჭ-ნაწლავის ყველაზე გავრცელებული პრობლემები.</p>
        </div>

        <div className='blog-content'>

          <div className='blog-post-start'>
            <img src={stomachblog} alt='stomachblog' />
            <div className='blog-post-start-right'>

              <p className='blog-post-start-head'>კუჭ-ნაწლავის ყველაზე გავრცელებული პრობლემები.</p>
              <p>კუჭ-ნაწლავი უნიკალური სისტემაა. მის ჯანმრთელობაზე უშუალოდ არის დამოკიდებული მთელი ორგანიზმის მდგომარეობა. მისი მუშაობის დარღვევა პირდაპირ აისახება კანზე. საკვებმა, რომელიც ვნებს კუჭს, შეიძლება დისბაქტერიოზი და ალერგიაც გამოიწვიოს.
                ასე რომ, თუ თქვენი კანის მდგომარეობით უკმაყოფილო ხართ, მიმართეთ გასტროენტეროლოგს და შეამოწმეთ, ყველაფერი რიგზე გაქვთ თუ არა. გარემოს დაბინძურება, არასწორი კვება, უმოძრაო ცხოვრების წესი კუჭ-ნაწლავის ფუნქციის დარღვევას, მისი მოქმედების შეფერხებას იწვევს.</p>
            </div>

          </div>

          <h3 className='stomach-head'>გასტროეზოფაგური რეფლუქსური დაავადება (GERD)</h3>
          <p>გულძმარვა კუჭის ბუნებრივი რეაქციაა, რომელიც გამაღიზიანებელ საკვებთან მიმართებაში გვაწუხებს, მაგრამ თუ ამ სიმპტომს რეგულარული სახე აქვს, შესაძლოა, საქმე გასტროეზოფაგურ რეფლუქსურ დაავადებასთან გქვონდეს. სამედიცინო პროვაიდერს ხშირად შეუძლია GERD-ის დიაგნოსტიკა მხოლოდ სიმპტომების აღწერის საფუძველზე, მაგრამ თუ პრობლემა გარკვეული პერიოდის განმავლობაში მიმდინარეობდა, შესაძლოა დამატებითი დიაგნოსტიკური ტესტირება გახდეს საჭირო, რათა შეფასდეს, გამოიწვია თუ არა დაავადებამ საყლაპავ მიდამოში დაზიანება.</p>

          <p>GERD ყველაზე ხშირად გულძმარვით ვლინდება, მაგრამ უკონტროლო GERD-ს შეუძლია საყლაპავის ლორწოვანი გარსის ეროზიისა და სისხლდენის გამოწვევა. მდგომარეობამ ასევე შეიძლება გამოიწვიოს გულმკერდის არეში ისეთი ძლიერი ტკივილი, რომ ზოგჯერ შეცდომით გულის შეტევად აღვიქვათ.</p>
          <p>GERD-ის კონტროლი ჩვეულებრივ იწყება ცხოვრების წესის მარტივი ცვლილებებით, მათ შორის, ძილის წინ მინიმუმ ორი საათით ადრე საკვებისგან თავის არიდებით. ურეცეპტოდ გაცემულმა საშუალებებმა ასევე შეიძლება ხანმოკლე პერიოდში შეგიმსუბუქოთ მდგომარეობა, მაგრამ ქრონიკული ან სერიოზული GERD-ისთვის შესაძლოა, საჭირო გახდეს მედიკამენტების მიღება ან თუნდაც ოპერაცია.</p>

          <h3 className='stomach-head'>ქრონიკული დიარეა</h3>
          <p>გულძმარვის მსგავსად ეს სიმპტომიც ნორმალურია, როცა მას იშვიათი ხასიათი აქვს, თუმცა, თუკი ეს ხშირად ხდება, მაშინ აუცილებლად უნდა მიექცეს ყურადღება.</p>
          <p>დიარეის მკურნალობა რთულია, რადგან მას შეიძლება ჰქონდეს მრავალი შესაძლო მიზეზი. ეს შეიძლება იყოს ორგანიზმის მიერ საკვების შეწოვის უუნარობის შედეგი, დარღვევა, როგორიცაა გაღიზიანებული ნაწლავის სინდრომი (IBS), კრონის დაავადება ან წყლულოვანი კოლიტი. სულაც მისი გამომწვევი ინფექციები შეიძლება იყოს.</p>
          <p>გარდა იმისა, რომ ქრონიკული დიარეა ართულებს ყოველდღიური ცხოვრების რიტმის ჩვეულად გაგრძელებას, ის შეიძლება იყოს უფრო სერიოზული პრობლემის მანიშნებელი, რომელსაც სამედიცინო ჩარევა და პროფესიონალის მიერ შეფასება დასჭირდება.</p>
          <h3 className='stomach-head'>ქრონიკული ყაბზობა</h3>
          <p>ქრონიკული ყაბზობა, როგორც წესი, კვირაში კუჭ-ნაწლავის სამზე ნაკლებჯერ მოქმედებაა. დიარეის მსგავსად, ქრონიკული ყაბზობის მიზეზი შეიძლება იყოს რთული დიაგნოსტიკა. მდგომარეობის მკურნალობა შეიძლება დაიწყოს ურეცეპტოდ გაცემული საშუალებებით, როგორიცაა განავლის დამარბილებელი საშუალებები და ბოჭკოვანი დანამატები.</p>
          <p>რეალურად, რაციონში მეტი სითხის დამატებამ, შესაძლოა, უშველოს მას. თუმცა,  თუკი ეს გზა ყაბზობას ვერ შველის, ექიმმა უნდა დანიშნოს ამ სიმპტომისთვის განკუთვნილი სპეციალური ვარჯიშები.</p>
          <h3 className='stomach-head'>გასტროენტერიტი</h3>
          <p>ისეთი სიმპტომებით, როგორიცაა ცხელება, ღებინება, დიარეა და თავის ტკივილი, გასაკვირი არ არის, რომ უმეტესობა ამას კუჭის გრიპს უწოდებს.</p>
          <p>გასტროენტერიტი გამოწვეულია ნაწლავის ინფექციით - ვირუსული ან ბაქტერიული. ბაქტერიული ინფექციები შეიძლება გამოიწვიოს E. coli-მ ან სალმონელამ, ხოლო ვირუსული ინფექციები შეიძლება მოიცავდეს როტავირუსს ან  ნოროვირუსს. ასევე, მისი გამომწვევი მიზეზი ხშირად პარაზიტებია.</p>
          <p>თუ სიმპტომები რამდენიმე დღე გრძელდება, საუკეთესო რჩევაა, დალიოთ ბევრი სითხე, რათა თავიდან აიცილოთ დეჰიდრატაცია. გამოიყენეთ ხელების კარგი ჰიგიენა ინფექციის გავრცელების თავიდან ასაცილებლად და, თუ ეს შესაძლებელია, სთხოვეთ სხვებს, არ გამოიყენონ იგივე ნივთები, სანამ სიმპტომები არ გადაივლის.  სიმპტომები, რომლებიც რამდენიმე დღეზე მეტხანს გრძელდება, საჭიროებს დამატებით დაკვირვებას, მაგალითად, ანტისხეულების ტესტირებას, რომელიც შეიძლება მიუთითებდეს განავალში ალერგიაზე ან ინფექციის ნიშნებზე, რაც დაეხმარება ექიმს, დანიშნოს ყველაზე ეფექტური მკურნალობა.</p>
          <h3 className='stomach-head'>წყლულები</h3>

          <p>პეპტიკურ წყლულს ადრე ყველაზე ხშირად ცხოვრების სტილის ფაქტორებს უკრავშირებდნენ, მაგალითად: სტრესს ან დიეტას, მაგრამ ბოლო წლების კვლევებმა აჩვენა, რომ წყლულები, სავარაუდოდ, გამოწვეულია კუჭის ბაქტერიებით ან არასტეროიდული ანთების საწინააღმდეგო საშუალებების გამოყენებით, როგორიცაა იბუპროფენი ან ნაპროქსენი.</p>
          <p>ბაქტერიას ან არასტეროიდულ ანთების საწინააღმდეგო საშუალებებს შეუძლიათ დააზიანონ ლორწო, რომელიც კუჭის შიდა ნაწილს მჟავასგან იცავს. შედეგად  ღიზიანდება ქსოვილი და ჩნდება პეპტიკური წყლული.</p>
          <p>ექიმს შეუძლია ჩაატაროს ანალიზები იმის დასადგენად, არის თუ არა წყლული ინფექციით გამოწვეული (ჩვეულებრივ H. pylori) და დანიშნოს ანტიბიოტიკები სამკურნალოდ. ასევე ხშირად ინიშნება პროტონული ტუმბოს ინჰიბიტორი კუჭში მჟავის შესამცირებლად.</p>
          <p>კუჭ-ნაწლავთან დაკავშირებული პრობლემები უამრავია, თუმცა, მათი დიაგნოსტირებითა და ექიმთან დროულად კონსულტაციით, შესაძლებელია, ამ პრობლემების გამწვავების თავიდან არიდება. აქვე შეგახსენებთ, რომ EKIMO-ს აპლიკაციით შეგიძლიათ, მარტივად დაჯავნოთ ვიზიტი სფეროს პროფესიონალთან და დისტანციურად გამოიწეროთ დანიშნული მედიკამენტები.  </p>
        </div>
      </div>

    </Layout>
  )
}

export default Stomach