import React from 'react'
import Layout from '../../components/layout/layout'
import blogarrow from '../../images/blog/blogarrow.svg'
import './doccheck.scss'
import blogdocchekblog from '../../images/blog/blogdocchekblog.svg'

const Doccheck = () => {
  return (
    <Layout>
      <div className='bacterial'>



        <div className='blog-txt-head'>
          <h2 className='blog-main-txt-head'>ბლოგი</h2>
          <img src={blogarrow} alt='blogarrow' className='blogarrow' />
          <p className='blog-main-txt-head-sub'>რატომაა ჯანმრთელობის პერიოდული გადამოწმება ადამიანისთვის აუცილებელი?</p>
        </div>

        <div className='blog-content'>

          <div className='blog-post-start'>
            <img src={blogdocchekblog} alt='blogdocchekblog' />
            <div className='blog-post-start-right'>

              <p className='blog-post-start-head'>რატომაა ჯანმრთელობის პერიოდული გადამოწმება ადამიანისთვის აუცილებელი?</p>
              <p>პერიოდული შემოწმებები საშუალებას იძლევა  ჯანმრთელობის პრობლემები ჯერ კიდევ მათ გამოვლენამდე ან იმდენად ადრეულ ეტაპზე აღმოაჩინოთ, რომ მკურნალობას წარმატებით დაექვემდებაროს.
ზრუნვა საკუთარ ჯანმრთელობაზე იწყება დროული პროფილაქტიკით. ნუ გადადებთ – დაგეგმეთ გამოკვლევა დღესვე!
 </p>
            </div>

          </div>

          <p>პროფილაქტიკური გამოკვლევები რეკომენდებულია როგორც ჯანმრთელი ადამიანებისთვის, ისე მათთვის, ვისაც აქვს ქრონიკული დაავადებები ან მაღალი რისკის ჯგუფში შედის. რეგულარული გამოკვლევებით თქვენ იზრუნებთ თქვენს ჯანმრთელობაზე და შეამცირებთ გართულებების რისკს.</p>
          <h3>როგორ დავგეგმოთ პროფილაქტიკური გამოკვლევები:</h3>

          <ul>
            <li>კონსულტაცია ექიმთან – გაიარეთ კონსულტაცია თქვენი ოჯახის ექიმთან ან სპეციალისტთან, რათა ინდივიდუალური გეგმა შეიმუშავოთ.</li>
            <li>გრაფიკის შედგენა – დაადგინეთ, რა სიხშირით გჭირდებათ კონკრეტული გამოკვლევები თქვენი ასაკისა და ჯანმრთელობის მდგომარეობის გათვალისწინებით.</li>
            <li>მუდმივი მონიტორინგი – თუ უკვე გაქვთ რაიმე ქრონიკული დაავადება, გამოკვლევები შეიძლება უფრო ხშირად დაგჭირდეთ.</li>
          </ul>

          <h3>რა კვლევებს მოიცავს ძირითადად</h3>
          <ul>
            <li>ლაბორატორიული კვლევები: ასაკობრივი ჯგუფის მიხედვით, სისხლის სრული ბიოქიმიურ კვლევა, ღვიძლისა და თირკმლების ფუნქციები, ლიპიდურ სპექტრი, ჰორმონები, ონკომარკერები, ვირუსული ჰეპატიტები და სხვა სქესობრივი გზით გადამდები დაავადებების კვლევა.</li>
            <li>ინსტრუმენტული კვლევები: ყველა ორგანოთა სისტემის ექოსკოპია, სისხლძარღვების დოპლეროგრაფიული კვლევა; სრული კარდიოლოგიური კვლევები.</li>
            <li>ექიმ-სპეციალისტთა კონსულტაციები: კარდიოლოგის, ნევროლოგის, ოფთალმოლოგის, უროლოგისა და გინეკოლოგის კონსულტაციები.</li>
            <li>ონკო-სკრინინგი: საჭიროების მიხედვით, პროფილაქტიკური კვლევების პაკეტს ემატება მთლიანი სხეულის მაგნიტურ-რეზონანსული ტომოგრაფია, უახლესი, 3T სიმძლავრის ტომოგრაფით.</li>
          </ul>
        </div>

      </div>

    </Layout>
  )
}

export default Doccheck