import React from 'react'
import Layout from '../../components/layout/layout'
import blogarrow from '../../images/blog/blogarrow.svg'
import './biopsia.scss'
import biopsiablog from '../../images/blog/biopsiablog.svg'

const Biopsia = () => {
  return (
    <Layout>
      <div className='bacterial'>



        <div className='blog-txt-head'>
          <h2 className='blog-main-txt-head'>ბლოგი</h2>
          <img src={blogarrow} alt='blogarrow' className='blogarrow' />
          <p className='blog-main-txt-head-sub'>ბიოფსია.</p>
        </div>

        <div className='blog-content'>

          <div className='blog-post-start'>
            <img src={biopsiablog} alt='biopsiablog' />
            <div className='blog-post-start-right'>

              <p className='blog-post-start-head'>რა არის ბიოფსია?</p>
              <p>ბიოფსია ეს არის სამედიცინო  პროცედურა, რომელიც ინიშნება ონკოლოგიური დაავადებების დროულად გამოსავლენად და მოიცავს დაზიანებული ქსოვილიდან მასალის  ამოღებასა და მის ანალიზს.   ბიოფსიის შედეგები გამორიცხავს ან ადასტურებს  ჯანმრთელობისათვის საზიანო პროცესების არსებობას ადამიანის ორგანიზმში.</p>
            </div>

          </div>
          <h3>როდის არის საჭირო ბიოფსია?</h3>

          <p>როდესაც რადიოლოგიური და ლაბორატორიული კვლევებით ვერ ხერხდება დიაგნოზის დაზუსტება, ტარდება ბიოფსია.
კვლევის საუკეთესო მეთოდია, რადგან ციტოლოგს/მორფოლოგს საშუალებას აძლევს უჯრედები პირდაპირ, მიკროსკოპული გზით შეისწავლოს და პასუხს ხშირ შემთხვევაში გადამწყვეტი მნიშვნელობა აქვს მკურნალობის ტაქტიკის განსაზღვრისთვის. 
ბიოფსიით კლინიკური დიაგნოზი და პათოლოგიური პროცესის გავრცელების საზღვრები ზუსტდება.
</p>
          <h3>მტკივნეული, თუ არა პროცედურა?</h3>
          <p>უმეტეს შემთხვევაში უმტკივნეულოა.  თუმცა ეს დამოკიდებულია სხეულის რომელი ორგანოდან ხდება ნიმუშის აღება. პროცედურის დროს პაციენტი შეიძლება განიცდიდეს სუსტ, მონოტონურ ტკივილებს, რომელთა შემსუბუქებაც შესაძლებელია ტკივილგამაყუჩებლებით, რათქმაუნდა  ექიმის რეკომენდაციით.</p>
          <h3>ბიოფსიის სახეები</h3>
        <p>ბიოფსიის სახეები ერთმანეთისგან განსხვავდება იმის მიხედვით, თუ რომელი ორგანოს კვლევა ტარდება. სწორედ სამიზნე ორგანოს მიხედვით განსხვავდება ერთმანეთსგან პუნქციის ნემსის ზომებიც . შესაძლოა ჩატარდეს,  
          როგორც ულტრასონოგრაფიის, ისე კომპიუტერული ტომოგრაფიის კონტროლით, იმის გათვალისწინებით თუ რომელი ორგანოს გამოკვლევა მიმდინარეობს:</p>
          <ul>
            <li>ასპირაციული წვრილნემსიანი</li>
            <li>მსხვილნემსიანი</li>
            <li>ღია, ანუ ოპერაციული</li>
            <li>ლაპაროსკოპიული</li>
            <li>ენდოსკოპიური</li>
          </ul>
        </div>
        

      </div>

    </Layout>
  )
}

export default Biopsia