import React from 'react'
import Layout from '../../components/layout/layout'
import './home.scss'
import homenatia from '../../images/homenatia.svg'
import Homenatiabg from '../../images/homenatiabg.svg'
import Mhastanesi from '../../images/logos/7mhastanesi.svg'
import acibadem from '../../images/logos/acibadem.svg'
import imperial from '../../images/logos/imperial.svg'
import liww from '../../images/logos/liww.svg'
import MedicalPark from '../../images/logos/logo-medicalPark.svg'
import medicana from '../../images/logos/medicana.svg'
import memorial from '../../images/logos/memorial.png'
import mkk from '../../images/logos/mkk.svg'
import bariatria from '../../images/news/bariatria.svg'
import chestcorrection from '../../images/news/chestcorrection.svg'
import diabet from '../../images/news/diabet.svg'
import invitro from '../../images/news/invitro.svg'
import oncologic from '../../images/news/oncologic.svg'
import turkeynews from '../../images/news/turkeynews.svg'
import firstslider from '../../images/finalslider/firstslider.svg'
import secondslider from '../../images/finalslider/secondslider.svg'
import thirdslider from '../../images/finalslider/thirdslider.svg'
import fourslider from '../../images/finalslider/fourslider.svg'
import fiveslider from '../../images/finalslider/fiveslider.svg'
import directorrnatia from '../../images/directorr-natia.svg'

import video from '../../images/video/video.svg'
import costvideo from '../../images/video/costvideo.png'
import doctorvideo from '../../images/video/doctorvideo.png'
import phonevideo from '../../images/video/phonevideo.png'
import techvideo from '../../images/video/techvideo.png'

import homeslide1 from '../../images/homeslides/homeslide1.svg'
import homeslide2 from '../../images/homeslides/homeslide2.svg'
import homeslide3 from '../../images/homeslides/homeslide3.svg'
import homeslide4 from '../../images/homeslides/homeslide4.svg'
import homeslide5 from '../../images/homeslides/homeslide5.svg'
import homeslide6 from '../../images/homeslides/homeslide6.svg'
import homeslide7 from '../../images/homeslides/homeslide7.svg'
import homeslide8 from '../../images/homeslides/homeslide8.svg'
import homeslide9 from '../../images/homeslides/homeslide9.svg'
import homeslide10 from '../../images/homeslides/homeslide10.svg'
import homeslide11 from '../../images/homeslides/homeslide11.svg'

import oncologicDirection from '../../images/directions/oncologic.svg'
import fizioteraphy from '../../images/directions/fizioteraphy.svg'
import ginecologic from '../../images/directions/ginecologic.svg'
import nervology from '../../images/directions/nervology.svg'
import oftalmology from '../../images/directions/oftalmology.svg'
import pediatric from '../../images/directions/pediatric.svg'
import plastic from '../../images/directions/plastic.svg'
import urology from '../../images/directions/urology.svg'
import natiamobile from '../../images/natiamobile.svg'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { useNavigate } from 'react-router-dom'
import { Autoplay } from 'swiper/modules';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

import homeblock1 from '../../images/homeblock1.svg'
import homeblock2 from '../../images/homeblock2.svg'
import homesmallimg from '../../images/home-small-img.svg'

const Home = () => {

  const clinics = [
    { src: acibadem, alt: 'acibadem', link: 'https://www.acibadem.com.tr/' },
    { src: Mhastanesi, alt: 'Mhastanesi', link: 'https://www.7mhastanesi.com.tr/' },
    { src: imperial, alt: 'imperial', link: 'https://www.imperialhastanesi.com/' },
    { src: liww, alt: 'liww', link: 'https://www.livhospital.com/en' },
    { src: MedicalPark, alt: '7mhast', link: 'https://medicalparkinternational.com/en-us/Pages/default.aspx' },
    { src: medicana, alt: '7mhast', link: 'https://www.medicanainternational.com/' },
    { src: memorial, alt: 'memorial', link: 'https://www.memorial.com.tr/en' },
    { src: mkk, alt: 'mkk', link: 'https://medipol.com.tr/en/' },
  ];

  const news = [
    { src: invitro, title: 'ინ ვიტრო განაყოფიერება IVF.', link: '/blog/invitro' },
    { src: bariatria, title: 'ბარიატრიული ქირურგია (SLEEVE GASTRECTOMY)', link: '/blog/bacterial' },
    { src: oncologic, title: 'ონკოლოგიური დაავადებები.', link: '/blog/oncologic' },
    { src: diabet, title: 'დიაბეტის ტიპები და გამომწვევი მიზეზები.', link: '/blog/diabet' },
    { src: turkeynews, title: 'თურქეთი მსოფლიოში მეშვიდე ადგილს იკავებს ჯანმრთელობის ტურიზმში.', link: '/blog/turkeymedic' },
    { src: chestcorrection, title: 'მკერდის კორექცია (მამოპლასტიკა) – გაზრდა, დაპატარავება, აწევა…', link: '/blog/chests' },
  ]

  const choose = [
    { src: phonevideo, title: '24/7 მომსახურება', txt: 'ჩვენი გუნდი გაგიწევთ უფასო კონსულტაციას დიაგნოზის გადამოწმებაში, მკურნალობის დაგემვაში, მგზავრობასა და რეაბილიტაციაში.' },
    { src: doctorvideo, title: 'ექიმის არჩევა', txt: 'ექიმის არჩევა მნიშვნელოვანი გადაწყვეტილებაა, რომელიც პირდაპირ აისახება თქვენს ჯანმრთელობასა და კეთილდღეობაზე. ჩვენ გიმარტივებთ ამ პროცესს კვალიფიციურ ექიმებთან თანამშრომლობით. ჩვენი დახმარებით თქვენ შეგიძლიათ მარტივად იპოვოთ ექიმი, რომელიც დააკმაყოფილებს თქვენს სპეციფიკურ საჭიროებებს.' },
    { src: costvideo, title: 'მოწინავე ტექნოლოგიები & მეთოდები', txt: 'ინოვაციური სამედიცინო ტექნოლოგიები & მეთოდები რაც იძლევა ზუსტი დიაგნოსტიკის და მკურნალობის შესაძლებლობას.' },
    { src: techvideo, title: 'მკურნალობის ხარჯების შემცირება', txt: 'თურქეთში ბევრი კლინიკაა მათ შორის არის კარგიც და ცუდიც, ძვირიც და იაფიც, ხარისხიანი და უხარისხო მომსახურეობით. მკურნალობის ღირებულება სხვადასხვა კლინიკაში განსხვავებულია,  ჩვენ გეხმარებით თავი აარიდოთ ზედმეტ ხარჯებს. ' },
  ]

  const directions = [
    { src: oncologicDirection, title: 'ონკოლოგია', },
    { src: ginecologic, title: 'გინეკოლოგია', },
    { src: urology, title: 'უროლოგია', },
    { src: oftalmology, title: 'ოფთალმოლოგია', },
    { src: nervology, title: 'ნევროლოგია', },
    { src: fizioteraphy, title: 'ფიზიოტერაფია', },
    { src: pediatric, title: 'პედიატრია', },
    { src: plastic, title: 'პლასტიკური ქირურგია', },
  ]

  const navigate = useNavigate();

  const handleBlogNavigate = (url) => {
    navigate(url)
  }

  const items = [
    { id: 1, src: homeslide1, alt: 'Image 1' },
    { id: 2, src: homeslide2, alt: 'Image 2' },
    { id: 3, src: homeslide3, alt: 'Image 3' },
    { id: 4, src: homeslide4, alt: 'Image 4' },
    { id: 5, src: homeslide5, alt: 'Image 4' },
    { id: 6, src: homeslide6, alt: 'Image 4' },
    { id: 7, src: homeslide7, alt: 'Image 4' },
    { id: 8, src: homeslide8, alt: 'Image 4' },
    { id: 9, src: homeslide9, alt: 'Image 4' },
    { id: 10, src: homeslide11, alt: 'Image 4' },
    { id: 11, src: homeslide11, alt: 'Image 4' },
  ];

  const settings = {
    dots: false, 
    infinite: true, 
    speed: 5000, 
    slidesToShow: 4, 
    slidesToScroll: 1, 
    autoplay: true, 
    autoplaySpeed: 0, 
    cssEase: 'linear', 

    responsive: [
      {
        breakpoint: 950, 
        settings: {
          slidesToShow: 1, 
          slidesToScroll: 1, 
        },
      },

    ],
  };

  return (
    <Layout>
      <div className='home'>
        <div className='home-head-box padding-left'>
          <div className="video-section">
            <Swiper
              spaceBetween={10} 
              slidesPerView={1} 
              loop={true} 
              autoplay={{ delay: 3000 }}
              modules={[Autoplay]}
            >
              <SwiperSlide>
                <img src={firstslider} alt="video-1" className="video-img" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={secondslider} alt="doctor-video" className="video-img border-img" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={thirdslider} alt="cost-video" className="video-img border-img" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={fourslider} alt="phone-video" className="video-img border-img" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={fiveslider} alt="phone-video" className="video-img border-img" />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>

        <div className="slider-container">
      <Slider {...settings}>
        {items.map((item) => (
          <div className="slider-item" key={item.id}>
            <img src={item.src} alt={item.alt} />
          </div>
        ))}
      </Slider>
    </div>

        <div className='padding-left'>
          <h2 className='choose-us'> რატომ გვირჩევენ ჩვენ?</h2>
          <div className='main-services-box-container'>
            {choose.map((item) => (
              <div className='main-services-box'>
                <img src={item.src} />
                <div className='main-services-box-bottom'>
                  <p className='main-services-box-title'>{item.title} </p>
                  <p className='main-services-box-txt'> {item.txt}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className='home-about-container'>
          <div className='home-about-container-child'>
            <div className='home-about-box-right '>
              <img src={directorrnatia} alt='homenatia' className='homenatia-img' />
              <img src={Homenatiabg} alt='Homenatiabg' className='homenatiabg' />
              <p className='natia-name desktop-natia'>ნათია ახალაძე</p>
              <p className='natia-profession desktop-natia general-director'>გენერალური დირექტორი</p>
            </div>
            <div className='natiamobile-box'>
              <img src={directorrnatia} alt='natiamobile' className='natiamobile' />
              <p className='natia-name'>ნათია ახალაძე</p>
              <p className='natia-profession'>გენერალური დირექტორი</p>
            </div>
            <div className='home-about-box'>
              <p className='home-about-txt home-about-txt-main'>კომპანია [Medical Tourism] იძლევა უნიკალურ შესაძლებლობას ქართველი მოქალაქეებისთვის, მიიღონ უმაღლესი ხარისხის სამედიცინო მომსახურება თურქეთის წამყვან კლინიკებში.</p>
              <p className='home-about-txt'>  ჩვენ გვაქვს მჭიდრო თანამშრომლობა და ურთიერთობა თითოეულ სამედიცინო დაწესებულებასთან, რომლებიც უზრუნველყოფენ ყველაზე მნიშვნელოვან და რთულ სამედიცინო მომსახურებას თანამედროვე ინოვაციური მეთოდებით.
                კომპანია [Medical Tourism] არის ლიდერი სამედიცინო ტურიზმის სფეროში, რომელიც გთავაზობთ პროფესიონალურ და ხარისხიან მომსახურებას საქართველოს მოქალაქეებს, რომლებსაც სურთ მიიღონ წამყვან სამედიცინო კლინიკებში მკურნალობა თურქეთში.
                ჩვენ ვთავაზობთ სრული სამედიცინო ტურიზმის მომსახურების პაკეტს, რომელიც მოიცავს არა მხოლოდ მაღალი ხარისხის მკურნალობას, არამედ ორგანიზაციული საკითხების სრულ პაკეტს – თარჯიმანი, ტრანსპორტი, განთავსება და სხვა.</p>
              <p className='home-about-txt'>ჩვენი მიზანია, რომ თითოეულ პაციენტს მივაწოდოთ საუკეთესო სამედიცინო მომსახურეობა, დავარწმუნოთ მათ კომფორტსა და უსაფრთხოებაში, ხოლო პროცედურები შევასრულოთ უმაღლეს საერთაშორისო სტანდარტებზე. </p>
            </div>
          </div>
        </div>

        <div className='home-big-img-bg'>
          <img src={homeblock1} />
          <div className='home-big-img-bg-txt'>
            <p>შენი </p>
            <p>ჯანმრთელობა </p>
            <p>შენს ხელშია! </p>
          </div>

          <div className='home-big-img-bg-txt-right'>
            <p className='home-big-img-bg-txt-right-bottom-head-text'>მიიღე უმაღლესი სამედიცინო მომსახურეობა თუ გჭირდებათ სწორი დიაგნოზი და მაღალკვალიფიციური ექიმების თანამედროვე ტექნოლოგიებით მომსახურეობა, დაგეგმეთ ჩვენს ერთად ვიზიტი მაღალრეიტინგიან კლინიკებში.</p>
            <div className='home-big-img-bg-txt-right-bottom'>
              <img src={homesmallimg} width={58} height={58}/>
              <div className='home-big-img-bg-txt-right-bottom-img'>
                <p className='home-patients-number'>30,000+</p>
                <p>კმაყოფილი პაციენტი.</p>
              </div>
            </div>
          </div>
        </div>

        <p className='home-news padding-left'>ბლოგი</p>
        <div className='news-container padding-left'>
          {news.map((news, index) => (
            <div>
              <img src={news.src} alt={news.src} onClick={() => handleBlogNavigate(news.link)} />
              <p className='news-container-txt'>{news.title}</p>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default Home