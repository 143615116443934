import React from 'react'
import Layout from '../../components/layout/layout'
import blogarrow from '../../images/blog/blogarrow.svg'
import './diabet.scss'
import diabetblog from '../../images/blog/diabetblog.svg'
import diabeticblog from '../../images/blogimages/diabeticblog.svg'



const Diabet = () => {
  return (
    <Layout>
      <div className='diabetic'>



        <div className='blog-txt-head'>
          <h2 className='blog-main-txt-head'>ბლოგი</h2>
          <img src={blogarrow} alt='blogarrow' className='blogarrow' />
          <p className='blog-main-txt-head-sub'>დიაბეტის ტიპები და გამომწვევი მიზეზები.</p>
        </div>

        <div className='blog-content'>

          <div className='blog-post-start'>
            <img src={diabetblog} alt='diabetblog' />
            <div className='blog-post-start-right'>

              <p className='blog-post-start-head'>დიაბეტის ტიპები და გამომწვევი მიზეზები.</p>
              <p>დიაბეტი ქრონიკული დაავადებაა, რომლის დროსაც სისხლში მომატებულია გლუკოზის რაოდენობა. მონელების პროცესში საკვების უმეტეს ნაწილს ორგანიზმი გლუკოზად გარდაქმნის, რომელსაც სხეულის ყველა უჯრედი იყენებს ენერგიის წყაროდ. უჯრედებში შესაღწევად გლუკოზას სჭირდება ჰორმონი ინსულინი, რომელიც პანკრეასში – კუჭქვეშა ჯირკვალში წარმოიქმნება.</p>
            </div>
          </div>
          <p>დიაბეტი მაშინ ვითარდება, როცა ორგანიზმი ან ვერ გამოიმუშავებს საჭირო რაოდენობის ინსულინს, ან სათანადოდ ვერ იყენებს მას. ვერც პირველ, ვერც მეორე შემთხვევაში გლუკოზა უჯრედებში ვეღარ აღწევს და ჭარბად გროვდება სისხლში, ეს კი ჯანმრთელობის სერიოზულ პრობლემებს იწვევს.</p>

          <h3>რა ტიპის დიაბეტი არსებობს?</h3>
          <p className='diabet-type'><strong>ტიპი 1</strong></p>
          <p className='diabet-type-txt'>დიაბეტი ტიპი 1 ის შემთხვევაა, როდესაც პანკრეასში ინსულინი საერთოდ არ წარმოიქმნება. მას ინსულინდამოკიდებულ ან იუვენილურ დიაბეტსაც უწოდებენ, რადგან, როგორც წესი, ბავშვებსა და მოზარდებთან აღირიცხება, თუმცა შესაძლოა, ზრდასრულ ადამიანსაც დაემართოს.</p>
          <p> <span className='symptom-txt'>სიმპტომები: </span> რომლებიც მიუთითებს ამ ცვლილებაზე მეტაბოლიზმის მხრივ შარდის ხშირი მოცილება, უჩვეულო წყურვილი, უწყვეტი შიმშილი, წონის დაკლება, მხედველობის დაბინდვა,* ხელებისა და ფეხების დაბუჟება, ჩხვლეტა, გაღიზიანება და დაღლილობა. ისინი მოულოდნელად ჩნდებიან.</p>

          <p className='diabet-type'><strong>ტიპი 2</strong></p>
          <p className='diabet-type-txt'>დიაბეტი ტიპი 2 (არაინსულინდამოკიდებული, მოზრდილთა დიაბეტი) დაავადების ის ფორმაა, როდესაც პანკრეასი ვერ წარმოქმნის საკმარის ინსულინს ან სხეულის უჯრედები არ რეაგირებენ მასზე (უკანასკნელ შემთხვევას მედიცინაში ინსულინრეზისტენტობას უწოდებენ). დიაბეტიანთა 90-95%-ს სწორედ დიაბეტი ტიპი 2 აქვს.</p>
          <p> <span className='symptom-txt'>სიმპტომები: </span> ტიპი 2 კი უმეტესად ნელა ვითარდება, ზოგჯერ წლობით უსიმპტომოდ მიმდინარეობს და ვიდრე ადამიანი შეიტყობს, რომ დიაბეტი აქვს, უზიანდება თვალები, თირკმელები, გული, სისხლძარღვები.
            სიმპტომები ძალიან ჰგავს I ტიპის დიაბეტს მაგრამ შესაძლოა სხვა ნიშნებითაც გამოიხატოს: კისერზე და იღლიის ფოსოში კანის გამუქებით, მაღალი არტერიული წნევით, ქოლესტეროლის ცვლის დარღვევით, სოკოვანი ინფექციებით, ქალებთან – მენსტრუალური ციკლის მოშლით</p>
          <p className='diabet-type'><strong>რედიაბეტი</strong></p>
          <p className='diabet-type-txt'>ჩნდება მაშინ, როდესაც სისხლში შაქარი ნორმაზე მაღალია, მაგრამ ის საკმარისად მაღალი არ არის საიმისოდ, რომ ტიპი 2 დიაბეტის დიაგნოზი დაისვას.</p>

          <p className='diabet-type'><strong>გესტაციური დიაბეტი</strong></p>
          <p className='diabet-type-txt'>გესტაციური დიაბეტი ორსულობის დროს ჰორმონალური ცვლილებების შედეგია.
            პლაცენტა გამოიმუშავებს ჰორმონებს, რომლებიც ორსულის უჯრედებს ნაკლებად მგრძნობიარეს ხდის ინსულინის ზემოქმედების მიმართ. ამან შეიძლება გამოიწვიოს სისხლში შაქრის მომატება ორსულობის დროს.
            ქალები, რომლებსაც აქვთ ჭარბი წონა ორსულობის დროს, უფრო ხშირად ავადდებიან გესტაციური დიაბეტით. </p>
          <p>გესტაციური დიაბეტის მქონე ქალების უმეტესობას არანაირი სიმპტომი არ აქვს. მდგომარეობა ხშირად სისხლში შაქრის ჩვეულებრივი ტესტირების დროს ვლინდება, რომელიც ჩვეულებრივ გესტაციის 24-ე და 28-ე კვირას შორის ტარდება.</p>
          <p>ნებისმიერმა, ვისაც აქვს დიაბეტის სიმპტომები ან დაავადების განვითარების რისკის ქვეშ იმყოფება, უნდა ჩაიტაროს დიაბეტის დიაგნოსტიკური ტესტები.
            ორსულობის მეორე ან მესამე ტრიმესტრში კი აუცილებელია გესტაციური დიაბეტის რეგულარული კვლევა.
            დიაბეტის მართვის მნიშვნელოვანი ნაწილია ჯანსაღი წონის შენარჩუნება ჯანსაღი დიეტისა და ფიზიკური აქტივობის მეშვეობით.
          </p>
        </div>
        <img src={diabeticblog} alt='diabeticblog' className='diabeticblog-img' />
      </div>

    </Layout>
  )
}

export default Diabet