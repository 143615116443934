import React from 'react'
import Layout from '../../components/layout/layout'
import blogarrow from '../../images/blog/blogarrow.svg'
import './prostat.scss'
import prostatblog from '../../images/blog/prostatblog.svg'

const Prostat = () => {
  return (
    <Layout>
      <div className='prostat'>



        <div className='blog-txt-head'>
          <h2 className='blog-main-txt-head'>ბლოგი</h2>
          <img src={blogarrow} alt='blogarrow' className='blogarrow' />
          <p className='blog-main-txt-head-sub'>პროსტატის კიბო, მისი დიაგნოსტიკა და მკურნალობა.</p>
        </div>

        <div className='blog-content'>

          <div className='blog-post-start'>
            <img src={prostatblog} alt='prostatblog' />
            <div className='blog-post-start-right'>

              <p className='blog-post-start-head'>პროსტატის კიბო, მისი დიაგნოსტიკა და მკურნალობა.</p>
              <p>პროსტატა, იგივე წინამდებარე ჯირკვალი მამაკაცის რეპროდუქციული სისტემის ნაწილია. ის მენჯის ღრუში, შარდის ბუშტის ქვემოთ მდებარეობს. მამაკაცთა ონკოლოგიურ დაავადებათა შორის პროსტატის კიბო ერთ-ერთი ყველაზე გავრცელებულია. 2021 წლის სტატისტიკური მონაცემებით, კაცებში გამოვლენილ ახალ სიმსივნურ პათოლოგიებში ის პირველ ადგილს იკავებს. </p>
            </div>
          </div>


          <p>პროსტატის კიბო ეწოდება წინამდებარე ჯირკვალში არსებულ ავთვისებიან წარმონაქმნს. მას ნელი ზრდა ახასიათებს, თუმცა ზოგიერთ შემთხვევებში შესაძლოა სწრაფადაც განვითარდეს. როგორც წესი, საწყის სტადიაზე დაავადება იშვიათად ვლინდება სიმპტომებით. სწორედ ამიტომ, მამაკაცებმა, რომლებიც არ იტარებენ პროფილაქტიკურ კვლევებს, შესაძლოა მის შესახებ ათწლეულების განმავლობაშიც კი ვერ შეიტყონ.</p>
          <h3 className='prostat-header-txt'>პროსტატის კიბოს სიმპტომები</h3>
          <p>როგორც უკვე აღვნიშნეთ, პროსტატის სიმსივნე უმეტესად ჩუმად მიმდინარეობს და  სპეციფიკური სიმპტომებით იშვიათად ვლინდება. გასათვალისწინებელია ისიც, რომ მისი ჩივილები ძალიან ჰგავს პროსტატის კეთილთვისებიან ჰიპერპლაზიას (პკჰ), რომელსაც ადენომასაც უწოდებენ. ასე რომ, კლინიკური ნიშნები, რომლებსაც ქვემოთ ჩამოვთვლით, აუცილებლად პროსტატის კიბოს არ ნიშნავს და მნიშვნელოვანია, ჩატარდეს სრულფასოვანი სამედიცინო გამოკვლევა.
            აღსანიშნავია, რომ სიმპტომთა გამოვლენა იწყება მაშინ, როდესაც კიბო უკვე დიდი ზომისაა და აწვება შარდსადენს. სწორედ ამის შედეგად იბლოკება შარდის ნაკადი ნაწილობრივ ან სრულად.</p>
            <p>პროსტატის კიბოს მეოთხე სტადია ხასიათდება მეტასტაზების გავრცელებით სხვა ორგანოებზე, რაც იწვევს ტკივილს სხეულის სხვადასხვა ნაწილში. ხშირად მეტასტაზები ვრცელდება ძვლებში (მენჯი, ნეკნები, ხერხემალი). არის შემთხვევები, როდესაც დაავადება აზიანებს ზურგის ტვინსაც, რაც იწვევს წელის ტკივილს, დამბლას, საერთო სისუსტესა და შარდის შეუკავებლობას.</p>

            <h3 className='prostat-header-txt'>პროსტატის კიბოს სიმპტომები მოიცავს:</h3>
            <ul>
              <li>გახშირებულ შარდვას</li>
              <li>ნიქტურიას ანუ ღამის შარდვას</li>
              <li>დიზურიას ანუ მტკივნეულ შარდვას</li>
              <li>შარდის ნაკადის მოდუნებასა და წყვეტილ შარდვას</li>
              <li>შარდის ბუშტის არასრულად დაცლის შეგრძნებას</li>
              <li>შარდვის სრული შეკავება</li>
              <li>ჰემატურია ანუ სისხლიანი შარდვა</li>
            </ul>

            <h3 className='prostat-header-txt'>როგორია დაავადების განვითარების რისკ-ფაქტორები?</h3>
            <p>წინამდებარე ჯირკვლის კობი უმეტესად შუახნის და ასაკოვან მამაკაცებშია გავრცელებულია. მისით ძირითადად 45-70 წლამდე ასაკის კაცები ავადდებიან. მიიჩნევა, რომ ახალგაზრდა ასაკში პროსტატაში არსებულ ავთვისებიან წარმონაქმნს უფრო სწრაფი ზრდა ახასიათებს, მიმდინარეობა კი შედარებით მძიმე.</p>
            <p>დაავადების განვითარების რისკი 2-3-ჯერ უფრო მაღალია იმ ადამიანებში, რომელთა ახლო ნათესავებს (მამას, ძმას) აქვთ ან ჰქონიათ პროსტატის კიბო. გენეტიკური წინასწარგანწყობის გარდა, მისი ხელშემწყობი ფაქტორებია ქრონიკული ინფექციური დაავადებები, მწეველობა, ალკოჰოლის ხშირად და ჭარბად მოხმარება, არაჯანსაღი კვების რაციონი, ჰორმონალური ფონის დარღვევა და სხვა.</p>
            <h3 className='prostat-header-txt'>პროსტატის კიბოს მკურნალობა</h3>
            <p>პროსტატის კიბოს მკურნალობა ინდივიდუალურად იგეგმება სიმსივნის ტიპის, ზომისა და აგრესიულობის მიხედვით, ასევე პაციენტის ასაკის, პრეფერენციებისა და ჯანმრთელობის საერთო მდგომარეობის მიხედვით.
ხშირად დაავადებას ადრეულ სტადიაზე ქირურგიულად მკურნალობენ, რა დროსაც ხდება სიმსივნური წარმონაქმნისა და მის ირგვლივ მდებარე ლიმფური კვანძების ამოკვეთა. საჭიროების შემთხვევაში, ოპერაცია შეიძლება შესრულდეს როგორც ღია წესით, ისე ლაპარასკოპიულად.
იმ შემთხვევაში, თუ წარმონაქმნი არ არის ლოკალიზებული და სიმსივნური პროცესები შორსწასულია, პროსტატის კიბოს მკურნალობა კონსერვატიული მიდგომით წარიმართება. ეს გულისხმობს მკურნალობაში სხივური თერაპიის, ჰორმონული თერაპიისა და ქიმიოთერაპიის ჩართვას.
</p>
<p>დასასრულ, უნდა აღვნიშნოთ, რომ პროსტატის კიბო ადრეულ სტადიაზე უმეტესად მარტივად იკურნება, ბევრ შემთხვევაში კი მიიღწევა ხანგრძლივი რემისია. ამიტომ ძალიან მნიშვნელოვანია მისი დროული აღმოჩენა, სრულფასოვანი დიაგნოსტირება და ეფექტიანი მკურნალობა. ამ ყველაფერს კი ერთ სივრცეში გთავაზობთ თოდუას კლინიკა. ჩვენთან შესაძლებელია სრული სამედიცინო დიაგნოსტირება და ყველა საჭირო სამკურნალო პროცედურის ჩატარება გამოცდილი და მაღალკვალიფიციური უროლოგების ხელმძღვანელობით.</p>


        </div>

      </div>

    </Layout>
  )
}

export default Prostat