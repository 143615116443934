import React from 'react'
import Layout from '../../components/layout/layout'
import blogarrow from '../../images/blog/blogarrow.svg'
import './buasil.scss'
import buasilblog from '../../images/blog/buasilblog.svg'
import { Helmet } from 'react-helmet';

import buasilblogimg from '../../images/blogimages/buasil-blog-img.svg'


const Buasil = () => {
  return (
    <Layout>
      <Helmet>
        <title>ბუასილის მკურნალობა თურქეთში - თურქეთის წამყვან კლინიკებში</title>
        <meta name="description" content="მკურნალობა თურქეთის წამყვან კლინიკებში - აღმოაჩინეთ ინფორმაცია ინ ვიტრო განაყოფიერება, ბარიატრიული ქირურგია, ონკოლოგიური დაავადებები და სხვა სამედიცინო თემები." />
        <meta name="keywords" content="ბლოგი, სამედიცინო ტურიზმი, მკურნალობა, ბარიატრია, ონკოლოგია, თურქეთის კლინიკები" />
      </Helmet>
      <div className='buasil'>
        <div className='blog-txt-head'>
          <h2 className='blog-main-txt-head'>ბლოგი</h2>
          <img src={blogarrow} alt='blogarrow' className='blogarrow' />
          <p className='blog-main-txt-head-sub'>ბუასილი და მისი მკურნალობა.</p>
        </div>

        <div className='blog-content'>

          <div className='blog-post-start'>
            <img src={buasilblog} alt='buasilblog' />
            <div className='blog-post-start-right'>

              <p className='blog-post-start-head'>ბუასილი და მისი მკურნალობა.</p>
              <p>ბუასილი, იგივე ჰემოროიდული დაავადება, ერთ-ერთი ყველაზე გავრცელებული პრობლემაა მთელს მსოფლიოში.  </p>
              <p>ჰემოროიდულ კომპლექსს ძალიან მნიშვნელოვანი ფუნქცია აქვს ჩვენს ორგანიზმში. იგი სწორი ნაწლავის ანალური ნაწილთან სხვა ანატომიურ სტრუქტურებთან ერთობლივად მუშაობს, რათა ხელი შეუწყოს და დაარეგულიროს აირების, ნაწლავების შიგთავსის შეკავება. ზუსტად ამიტომ, სწორ ნაწლავში ჰერმეტიულობა დაცულია. ჰემოროიდული კვანძების გადიდება კი უკვე პათოლოგიური პროცესია და ამას ჰემოროიდული დაავადება, ბუასილი ეწოდება.</p>
            </div>
          </div>
          <p>ამ დაავადების თვითმკურნალობა დაუშვებელია, რადგან შესაძლოა მსხვილი ნაწლავის უფრო სერიოზული და სახიფათო პათოლოგია განვითარდეს.
            ამიტომ, ბუასილის ნიშნები გამოვლინდება თუ არა, სამკურნალოდ დროულად უნდა მიმართოთ გამოცდილ ექიმ პროქტოლოგს.
            დიაგნოსტირება ხდება სამედიცინო გამოკვლევის შედეგად. ზოგიერთ შემთხვევაში შეიძლება საჭირო გახდეს დამატებითი კვლევები, როგორიცაა კოლონოსკოპია, რათა გამოირიცხოს სხვა გართულებები.
          </p>
          <h3 className='buasil-head'>ბუასილის სახეები და სიმპტომები</h3>
          <p>ჰემოროი შეიძლება დავყოთ შიდა და გარე ფორმად. შიდა ჰემოროი მდებარეობს სწორი ნაწლავის შიგნით და შესაძლოა გამოიწვიოს სისხლიანი გამონადენი. გარე ჰემოროი კი მდებარეობს ანალური ხვრელის გარშემო და იწვევს ტკივილს.
            ჰემოროის ძირითადი სიმპტომებია: ტკივილი ან დისკომფორტი დეფეკაციის შემდეგ ქავილი ან გაღიზიანება ანალურ არეში წითელი სისხლიანი გამონადენი დეფეკაციის დროს სიმსივნე ან ბურთულა ანუსის მახლობლად სველი, მტკივნეული მასა ანუსთან.</p>
            <h3 className='buasil-head'>გამოყოფენ ქრონიკული ბუასილის 4 სტადიას:</h3>
        <ul>
          <li>პირველი სტადია ანალური არხიდან კვანძების გამოვარდნის გარეშე იწყება სისხლდენა დეფეკაციის დროს.</li>
          <li>მეორე სტადია ჭინთვების დროს ბუასილის კვანძი გარეთ გამოდის, ხოლო დეფეკაციის დამთავრების შემდეგ უკან ბრუნდება.</li>
          <li>მესამე სტადია ბუასილის კვანძი მხოლოდ დეფეკაციის დროს გამოდის გარეთ, მაგრამ ის უკან არ ბრუნდება, საჭიროა დახმარება. მოგვიანებით პროლაფსი შეიძლება დაიწყოს მუცელშიდა წნევის მომატების შემთხვევაში. მაგალითად, ადამიანმა თუ მძიმე ტვირთი აწია, ან ხველის დროს.</li>
          <li>მეოთხე სტადია ამ დროს პროლაფსი პერმანენტულია, რომელიც არ სწორდება. იწყება თრომბოზი, ტკივილი, ხშირად ძლიერი სისხლდენა. საჭირო ხდება ბუასილის კვანძების მოშორება.</li>
        </ul>
        <div className='buasilblogimg-box'>
        <img src={buasilblogimg} alt='buasilblogimg' className='buasilblogimg' />
        </div>

        </div>
      
      </div>

    </Layout>
  )
}

export default Buasil