import React from 'react'
import Layout from '../../components/layout/layout'
import blogarrow from '../../images/blog/blogarrow.svg'
import './turkeymedic.scss'
import turkeymedicblog from '../../images/blog/turkeymedicblog.svg'
import HeadLogo from '../../images/head-logo.svg'

const Turkeymedic = () => {
  return (
    <Layout>
      <div className='turkeyBlog'>
        <div className='blog-txt-head'>
          <h2 className='blog-main-txt-head'>ბლოგი</h2>
          <img src={blogarrow} alt='blogarrow' className='blogarrow' />
          <p className='blog-main-txt-head-sub'>თურქეთი მსოფლიოში მეშვიდე ადგილს იკავებს ჯანმრთელობის ტურიზმში.</p>
        </div>
        <div className='blog-content'>
          <div className='blog-post-start'>
            <img src={turkeymedicblog} alt='turkeymedicblog' />
            <div className='blog-post-start-right'>
              <p className='blog-post-start-head'>თურქეთი მსოფლიოში მეშვიდე ადგილს იკავებს ჯანმრთელობის ტურიზმში.</p>
              <p>თურქეთი მსოფლიოში მეშვიდე ადგილს იკავებს ჯანმრთელობის ტურიზმში.</p>
              <p>თურქეთის ჯანდაცვის სექტორმა აღიარება მოიპოვა მაღალი ხარისხის სამედიცინო მომსახურების მიწოდებით შედარებით დაბალ ფასად, ვიდრე ბევრ დასავლურ ქვეყანაში. ხელმისაწვდომობის ამ ფაქტორმა, კვალიფიციური ჯანდაცვის პროფესიონალების ხელმისაწვდომობასთან ერთად, მიიპყრო საერთაშორისო პაციენტების ყურადღება, რომლებიც ეძებენ ხელმისაწვდომ, მაგრამ საიმედო სამედიცინო გადაწყვეტილებებს.</p>
            </div>
          </div>
          <p>თურქეთი იზიდავს პაციენტებს მთელი მსოფლიოდან, რომლებიც ეძებენ მაღალი ხარისხის ჯანდაცვის მომსახურებას ხელმისაწვდომ ფასებში. თავისი მოწინავე სამედიცინო ინფრასტრუქტურით, კვალიფიციური ჯანდაცვის პროფესიონალებით და მკურნალობის მრავალფეროვნებით, თურქეთი პოზიციონირდა, როგორც წამყვან მიმართულება სამედიცინო ტურისტებისთვის.
            თურქეთი ამაყობს თანამედროვე და დახვეწილი ჯანდაცვის ინფრასტრუქტურით, თანამედროვე საავადმყოფოებითა და კლინიკებით უახლესი სამედიცინო ტექნოლოგიებით. გარდა ამისა, ამ დაწესებულებიდან ბევრი ფლობს საერთაშორისო აკრედიტაციას, რაც უზრუნველყოფს ჯანდაცვის მიწოდების გლობალურ სტანდარტებთან შესაბამისობას და უზრუნველყოფს პაციენტის უსაფრთხოებას.</p>
          <h3>როგორ ხდება თურქეთში მკურნალობა?</h3>
          <p>მკურნალობის დაგეგმვა – პირველ ეტაპზე ხდება პაციენტთან წინასწარ გასაუბრებით.
            გროვდება პაციენტის სამედიცინო ისტორია, მათ შორის: არსებული დიაგნოზი, მკურნალობის ეტაპები, ოპერაციული ჩარევები და ა.შ
            პროფესორების და მედ პერსონალის მიერ გადამოწმდება არსებული მასალა, რის შემდეგაც პაციენტს ეგზავნება მკურნალობის გეგმა გაწერილი ხარჯებით.
            ჩვენ გთავაზობთ ასევე შემდეგ სერვისებს: ტრანსფერი საბაჟო ფუნქტიდან, კვალიფიციური თარჯიმნის მომსახურეობას, დანიშნული მედიკამენტების შეძენაში დახმარებას, საჭიროების შემთხვევაში სასტუმროს დაჯავშნა.</p>

          <h3>როგორ შევამციროთ თურქეთში მკურნალობის ხარჯები?</h3>
          <ul className='turkey-blog-list'>
            <li>- მკურნალობის ღირებულება სხვადასხვა კლინიკაში განსხვავებულია, აუცილებლად გადაამოწმეთ ფასები და წინასწარ გაარკვიეთ მკურნალობის სავარაუდო ღირებულების ფასი.</li>
            <li>- არდაეყრდნოთ მხოლოდ ერთი კლინიკის მიხედვით ფასებს.</li>
            <li>- თურქეთში ბევრი კლინიკაა მათ შორის არის კარგიც და ცუდიც, ძვირიც და იაფიც, ხარისხიანი და უხარისხო მომსახურეობით. აირჩიე შენზე მორგებული საუკეთესო ვარიანტი.</li>
            <li>- კლინიკის არჩევისას მიაქციეთ ყურადღება კლინიკას აქვს თუ არა ის განყოფილება, რომელიც კონკრეტულად შეესაბამება თქვენს დაავადებას.</li>
            <li>- უცხო ქვეყანაში მოგზაურობაც კი რთულია, ხოლო მკურნალობა სერიოზულ საკითხებთან არის დაკავშირებული, ამიტომ პროფესიონალებს დააგეგმინეთ მკურნალობა!</li>
          </ul>
          <div className='HeadLogo-turkey-box'>
            <img src={HeadLogo} alt='HeadLogo' className='HeadLogo-turkey' />
            </div>

            <p>კომპანია [Medical Tourism] იძლევა უნიკალურ შესაძლებლობას ქართული მოქალაქეებისთვის, მიიღონ უმაღლესი ხარისხის სამედიცინო მომსახურება თურქეთის წამყვან კლინიკებში.
              ჩვენ გვაქვს მჭიდრო თანამშრომლობა და ურთიერთობა თითოეულ სამედიცინო დაწესებულებასთან, რომლებიც უზრუნველყოფენ ყველაზე მნიშვნელოვან და რთულ სამედიცინო მომსახურებას თანამედროვე ინოვაციური მეთოდებით.
              კომპანია [Medical Tourism] არის ლიდერი სამედიცინო ტურიზმის სფეროში, რომელიც გთავაზობთ პროფესიონალურ და ხარისხიან მომსახურებას საქართველოს მოქალაქეებს, რომლებსაც სურთ მიიღონ წამყვან სამედიცინო კლინიკებში მკურნალობა თურქეთში.
              ჩვენ ვთავაზობთ სრული სამედიცინო ტურიზმის მომსახურების პაკეტს, რომელიც მოიცავს არა მხოლოდ მაღალი ხარისხის მკურნალობას, არამედ ორგანიზაციული საკითხების სრულ პაკეტს – თარჯიმანი, ტრანსპორტი, განთავსება და სხვა.</p>

            <p>ჩვენი მიზანია, რომ თითოეულ პაციენტს მივაწოდოთ საუკეთესო სამედიცინო მომსახურეობა, დავარწმუნოთ მათ კომფორტსა და უსაფრთხოებაში, ხოლო პროცედურები შევასრულოთ უმაღლეს საერთაშორისო სტანდარტებზე. </p>
          
        </div>
      </div>
    </Layout>
  )
}

export default Turkeymedic