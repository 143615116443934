import React from 'react'
import Layout from '../../components/layout/layout'
import blogarrow from '../../images/blog/blogarrow.svg'
import './chests.scss'
import chestblog from '../../images/blog/chestblog.svg'

const Chests = () => {
  return (
    <Layout>
      <div className='chests'>



        <div className='blog-txt-head'>
          <h2 className='blog-main-txt-head'>ბლოგი</h2>
          <img src={blogarrow} alt='blogarrow' className='blogarrow' />
          <p className='blog-main-txt-head-sub'>მკერდის კორექცია (მამოპლასტიკა) – გაზრდა, დაპატარავება, აწევა…</p>
        </div>

        <div className='blog-content'>

          <div className='blog-post-start'>
            <img src={chestblog} alt='chestblog' />
            <div className='blog-post-start-right'>

              <p className='blog-post-start-head'>მკერდის კორექცია (მამოპლასტიკა) გაზრდა, დაპატარავება, აწევა…</p>
              <p>ლამაზი მკერდი ყველა ქალის ოცნებაა და ეს ოცნება დღეს უკვე რეალობადაა ქცეული მამოპლასტიკის მეშვეობით. მამოპლასტიკას უწოდებენ მთელ რიგ პლასტიკურ ოპერაციებს სარძევე ჯირკვლებზე.</p>
            </div>
          </div>

          <p>უსაფრთხო იმპლანტების ხელმისაწვდომობამ და ქირურგიული ტექნიკების დახვეწამ, მნიშვნელოვნად გაიზარდა მოთხოვნა მკერდის კორექციაზე. დღეს მამოპლასტიკა ერთ-ერთი ყველაზე განვითარებული და პოპულარული მიმართულებაა პლასტიკურ ქირურგიაში.  თანამედროვე მამოპლასტიკით არა მხოლოდ მცირე დეფექტების გამოსწორებაა შესაძლებელი, არამედ ისეთი რთული ოპერაციების ჩატარებაც, როგორიცაა ძუძუს სრული რეკონსტრუქცია. </p>
          <h3>მამოპლასტიკის ყველაზე გავრცელებული ოპერაციებია:</h3>
          <ul>
            <li>გადიდება - (აუგმენტაციური მამოპლასტიკა)</li>
            <li>დაპატარავება (რედუქციული მამოპლასტიკა)</li>
            <li>აწევა(მასტოპექსია)</li>
            <li>მკერდის ლიპოფილინგი</li>
            <li>დეფორმირებული მკერდის კორექცია</li>
            <li>ჩაბრუნებული ძუძუს თავის პლასტიკა</li>
            <li>სარძევე ჯირკვლის დვრილის პლასტიკა</li>
          </ul>

          <h3>რა ღირს მკერდის იმპლანტების დაყენების ოპერაცია?</h3>

          <p>სხვადასხვა ქვეყნებში მამოპლასტიკის ფასების შედარებისთვის წარმოგიდგენთ 15 ქვეყანაში ოპერაციის მიახლოებით ფასებს. ხშირ შემთხვევებს ფასი შედგება ენდოპროთეზების, ქირურგიული ჩარევის, ექიმის კონსულტაციის, სტაციონარში განთავსების, სპეციალური ბიუსტჰალტერის და საჭირო გამოკვლევების ღირებულებისგან.</p>

          <ul className='country-costs'>
            <li>$2 664-დან თურქეთი</li>
            <li>$3 219-დან ჩეხეთი</li>
            <li>$3 496-დან მექსიკა </li>
            <li>$3 335-დან ტაილანდი</li>
            <li>$5 250-დან ინდოეთი</li>
            <li>$3 700-დან არაბეთის გაერთიანებული საემიროები</li>
            <li>$4 600-დან უკრაინა</li>
            <li>$4 700-დან სამხრეთი კორეა</li>
            <li>$8 000-დან გერმანია</li>
            <li>$8 500-დან ისრაელი</li>
            <li>$10 300-დან ავსტრია</li>
            <li>$11 400-დან ესპანეთი</li>
            <li>$12 100-დან იტალია</li>
            <li>$15 100-დან შვეიცარია</li>
            <li>$17 100-დან რუსეთი</li>
          </ul>

          <h3>მითები მამოპლასტიკის შესახებ</h3>
          <div>
            <p> <span className='chest-price-img'>X</span>  მამოპლასტიკის შემდეგ ქალს არ შეუძლია ბავშვის ძუძუთი კვება.</p>
            <p> <span className='chest-price-img'>X</span> იმპლანტი შეიძლება აფეთქდეს თვითმფრინავში წნევის ცვლილების გამო.</p>
            <p> <span className='chest-price-img'>X</span>  ენდოპროთეზის დაზიანება სახიფათოა ჯანმრთელობისთვის და სიცოცხლისთვის.</p>
            <p> <span className='chest-price-img'>X</span>  მკერდის გადიდების შემდეგ არ შეიძლება მუცელზე წოლა.</p>
            <p> <span className='chest-price-img'>X</span>  იმპლანტების დაყენება მკერდის კიბოს იწვევს.</p>
          </div>
        </div>

      </div>

    </Layout>
  )
}

export default Chests