import React from 'react'
import facebookfooter from '../../images/footer/facebookfooter.svg'
import instagramfoot from '../../images/footer/instagramfoot.svg'
import footlogo from '../../images/footlogo.png'
import whatsappfoot from '../../images/footer/whatsappfoot.svg'
import './footer.scss';
import { useNavigate } from 'react-router-dom'
import phonemobile from '../../images/home/phonemobile.svg'
import phoneemail from '../../images/home/phoneemail.svg'





const Footer = () => {
  const navigate = useNavigate();

  const handleBlog = () => {
    navigate('/blog')
  }

  const handleHome = () => {
    navigate('/')
  }

  const handleServices = () => {
    navigate('/services')
  }
  return (
    <footer className='footer'>



        <div className='footer-left'>
          <div className='footer-left-logo-box'>
            <img src={footlogo} alt='facebookfooter' className='facebookfooter-img'/>

            <div className='footer-left-img-block'>
              <a href='https://www.facebook.com/profile.php?id=100068552813359' target="_blank"
              rel="noopener noreferrer"><img src={facebookfooter} alt='facebook' /></a>
              <a href='https://www.instagram.com/medical_tourism_iin_turkey?igsh=MW01MDZteDUzNnVzOA%3D%3D&utm_source=qr&fbclid=IwY2xjawHCvFFleHRuA2FlbQIxMAABHR-J2Xe6W-4BXUZ57UADB89QiL6hQkSJuzby4KVR3r0OQcDmY0t2X5ashA_aem_st__iNczmmGR_lFfYX-muw' target="_blank"
              rel="noopener noreferrer"><img src={instagramfoot} alt='instagram' /> </a>
              <a href="https://wa.me/995568320164?text=გამარჯობა" target="_blank" rel="noopener noreferrer"> <img src={whatsappfoot} alt='whatsapp' />
            </a>
            </div>
          </div>


          <div className='footer-left-main-box'>
            <p className='footer-left-main-txt' onClick={handleHome}>მთავარი</p>
            <p  onClick={handleServices}>განყოფილებები</p>
            <p  onClick={handleBlog}>ბლოგი</p>
          </div>
        </div>

        <div className='footer-right-box-contact'>
          <p className='footer-right-box-contact-txt'>საკონტაკტო: +995 568 32 01 64</p>
          <p>მეილი: Medicaltourısm@mail.ru</p>
        </div>

        <div className='mobile-footer-contact'>
        <div className='footer-left-main-box mobile-footer-contact-left'>
            <p className='footer-left-main-txt' onClick={handleHome}>მთავარი</p>
            <p  onClick={handleServices}>განყოფილებები</p>
            <p  onClick={handleBlog}>ბლოგი</p>
          </div>
          <div className='footer-right-box-contact mobile-footer-contact-right'>
            <div className='footer-bottom-mobile'>
              <img src={phonemobile} alt='phone' className='footer-right-box-contact-img'/>
              <p className='footer-right-box-contact-txt'> +995 568 32 01 64</p>              
            </div>

            <div className='footer-bottom-mobile'>
              <img src={phoneemail} alt='email' className='footer-right-box-contact-img'/>
              <p>Medicaltourısm@mail.ru</p>
            </div>
        </div>

        </div>

    </footer>
  )
}

export default Footer