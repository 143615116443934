import React from 'react'
import './blog.scss'
import Layout from '../../components/layout/layout'
import { Helmet } from 'react-helmet';

import invitro from '../../images/news/invitro.svg'
import bariatria from '../../images/news/bariatria.svg'
import oncologic from '../../images/news/oncologic.svg'
import { useNavigate } from 'react-router-dom'



import aimedic from '../../images/blog/aimedic.svg'
import biopsia from '../../images/blog/biopsia.svg'
import buasil from '../../images/blog/buasil.svg'
import chests from '../../images/blog/chests.svg'
import cistit from '../../images/blog/cistit.svg'
import diabet from '../../images/blog/diabet.svg'
import doccheck from '../../images/blog/doccheck.svg'
import gastrit from '../../images/blog/gastrit.svg'
import heart from '../../images/blog/heart.svg'
import neckcancer from '../../images/blog/neckcancer.svg'
import nevroz from '../../images/blog/nevroz.svg'
import pets from '../../images/blog/pets.svg'
import prostat from '../../images/blog/prostat.svg'
import stomach from '../../images/blog/stomach.svg'
import turkeymedic from '../../images/blog/turkeymedic.svg'


const Blog = () => {

  const news = [
    { src: invitro, title: 'ინ ვიტრო განაყოფიერება IVF.', link: '/blog/invitro' },
    { src: bariatria, title: 'ბარიატრიული ქირურგია (SLEEVE GASTRECTOMY)', link: '/blog/bacterial' },
    { src: oncologic, title: 'ონკოლოგიური დაავადებები.', link: '/blog/oncologic' },
    { src: biopsia, title: 'ბიოფსია.', link: '/blog/biopsia' },
    { src: diabet, title: 'დიაბეტის ტიპები და გამომწვევი მიზეზები.', link: '/blog/diabet' },
    { src: pets, title: 'პოზიტრონ-ემისიური ტომოგრაფია (პეტ) და მისი უპირატესობები.', link: '/blog/pets' },
    { src: doccheck, title: 'რატომაა ჯანმრთელობის პერიოდული გადამოწმება ადამიანისთვის აუცილებელი?', link: '/blog/doccheck' },
    { src: aimedic, title: 'ხელოვნური ინტელექტის როლი სამედიცინო მომსახურების განვითარებაში.', link: '/blog/aimedic' },
    { src: turkeymedic, title: 'თურქეთი მსოფლიოში მეშვიდე ადგილს იკავებს ჯანმრთელობის ტურიზმში.', link: '/blog/turkeymedic' },
    { src: chests, title: 'მკერდის კორექცია (მამოპლასტიკა) – გაზრდა, დაპატარავება, აწევა…', link: '/blog/chests' },
    { src: nevroz, title: 'ნევროზი – გამომწვევი მიზეზები, სიმპტომები და მკურნალობა.', link: '/blog/nevroz' },
    { src: gastrit, title: 'გასტრიტი და მისი მართვის გზები.', link: '/blog/gastrit' },
    { src: prostat, title: 'პროსტატის კიბო, მისი დიაგნოსტიკა და მკურნალობა.', link: '/blog/prostat' },
    { src: neckcancer, title: 'ყელის კიბო - მიზეზები, მკურნალობა და პრევენცია.', link: '/blog/neckcancer' },
    { src: cistit, title: 'ცისტიტი – სიმპტომები და მკურნალობა.', link: '/blog/cistit' },
    { src: stomach, title: 'კუჭ-ნაწლავის ყველაზე გავრცელებული პრობლემები.', link: '/blog/stomach' },
    { src: heart, title: 'გულ-სისხლძარღვთა დაავადებები, სიმპტომები და პრევენცია.', link: '/blog/heart' },
    { src: buasil, title: 'ბუასილი და მისი მკურნალობა.', link: '/blog/buasil' },
  ]

  const navigate = useNavigate();

  const handleBlogNavigate = (url) => {
    navigate(url)
  }

  return (
    <Layout>
       <Helmet>
        <title>ბლოგი - მკურნალობა თურქეთში</title>
        <meta name="description" content="მკურნალობა თურქეთში - აღმოაჩინეთ ინფორმაცია ინ ვიტრო განაყოფიერება, ბარიატრიული ქირურგია, ონკოლოგიური დაავადებები და სხვა სამედიცინო თემები." />
        <meta name="keywords" content="ბლოგი, სამედიცინო ტურიზმი, მკურნალობა, ბარიატრია, ონკოლოგია, თურქეთის კლინიკები" />
      </Helmet>
      <div className='blog'>

      <div className='services-main-title margin-bottom'>
      <p> ბლოგი</p>
      </div>
        <div className='blog-container'>
          {news.map((news, index) => (
            <div className='blog-news-container' key={index}>
              <img src={news.src} alt={news.src} onClick={() => handleBlogNavigate(news.link)} />
              <p className='blog-container-txt'>{news.title}</p>
            </div>
          ))}
        </div>
      </div>
    </Layout>

  )
}

export default Blog