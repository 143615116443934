import React from 'react'
import Layout from '../../components/layout/layout'
import blogarrow from '../../images/blog/blogarrow.svg'
import './gastrit.scss'
import gastritblog from '../../images/blog/gastritblog.svg'

const Gastrit = () => {
  return (
    <Layout>
      <div className='gastrit'>
        <div className='blog-txt-head'>
          <h2 className='blog-main-txt-head'>ბლოგი</h2>
          <img src={blogarrow} alt='blogarrow' className='blogarrow' />
          <p className='blog-main-txt-head-sub'>გასტრიტი და მისი მართვის გზები.</p>
        </div>

        <div className='blog-content'>

          <div className='blog-post-start'>
            <img src={gastritblog} alt='gastritblog' />
            <div className='blog-post-start-right'>

              <p className='blog-post-start-head'>გასტრიტი და მისი მართვის გზები.</p>
              <p>გასტრიტი კუჭის ლორწოვანი გარსის ანთებაა. კუჭის ლორწოვანი გარსი ძალიან მნიშვნელოვან როლს ასრულებს ჩვენს ორგანიზმში. ის წარმოქმნის კუჭის მჟავასა და ფერმენტებს, რომლებიც ხელს უწყობენ საჭმლის მონელებას. როდესაც ლორწოვან გარსში იწყება ანთება, ის ვეღარ ასრულებს დაკისრებულ მოვალეობას, რაც იწვევს სხვადასხვა სიმპტომებს.</p>
            </div>
          </div>

          <h3>გასტრიტის ორი ძირითადი ტიპი არსებობს: მწვავე და ქრონიკული გასტრიტი.</h3>
          <h3 className='blue-head'>მწვავე გასტრიტი</h3>
          <p>მწვავე გასტრიტი მოულოდნელად ჩნდება და მკვეთრად გამოხატული სიმპტომები ახასიათებს: მუცლის ძლიერი დისკომფორტი, ტკივილი, გულისრევა და ღებინება. ამ ტიპის გასტრიტი სხვადასხვა ფაქტორებითაა გამოწვეული:</p>
          <ul>
            <li>არასტეროიდული ანთების საწინააღმდეგო საშუალებების გამოყენება: მედიკამენტებმა, როგორიცაა იბუპროფენი ან ასპირინი, შეიძლება გააღიზიანონ კუჭის ლორწოვანი გარსი, თუ გამოიყენება ხშირად ან მაღალი დოზებით.</li>
            <li>ალკოჰოლის გადაჭარბებული მოხმარება: ალკოჰოლის გადაჭარბებული მოხმარება კუჭის დამცავი გარსის განადგურების ერთ-ერთი მიზეზია.</li>
            <li>ბაქტერიული ინფექციები: ზოგიერთი ბაქტერია, განსაკუთრებით Helicobacter pylori, იწვევს მწვავე გასტრიტს.</li>
            <li>სტრესი: ქირურგიული ჩარევის, მძიმე დაავადებების, დამწვრობის ან მძიმე ინფექციების შედეგად მიღებული ძლიერი სტრესი ხშირად კავშირშია მწვავე გასტრიტთან.</li>
          </ul>

          <h3 className='blue-head'>ქრონიკული გასტრიტი</h3>
          <p>ქრონიკული გასტრიტი თანდათანობით ვითარდება და დიდი ხნის განმავლობაში გრძელდება, თუ მკურნალობა დროულად არ დაიწყო. ამას კი მოჰყვება კუჭის ლორწოვანი გარსის გაუარესება. მწვავე გასტრიტისგან განსხვავებით, ქრონიკული გასტრიტის სიმპტომები უფრო მსუბუქია, ან საერთოდ არ არის. მიუხედავად ამისა, ამ მდგომარეობამ შეიძლება გამოიწვიოს სერიოზული გართულებები.
            ქრონიკული გასტრიტის გამომწვევი მიზეზებია:
          </p>
          <ul>
            <li>Helicobacter pylor ბაქტერიით ხანგრძლივი ინფექცია ქრონიკული გასტრიტის ხშირი მიზეზია.</li>
            <li>ავტოიმუნური ფაქტორები: ზოგიერთ შემთხვევაში, სხეულის იმუნური სისტემა თავს ესხმის კუჭის ლორწოვან უჯრედებს, რაც იწვევს გასტრიტს.</li>
            <li>ნაღვლის ქრონიკული რეფლუქსი: საჭმლის მომნელებელი სითხე, რომელიც წარმოიქმნება ღვიძლში, უკან ბრუნდება კუჭში, რაც დროთა განმავლობაში ანთებას იწვევს.</li>
          </ul>
          <h3 className='blue-head'>გასტრიტის სიმპტომები</h3>
          <p>გასტრიტის ნიშნები და მისი სიმწვავე ინდივიდუალურია და დამოკიდებულია დაავადების ტიპზე. ზოგჯერ ის სიმპტომებით საერთოდ არ ვლინდება. მაგალითად, არასტეროიდული ანთების საწინააღმდეგო მედიკამენტებით გამოწვეულ გასტრიტს კლინიკური ნიშნები ხშირად არ ახასიათებს.
            სიმპტომები, რომლებსაც უნდა მიაქციოთ ყურადღება:</p>
          <ul>
            <li>კუჭის ტკივილი – ის გასტრიტის ერთ-ერთი მთავარი ნიშანია. პაციენტები ხშირად მას, როგორც მღრღნელ ტკივილად, ისე აღწერენ. დაავადებისთვის, ასევე, დამახასიათებელია წვის შეგრძნება მუცლის ზედა ნაწილში, რომელიც შეიძლება იყოს საკვების მიღებამდე ან/და ღამით მშიერ კუჭზე.</li>
            <li>მუცლის შებერილობა და დისკომფორტი – გაზების დაგროვება, სისავსისა და დისკომფორტის შეგრძნება მუცლის ზედა ნაწილში გასტრიტს, ძირითადად, ჭამის შემდეგ ახასიათებს. საკვების მიღებამ შესაძლოა კუჭის ტკივილიც გაამწვავოს.</li>
            <li>გულისრევა და პირღებინება – გასტრიტი ხშირად იწვევს გულისრევის შეგრძნებას, რაც ზოგიერთ შემთხვევაში შეიძლება პირღებინებაშიც გადაიზარდოს.</li>
            <li>მადის დაქვეითება – გარკვეული ტიპის გასტრიტს მადის დაქვეითება ახასიათებს. პაციენტს შეიძლება საერთოდ არ ჰქონდეს საკვების მიღების სურვილი.</li>
            <li>წონის მოულოდნელი კლება – მადის დაქვეითებასა და ჭამის გართულებას ხშირად მოჰყვება წონის კლება. ის უფრო მეტად ქრონიკულ გასტრიტს ახასიათებს.</li>
          </ul>
          <h3 className='blue-head'>როგორ ხდება დიაგნოსტირება</h3>
          <p>ექიმები გასტრიტის დიაგნოზს შემდეგნაირად სვამენ:</p>
          <ul>
            <li>სამედიცინო ისტორია და ფიზიკური გამოკვლევა: სიმპტომების განხილვა და მუცლის არეში ტკივილის შემოწმება.</li>
            <li>ტესტები: მათ შორის სისხლის ანალიზები, განავლის ტესტები და ტესტები Helicobacter pylori-ზე.</li>
            <li>ენდოსკოპია: ამ შემთხვევაში გამოიყენება პატარა კამერა კუჭის ლორწოვანი გარსის დასათვალიერებლად.</li>
          </ul>
          <h3 className='blue-head'>გასტრიტის მკურნალობა</h3>
          <p>აღსანიშნავია, რომ გასტრიტის დროულად მკურნალობა არა მხოლოდ შემაწუხებელი ჩივილების მარტივად მოხსნაში დაგეხმარებათ, არამედ თავიდან აგაცილებთ მის გართულებებსაც. გასტრიტის, ერთ-ერთი გართულებაა კუჭის წყლული, რომელიც კიბოსწინარე მდგომარეობად მიიჩნევა. ამიტომ მისი განვითარების შემთხვევაში, დროული რეაგირება და კუჭის წყლულის მკურნალობა კიდევ უფრო აუცილებელია. </p>
          <p>მკურნალობის ტაქტიკა მნიშვნელოვნად არის დამოკიდებული გასტრიტის გამომწვევ მიზეზსა და ტიპზე. ხშირად ის მარტივად იკურნება ან იმართება, ზოგჯერ კი მას კომპლექსური მიდგომა სჭირდება. ნებისმიერ შემთხვევაში მედიცინის დარგი გასტროენტეროლოგია დღეს სწრაფი ტემპით ვითარდება და კუჭ-ნაწლავის პრობლემების მქონე პაციენტებს გაუმჯობესებულ სამკურნალო მეთოდებს სთავაზობს. </p>
        </div>
      </div>

    </Layout>
  )
}

export default Gastrit