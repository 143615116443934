import React from 'react'
import Layout from '../../components/layout/layout'
import blogarrow from '../../images/blog/blogarrow.svg'
import './cistit.scss'
import cistitblog from '../../images/blog/cistitblog.svg'

const Cistit = () => {
  return (
    <Layout>
      <div className='cistit'>
        <div className='blog-txt-head'>
          <h2 className='blog-main-txt-head'>ბლოგი</h2>
          <img src={blogarrow} alt='blogarrow' className='blogarrow' />
          <p className='blog-main-txt-head-sub'>ცისტიტი – სიმპტომები და მკურნალობა.</p>
        </div>

        <div className='blog-content'>

          <div className='blog-post-start'>
            <img src={cistitblog} alt='cistitblog' />
            <div className='blog-post-start-right'>

              <p className='blog-post-start-head'>ცისტიტი – სიმპტომები და მკურნალობა.</p>
              <p>შარდის ბუშტის ანთება, ცისტიტი შარდის ბუშტის კედლის ინფექციურ-ანთებითი პროცესია, როდესაც ცისტიტზე ვლაპარაკობთ, შარდის ბუშტის ანთებასთან ერთად შარდსადინარის ანთება – ურეთრიტიც იგულისხმება. ცისტიტი შეიძლება იყოს როგორც ბაქტერიული, ისე არაბაქტერიული (ინტერსტიციული) წარმოშობის. </p>
            </div>
          </div>

          <p>ცისტიტის მიმდინარეობა მწვავე ან ქრონიკული ხასიათისაა. მწვავე ცისტიტი ხშირად უვითარდებათ პრაქტიკულად ჯანმრთელ ქალებს, საშარდე გზების რაიმე გამოხატული პათოლოგიების გარეშე. ქრონიკულად ითვლება ცისტიტი, რომელიც წელიწადში სულ მცირე 2-3-ჯერ გამოვლინდება. ცისტიტს ძირითადად ახასიათებს გახშირებული და მტკივნეული შარდვა, დაუსრულებელი მოშარდვის შეგრძნება, დისკომფორტი მუცლის ქვედა არეში. მწვავე ცისტიტისთვის დამახასიათებელია სხეულის ტემპერატურის მომატება 38 გრადუსამდე და სისხლი შარდში.</p>
          <p>ცისტიტის დროს თირკმელში ინფექცია შეიძლება მოხვდეს ჰემატოგენური გზით, რაც პიელონეფრიტის არსებითი წყარო და ცისტიტის ერთ-ერთი ყველაზე ხშირი გართულებაა. ეს არის თირკმელების არასპეციფიკური ინფექციური დაავადება, მიმდინარეობს თირკმლის ქსოვილების, მენჯისა და ფიალების დაზიანებით. პიელონეფრიტი შეიძლება იყოს ცალმხრივი ან ორმხრივი, პირველადი და მეორეული, მწვავე, ქრონიკული და მორეციდივე. ყველაზე ხშირად პიელონეფრიტის გამომწვევია ნაწლავის ჩხირი, ენტეროკოკი, სტაფილოკოკოკი, სტრეპტოკოკი. ინფექცია თირკმელში, მენჯსა და მის ფიალებში აღწევს ჰემატოგენური ან ლიმფოგენური გზით, ქვედა საშარდე გზებიდან შარდსაწვეთის კედლით, მისი სანათურით – შარდის ბუშტ-შარდსაწვეთის რეფლუქსის არსებობისას.</p>
          <h3 className='cistit-text-head'>დაავადების ნიშნები</h3>
          <ul>
            <li>ხშირი შარდვა</li>
            <li>ტკივილი ან წვის შეგრძნება შარდვის დროს (დიზურია)</li>
            <li>მუქი, მღვრიე ან/და სისხლიანი შარდი</li>
            <li>მუცლის ქვედა ნაწილის ან მენჯის ტკივილი</li>
            <li>შარდის ბუშტის არასრულად დაცლის შეგრძნება</li>
            <li>მსუბუქი ცხელება (ზოგიერთ შემთხვევაში)</li>
          </ul>
          <h3 className='cistit-text-head'>პრევენციის გზები</h3>
          <p>ცისტიტის პრევენცია ისეთივე მნიშვნელოვანია, როგორც მისი დროული მკურნალობა. ცისტიტის განვითარების რისკის შესამცირებლად ექიმის ძირითადი რჩევები ამგვარია:</p>
          <ul>
            <li>შეინარჩუნეთ ჰიდრატაცია დღის განმავლობაში საკმარისი რაოდენობის წყლის დალევით</li>
            <li>მოშარდეთ რეგულარულად და ყოველ ჯერზე სრულად დაცალეთ შარდის ბუშტი</li>
            <li>ტუალეტის გამოყენების შემდეგ საწმენდი საშუალება გამოიყენეთ წინიდან უკან, რათა ბაქტერიები არ გადავიდეს ანუსიდან ურეთრაში</li>
            <li>მოერიდეთ უხეშ საპონს ან სხვა პოტენციურ გამღიზიანებლის გამოყენებას სასქესო ორგანოზე</li>
            <li>ატარეთ ნატურალური ბოჭკოებისგან დამზადებული თავისუფალი, სუნთქვადი საცვალ</li>
          </ul>

          <h3 className='cistit-text-head'>ცისტიტი – გამომწვევი მიზეზები</h3>
          <ul>
            <li>ინფექციები (სტრეპტოკოკები, სტაფილოკოკები);</li>
            <li>ორგანიზმის გადაციება;</li>
            <li>ხშირი შეკრულობა;</li>
            <li>ანთებით დაავადებების გადატანა;</li>
            <li>ჰიგიენის ნორმების დარღვევა;</li>
            <li>ორგანიზმში ქრონიკული ანთებითი პროცესების არსებობა;</li>
          </ul>
          <h3 className='cistit-text-head'>ცისტიტის მკურნალობა</h3>
          <p>დაავადების დიაგნოსტირებისთვის ინიშნება ლაბორატორიული ანალიზი, რომელიც ადასტურებს ან გამორიცხავს ინფექციის არსებობას. ბაქტერიული ცისტიტის შემთხვევაში, მკურნალობა ანტიბიოტიკებით წარიმართება. ექიმები ხაზს უსვამენ სრულფასოვანი მკურნალობის აუცილებლობას, რისთვისაც საჭიროა სრული კურსის დასრულება იმ შემთხვევაშიც კი, თუ სიმპტომები შემსუბუქდება ან გაქრება.</p>
          <p>ანტიბიოტიკების გარდა, ამ დროს აუცილებელია, ბევრი წყლის დალევა და ხშირი შარდვა, რათა საშარდე გზებიდან ბაქტერია სწრაფად გამოიდევნოს. ასევე, სასურველია ისეთი გამღიზიანებლების თავიდან არიდება, როგორებიცაა კოფეინი, ალკოჰოლი და ცხარე საკვები. გაითვალისწინეთ ისიც, რომ არ არის მიზანშეწონილი შარდის ხანგრძლივი დროით შეკავება.</p>
          <p>იმ შემთხვევაში, თუ ცისტიტის ეპიზოდები მკურნალობის დასრულების შემდეგ ისევ განმეორდა, აუცილებელია დაინიშნოს დამატებითი გამოკვლევები ინფექციის გამომწვევი მიზეზების დასადგენად.</p>

        </div>
      </div>

    </Layout>
  )
}

export default Cistit