import React from 'react'
import Layout from '../../components/layout/layout'
import blogarrow from '../../images/blog/blogarrow.svg'
import './neckcancer.scss'
import neckcancerblog from '../../images/blog/neckcancerblog.svg'

const Neckcancer = () => {
  return (
    <Layout>
      <div className='neckcancer'>
        <div className='blog-txt-head'>
          <h2 className='blog-main-txt-head'>ბლოგი</h2>
          <img src={blogarrow} alt='blogarrow' className='blogarrow' />
          <p className='blog-main-txt-head-sub'>ყელის კიბო - მიზეზები, მკურნალობა და პრევენცია.</p>
        </div>

        <div className='blog-content'>

          <div className='blog-post-start'>
            <img src={neckcancerblog} alt='neckcancerblog' />
            <div className='blog-post-start-right'>

              <p className='blog-post-start-head'>ყელის კიბო - მიზეზები, მკურნალობა და პრევენცია.</p>
              <p>ყელის კიბო ზოგადი ტერმინია, რომელიც ყელის მიდამოში სხვადასხვა ლოკაციაზე განვითარებულ სიმსივნეებს აღნიშნავს. ის საყლაპავის, ხორხისა და ნუშურა ჯირკვლების, იგივე გლანდების კიბოს აერთიანებს. მათ ბევრი საერთო გამომწვევი მიზეზი და სიმპტომი აქვთ, თუმცა გააჩნიათ მრავალი სპეციფიკური ნიშანიც. ბლოგში ჩვენ ყელის სიმსივნეებს შორის ყველაზე გავრცელებულ – საყლაპავისა და ხორხის კიბოს გაგაცნობთ და მიმოვიხილავთ მათ გამომწვევ მიზეზებს, სიმპტომებსა და მკურნალობის მეთოდებს. </p>
            </div>
          </div>
          <h3 className='neck-head-title'>როდის მივმართოთ ინ ვიტრო განაყოფიერებას?</h3>
          <p className='no-margin-btm'>საყლაპავისა და ხორხის კიბოზე შეიძლება მიანიშნებდეს ისეთი ჩივილები, როგორიცაა:</p>
          <ul>
            <li>დისფაგია ანუ გაძნელებული ყლაპვა</li>
            <li>ყელის ტკივილი და დისკომფორტი ყლაპვის დროს</li>
            <li>უცხო სხეულის შეგრძნება ყელში</li>
            <li>სისავსის შეგრძნება გულმკერდის არეში</li>
            <li>შეშუპება ყელის არეში</li>
            <li>მადის დაქვეითება და შედეგად წონაში კლება</li>
            <li>ხმის ჩახლეჩა, მისი დაკარგვა ან/და ხრინწი ხმაში</li>
            <li>ხველა (ნახველში შეიძლება შეინიშნებოდეს სისხლი)</li>
            <li>ნერწყვის გაძლიერებული გამოყოფა</li>
            <li>არასასიამოვნო სუნი პირიდან</li>
            <li>პირღებინება</li>
            <li>ყურის ტკივილი და სხვა.</li>
          </ul>

          <h3 className='neck-head-title'>რა იწვევს ხორხის კიბოს?</h3>
          <p>ხორხის კიბო, საყლაპავის სიმსივნის მსგავსად, მეტად არის გავრცელებული კაცებში, ვიდრე ქალებში. კონკრეტულად კი, ის თითქმის 9-ჯერ უფრო ხშირად გვხდება მამრობითი სქესის ინდივიდებში. ასევე, მამაკაცებში, ხორხის კიბოს თავ-კისრის ავთვისებიან სიმსივნეებს შორის პირველი ადგილი უკავია.</p>
          <p>საყლაპავის კიბოს ბევრი მაპროვოცირებელი ფაქტორი, რომელიც ზემოთ ჩამოვთვალეთ, ხორხის კიბოზეც ვრცელდება. მათ შორის არის, მწეველობა, ალკოჰოლის მოხმარება, ანთებითი დაავადებები, გენეტიკური წინასწარგანწყობა, პაპილომა ვირუსი და სხვა. ამასთან ერთად, ხორხის კიბოს განვითარებას შეიძლება ხელი შეუწყოს:</p>
          <ul>
            <li>პირის ღრუს არასათანადო ჰიგიენამ – ასეთ დროს იზრდება კბილებისა და ღრძილების ანთებითი დაავადებების გაჩენის რისკი, რამაც შესაძლოა გარკვეული როლი ითამაშოს ხორხის კიბოს პროვოცირებაში.</li>
            <li>ცუდი ეკოლოგიური მდგომარეობა და საწარმოო გარემო – საშიშ სუბსტანციებთან, მაგალითად ნავთობპროდუქტებთან, სარემონტო მასალებში შემავალ ქიმიურ ნივთიერებებთან ხშირი კონტაქტი, დაბინძურებულ და მტვრიან გარემოში ყოფნა და ა.შ
            </li>
          </ul>
          <h3 className='neck-head-title'>საყლაპავისა და ხორხის კიბოს დიაგნოსტიკა</h3>
          <p className='no-margin-btm'>ყელის სიმსივნეების დიაგნოსტირებისთვის გამოიყენება როგორც ლაბორატორიული, ისე ინსტრუმენტული გამოკვლევები, რომელთა შორისაცაა:</p>
          <ul>
            <li>ფიბროლარინგოსკოპია – ის ერთ-ერთი მთავარი სადიაგნოსტიკო მეთოდია ხორხში მიმდინარე პროცესების შესასწავლად</li>
            <li>ბიოფსია მასალის მორფოლოგიური შესწავლით</li>
            <li>კისრის მაგნიტურ-რეზონანსული კვლევა ან/და კომპიუტერული ტომოგრაფია (კონტრასტულ რეჟიმში)</li>
            <li>გულ-მკერდის კომპიუტერული ტომოგრაფია</li>
            <li>კისრის ლიმფური კვანძების ასპირაციული ბიოფსია</li>
            <li>PET CT კვლევა ანუ პოზიტრონ-ემისიური ტომოგრაფია– ის უზუსტეს ინფორმაციას აწვდის სპეციალისტს დაავადების გავრცელების შესახებ</li>
          </ul>
          <h3 className='neck-head-title'>საყლაპავის კიბოს გამომწვევი მიზეზები</h3>
          <p>საყლაპავის კიბო კუჭ-ნაწლავის ტრაქტის ერთ-ერთი ყველაზე გავრცელებული სიმსივნეა. აღსანიშნავია, რომ მათი შემთხვევები თითქმის 3-ჯერ უფრო ხშირია კაცებში, ვიდრე ქალებში. მისი განვითარების ხელშემწყობ ფაქტორებად კი მიიჩნევა:</p>
          <ul>
            <li>მწეველობა – მოგეხსენებათ, თამბაქო შეიცავს მრავალ სახიფათო ქიმიურ ნივთიერებასა და ტოქსინს, მათ შორის, კანცეროგენებს, რომელთა მოქმედებაც ადამიანთა ორგანიზმზე ზრდის ავთვისებიანი სიმსივნის წარმოქმნის რისკს. ამასთან ერთად, თამბაქოს კვამლი აღიზიანებს ყელში არსებულ ქსოვილებსა და ლორწოვან გარსს, რაც საყლაპავის კიბოს განვითარების ერთ-ერთი მნიშვნელოვანი რისკ-ფაქტორია.</li>
            <li>ალკოჰოლის ჭარბად მოხმარება – ალკოჰოლიც, თამბაქოს მსგავსად, საყლაპავის ლორწოვან გარსს აღიზიანებს. აღსანიშნავია ისიც, რომ ალკოჰოლისა და თამბაქოს ერთად მოხმარება კიდევ უფრო ზრდის საყლაპავის კიბოს განვითარების ალბათობას. ამ ურთიერთქმედების ზუსტი მიზეზი უცნობია, თუმცა ვარაუდობენ, რომ ალკოჰოლი შესაძლოა აძლიერებდეს თამბაქოდან კანცეროგენების შეწოვას.</li>
            <li>თერმული გაღიზიანება – ძალიან ცხელი საკვები და სასმელები ხშირად საყლაპავის ქსოვილების დამწვრობასა ან/და ლორწოვანი გარსის გაღიზიანებას იწვევს.</li>
            <li>საყლაპავის მექანიკური დაზიანება, რაც შესაძლებელია საკვების არასათანადოდ დაღეჭვამ გამოიწვიოს.</li>
            <li>საყლაპავის ქრონიკული ანთებითი დაავადებები, დივერტიკული დაავადებები და ლეიკოპაკიები.</li>
            <li>პაპილომა ვირუსი – ინფექციის გარკვეული გენოტიპები საყლაპავის კიბოს განვითარების ხელშემწყობ ფაქტორებად მიიჩნევა.</li>
            <li>ასაკი – 50 წლის ზემოთ ასაკის ინდივიდებში მისი განვითარების ალბათობა მატულობს.</li>
            <li>სქესი – როგორც აღვნიშნეთ, დაავადება ქალებზე მეტად, კაცებშია გავრცელებული.</li>
            <li>გენეტიკური წინასწარგანწყობა – ისევე, როგორც სხვა ონკოლოგიური დაავადებების შემთხვევაში, საყლაპავის კიბოს განვითარებაში, გარკვეულ როლს თამაშობს გენეტიკა.</li>
            <li>ბარეტის საყლაპავი – საყლაპავის ხანგრძლივად გაღიზიანებამ, შეიძლება კიბოსწინარე მდგომარეობა გამოიწვიოს, რასაც ბარეტის საყლაპავი ეწოდება.</li>
          </ul>

          <h3 className='neck-head-title'>ყელის კიბოს მკურნალობა</h3>
          <p>საყლაპავისა და ხორხის კიბოს მკურნალობის პროცესი მკაცრად რეგულირდება საერთაშორისო მეთოდური რეკომენდაციებით, ე.წ. გაიდლაინებით. დაავადებების მკურნალობაში აქტიურად გამოიყენება ოპერაციული, ქიმიოთერაპიული და სხივური მეთოდები. თუ დაავადება იძლევა შესაძლებლობას, სასურველია, ქირურგიული ჩარევა განხორციელდეს ენდოსკოპიური მეთოდით, რაც შედარებით მცირეინვაზიური პროცედურაა და გაცილებით აადვილებს რეაბილიტაციის პროცესს.</p>
          <p>ქირურგიული ოპერაციის, სხივური თერაპიისა და ქიმიოთერაპიის თანმიმდევრობა დამოკიდებულია სიმსივნური წარმონაქმნის ლოკალიზაციაზე, დაავადების გავრცელებასა და პაციენტის ზოგად მდგომარეობაზე. მკურნალობა, როგორც წესი, კომბინირებული ხასიათისაა და მოითხოვს სხვადასხვა პროფილის ექიმის და ექიმთა გუნდის ჩართულობას.</p>
          <p>ყოველივე აღნიშნულის გათვალისწინებით, საყლაპავისა და ხორხის კიბოს მკურნალობა მიზანშეწონილია მრავალპროფილურ კლინიკებში, სადაც მკურნალობის ტაქტიკის შესამუშავებლად და შესაფასებლად არსებობს მულტიდისციპლინური მიდგომა.</p>

          <h3 className='neck-head-title'>დაავადებების მონიტორინგი</h3>
          <p>ყელის სიმსივნეების მკურნალობის შემდეგ აუცილებელია დაავადების კონტროლი. როგორც წესი, პირველი ორი წლის განმავლობაში საკონტროლო გამოკვლევები 3 თვეში ერთხელ ინიშნება. მესამე წლიდან კი შემდგომი 3 წლის განმავლობაში პერიოდულობა 6 თვემდე იზრდება.</p>
          <p>გაითვალისწინეთ, რომ საწყის სტადიაზე ყელის სიმსივნეების მკურნალობა საკმაოდ წარმატებულია. ასეთ დროს გაცილებით მარტივი და ეფექტურია ქირურგიული ოპერაციებიც. დროთა განმავლობაში, დაავადების გართულებასთან ერთად კი გამოჯანმრთელების რისკები მცირდება. ამიტომ საუკეთესო გამოსავალი სიმსივნური პროცესების პრევენცია და კიბოსწინარე დაავადებების დროული დიაგნოსტიკაა.</p>
        </div>
      </div>

    </Layout>
  )
}

export default Neckcancer