import React from 'react'
import Layout from '../../components/layout/layout'
import blogarrow from '../../images/blog/blogarrow.svg'
import './heart.scss'
import heartblog from '../../images/blog/heartblog.svg'

const Heart = () => {
  return (
    <Layout>
      <div className='heart'>
        <div className='blog-txt-head'>
          <h2 className='blog-main-txt-head'>ბლოგი</h2>
          <img src={blogarrow} alt='blogarrow' className='blogarrow' />
          <p className='blog-main-txt-head-sub'>გულ-სისხლძარღვთა დაავადებები, სიმპტომები და პრევენცია.</p>
        </div>

        <div className='blog-content'>

          <div className='blog-post-start'>
            <img src={heartblog} alt='heartblog' />
            <div className='blog-post-start-right'>

              <p className='blog-post-start-head'>გულ-სისხლძარღვთა დაავადებები, სიმპტომები და პრევენცია.</p>
              <p>გულ-სისხლძარღვთა, ანუ სისხლის მიმოქცევის სისტემა ორგანიზმს სისხლით ამარაგებს.
იგი შედგება გულის, არტერიების, ვენების და კაპილარებისგან.
გულ-სისხლძარღვთა დაავადების გართულებები სიკვდილის ყველაზე გავრცელებული მიზეზია მსოფლიოში. თუმცა მისი განკურნება შესაძლებელია, აგრეთვე შესაძლებელია რისკ ფაქტორების შემცირება.
დაავადებები, რომლებიც გავლენას ახდენენ გულზე </p>
            </div>

          </div>
          <h3 className='heart-blue'>როდის მივმართოთ ინ ვიტრო განაყოფიერებას?</h3>
          <ul>
            <li>გულის კორონარული დაავადება - გულის კუნთის მომმარაგებელი სისხლძარღვების დაავადება;</li>
            <li>ცერებროვასკულური დაავადება - თავის ტვინის მომწოდებელი სისხლძარღვების დაავადება;</li>
            <li>პერიფერიული არტერიული დაავადება - იმ სისხლძარღვების დაავადება, რომელიც ხელებსა და ფეხებს ამარაგებს;</li>
            <li>გულის რევმატული დაავადება - გულის კუნთისა და გულის სარქველების დაზიანება რევმატიული ცხელებით, გამოწვეული სტრეპტოკოკური ბაქტერიით;</li>
            <li>თანდაყოლილი გულის დაავადება - თანდაყოლილი დეფექტები, რომლებიც გავლენას ახდენენ გულის ნორმალურ განვითარებასა და ფუნქციონირებაზე, რაც გამოწვეულია დაბადებიდან გულის სტრუქტურის მანკით;</li>
            <li>ღრმა ვენების თრომბოზი და ფილტვის ემბოლია - სისხლის შედედება ფეხის ვენებში, რომელიც შეიძლება გულსა და ფილტვებში გადავიდეს.</li>
            <li>ხოლო გულის შეტევა და ინსულტი, როგორც წესი, ყველაზე მწვავე შემთხვევებია და, ძირითადად, სისხლის ბლოკირებითაა გამოწვეული, რომელიც სისხლის მიმოქცევას გულსა და ტვინში ხელს უშლის.</li>
          </ul>

          <h3 className='heart-blue'>გულ-სისხლძარღვთა დაავადებების რისკ-ფაქტორები</h3>
          <p>გულის დაავადების და ინსულტის ყველაზე მნიშვნელოვანი ქცევითი რისკ-ფაქტორებია: არაჯანსაღი კვება, ფიზიკური უმოქმედობა, თამბაქოს მოხმარება და ალკოჰოლის მავნე მოხმარება. ქცევითი რისკ-ფაქტორების ეფექტი შეიძლება ინდივიდებში შემდეგნაირი სახით გამოვლინდეს: არტერიული წნევის მომატება, სისხლში გლუკოზის მომატება, სისხლში ლიპიდების მომატება, ჭარბი წონა და სიმსუქნე.</p>

          <h3 className='heart-blue'>სიმპტომები</h3>
          <ul>
            <li>სიმპტომები კონკრეტული მდგომარეობის მიხედვით განსხვავდება . ზოგიერთმა მდგომარეობამ, შესაძლოა თავდაპირველად საერთოდ არ გამოიწვიოს რაიმე სიმპტომები;</li>
            <li>თუმცა, ძირითადი გულ-სისხლძარღვთა პრობლემის ტიპიური სიმპტომები შემდეგნარია;</li>
            <li>ტკივილი ან მოჭერის შეგრძნება გულმკერდის არეში, რაც შეიძლება მიუთითებდეს სტენოკარდიაზე;</li>
            <li>ტკივილი ან დისკომფორტი მკლავებში, მარცხენა მხარში, იდაყვებში, ყბაში ან ზურგში;</li>
            <li>ჰაერის უკმარისობა;</li>
            <li>გულისრევა და დაღლილობა;</li>
            <li>თავბრუსხვევა ან ცივი ოფლიანობა.</li>
          </ul>
          <h3 className='heart-blue'>პრევენცია</h3>
          <p>გულ-სისხლძარღვთა დააავადებების რისკის შესამცირებლად აუცილებელია, თამბაქოს მოხმარების შეწყვეტა, რაციონში მარილის შემცირება, მეტი ხილისა და ბოსტნეულის ჭამა, რეგულარული ფიზიკური აქტივობა და ალკოჰოლის მავნე მოხმარების თავიდან აცილება.</p>
          <p>გარდა ამისა, ჰიპერტენზიის, დიაბეტისა და სისხლში მაღალი ლიპიდების მედიკამენტური მკურნალობა აუცილებელია გულ-სისხლძარღვთა რისკის შესამცირებლად და ამ პირობების მქონე ადამიანებში გულის შეტევისა და ინსულტის თავიდან ასაცილებლად.</p>

        </div>

      </div>

    </Layout>
  )
}

export default Heart